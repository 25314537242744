import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { CompanyProfile, Page } from "src/app/Models";
import { CompanyUser } from "../../../Models";
import { HttpService } from "src/services/HTTP";
import { PageRequest } from "@limestone/ls-shared-modules";

@Injectable()
export class CompanyProfileService extends HttpService {
	constructor(public http: HttpClient) {
		super(http);
		this.path = "/api/companyProfile";
	}

	getCompanyProfile(id: number): Observable<CompanyProfile> {
		const url = `${this.path}/${id}`;
		return this.http
			.get<CompanyProfile>(url)
			.pipe(
				map(
					(resp: CompanyProfile) =>
						new CompanyProfile(
							resp.companyId,
							resp.industryId,
							resp.legalBusinessName,
							resp.annualRevenueRangeId,
							resp.phoneId,
							resp.phoneCountryCode,
							resp.phoneNumber,
							resp.phoneExt,
							resp.agreedToTerms,
							resp.dbaNames,
							resp.existingFinances,
							resp.financeFrequency,
							resp.financePurposes,
							resp.financeRange,
							resp.financeUrgency,
							resp.addresses,
							resp.lienCollaborationYesNoId,
							resp.integrationDataConnectionId,
							resp.users,
							resp.isAccountingPlatformConnected,
							resp.isBankingPlatformConnected,
							resp.isCustomersInProgress,
							resp.formationStateCode,
							resp.isCodatCustomerFetchComplete,
							resp.isInvoiceHistoryFetchComplete,
							resp.hasSubmittedApplications,
							resp.taxId
						)
				)
			);
	}

	updateCompanyProfile(companyProfile: CompanyProfile): Observable<CompanyProfile> {
		return this.http
			.put<CompanyProfile>(`${this.path}/${companyProfile.companyId}`, companyProfile)
			.pipe(
				map(
					(c) =>
						new CompanyProfile(
							c.companyId,
							c.industryId,
							c.legalBusinessName,
							c.annualRevenueRangeId,
							c.phoneId,
							c.phoneCountryCode,
							c.phoneNumber,
							c.phoneExt,
							c.agreedToTerms,
							c.dbaNames,
							c.existingFinances,
							c.financeFrequency,
							c.financePurposes,
							c.financeRange,
							c.financeUrgency,
							c.addresses,
							c.lienCollaborationYesNoId,
							c.integrationDataConnectionId,
							c.users,
							c.isAccountingPlatformConnected,
							c.isBankingPlatformConnected,
							c.isCustomersInProgress,
							c.formationStateCode,
							c.isCodatCustomerFetchComplete,
							c.isInvoiceHistoryFetchComplete,
							c.hasSubmittedApplications,
							c.taxId
						)
				)
			);
	}

	updateCompanyProfileUser(user: CompanyUser): Observable<CompanyUser> {
		return this.http
			.put<CompanyUser>(`${this.path}/${user.companyId}/users/${user.userId}`, user)
			.pipe(map((c) => user));
	}

	deleteCompanyProfileUser(user: CompanyUser): Observable<CompanyUser> {
		return this.http
			.delete<CompanyUser>(`${this.path}/${user.companyId}/users/${user.userId}`, { body: user })
			.pipe(map((c) => user));
	}
}
