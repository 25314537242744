import { IRouteStepData } from "src/app/Models/Interfaces";
import { CompanyDocumentType } from "../../../Models/Enums";
import { FeatureFlags } from "@limestone/ls-shared-modules";

export const RouteStepData: Map<string, IRouteStepData> = new Map([
	["connect-financials", { step: 0, substep: 0, featureFlag: FeatureFlags.Onboarding_V1_0_0 }],
	["connect-financials/introduction", { step: 0, substep: 1, nextUrl: "../accounting" }],
	["connect-financials/accounting", { step: 0, substep: 2, nextUrl: "../banking-intro" }],
	["connect-financials/accounting-success", { step: 0, substep: 3, nextUrl: "../banking-intro" }],
	["connect-financials/banking-intro", { step: 0, substep: 4, nextUrl: "../../company-details/introduction" }],
	["connect-financials/banking-success", { step: 0, substep: 5, nextUrl: "../../company-details/introduction" }],
	["company-details", { step: 1, substep: 0, featureFlag: FeatureFlags.Onboarding_V1_0_0 }],
	[
		"company-details/introduction",
		{ step: 1, substep: 1, backgroundStyle: "inverse-background", nextUrl: "../company-name" }
	],
	["company-details/company-name", { step: 1, substep: 2, nextUrl: "../phone-address" }],
	["company-details/phone-address", { step: 1, substep: 3 }],
	["company-details/state-of-formation", { step: 1, substep: 4, nextUrl: "../company-industry" }],
	["company-details/company-industry", { step: 1, substep: 5 }],
	[
		"company-details/w-9",
		{ step: 1, substep: 6, docType: CompanyDocumentType.TPI, nextUrl: "../../financing-intentions" }
	],
	["financing-intentions", { step: 2, substep: 0, featureFlag: FeatureFlags.Onboarding_V1_0_0 }],
	["financing-intentions/amount-frequency", { step: 2, substep: 1, nextUrl: "../urgency-purpose" }],
	["financing-intentions/urgency-purpose", { step: 2, substep: 2, nextUrl: "../other-financing" }],
	["financing-intentions/other-financing", { step: 2, substep: 3, nextUrl: "../lien-information" }],
	["financing-intentions/lien-information", { step: 2, substep: 4, nextUrl: "../../customer-setup/" }],
	["customer-setup", { step: 3, substep: 0, featureFlag: FeatureFlags.Onboarding_V1_0_0 }],
	["customer-setup/manual", { step: 3, substep: 1, section: 0, subSection: 0, backgroundStyle: "inverse-background" }],
	[
		"customer-setup/customer-list",
		{ step: 3, substep: 2, section: 0, subSection: 1, backgroundStyle: "inverse-background" }
	],
	["customer-setup/customer-contact", { step: 3, substep: 3, section: 1, subSection: 0 }],
	[
		"customer-setup/relationship",
		{ step: 3, substep: 4, section: 2, subSection: 0, nextUrl: "../../currency-payment/" }
	],
	["customer-setup/currency-payment", { step: 3, substep: 5, section: 2, subSection: 1 }],
	[
		"customer-setup/customer-revenue",
		{ step: 3, substep: 6, section: 2, subSection: 2, nextUrl: "../../invoice-platform/" }
	],
	[
		"customer-setup/invoice-platform",
		{ step: 3, substep: 7, section: 3, subSection: 0, nextUrl: "../../status-platform/" }
	],
	[
		"customer-setup/status-platform",
		{ step: 3, substep: 8, section: 3, subSection: 1, nextUrl: "../../remittance-platform/" }
	],
	[
		"customer-setup/remittance-platform",
		{ step: 3, substep: 9, section: 3, subSection: 2, nextUrl: "../../requested-financing/" }
	],
	["customer-setup/requested-financing", { step: 3, substep: 10, section: 4, subSection: 0, nextUrl: "../../noa/" }],
	[
		"customer-setup/notice-of-assignment",
		{ step: 3, substep: 11, section: 4, subSection: 1, nextUrl: "../../autoXcel/" }
	],
	["customer-setup/autoXcel", { step: 3, substep: 12, section: 4, subSection: 2, nextUrl: "../../invoice-approvals/" }],
	[
		"customer-setup/invoice-approvals",
		{ step: 3, substep: 13, section: 4, subSection: 3, nextUrl: "../../expedited-financing/" }
	],
	[
		"customer-setup/expedited-financing",
		{ step: 3, substep: 14, section: 4, subSection: 4, nextUrl: "../../preferred-payment/" }
	],
	["customer-setup/preferred-payment", { step: 3, substep: 15, section: 4, subSection: 5, nextUrl: "../../review" }],
	["customer-setup/review", { step: 3, backgroundStyle: "inverse-background", nextUrl: "../../review-application" }],
	//['documents', { step: 4, substep: 0 }],
	//['documents/voided-check', { step: 4, substep: 2, docType: CompanyDocumentType.TPI }],
	//['documents/bank-statements', { step: 4, substep: 3, docType: CompanyDocumentType.TPI }],
	//['documents/interim-ytd', { step: 4, substep: 4, docType: CompanyDocumentType.TPI }],
	//['documents/tax-returns', { step: 4, substep: 5, docType: CompanyDocumentType.TPI }],
	//['documents/accountant-prepared-statements', { step: 4, substep: 6, docType: CompanyDocumentType.TPI }],
	//['documents/management-prepared-returns', { step: 4, substep: 7, docType: CompanyDocumentType.TPI }],
	//['documents/audited-statements', { step: 4, substep: 8, docType: CompanyDocumentType.TPI }],
	[
		"review-application",
		{ step: 4, substep: 0, featureFlag: FeatureFlags.Onboarding_V1_0_0, nextUrl: "../application-complete" }
	]
]);

export const RouteStepData_V102: Map<string, IRouteStepData> = new Map([
	["connect-financials", { step: 0, substep: 0, featureFlag: FeatureFlags.Onboarding_V1_0_0 }],
	["connect-financials/introduction", { step: 0, substep: 1, nextUrl: "../accounting" }],
	["connect-financials/accounting", { step: 0, substep: 2, nextUrl: "../banking-intro" }],
	["connect-financials/accounting-success", { step: 0, substep: 3, nextUrl: "../banking-intro" }],
	["connect-financials/banking-intro", { step: 0, substep: 4, nextUrl: "../../company-details/introduction" }],
	["connect-financials/banking-success", { step: 0, substep: 5, nextUrl: "../../company-details/introduction" }],
	["company-details", { step: 1, substep: 0, featureFlag: FeatureFlags.Onboarding_V1_0_0 }],
	[
		"company-details/introduction",
		{ step: 1, substep: 1, backgroundStyle: "inverse-background", nextUrl: "../company-name" }
	],
	["company-details/company-name", { step: 1, substep: 2, nextUrl: "../phone-address" }],
	["company-details/phone-address", { step: 1, substep: 3 }],
	["company-details/state-of-formation", { step: 1, substep: 4, nextUrl: "../company-industry" }],
	["company-details/company-industry", { step: 1, substep: 5 }],
	["company-details/w-9", { step: 1, substep: 6, docType: CompanyDocumentType.TPI, nextUrl: "../../customer-setup" }],
	["customer-setup", { step: 2, substep: 0, featureFlag: FeatureFlags.Onboarding_V1_0_0 }],
	["customer-setup/manual", { step: 2, substep: 1, section: 0, subSection: 0, backgroundStyle: "inverse-background" }],
	[
		"customer-setup/customer-list",
		{ step: 2, substep: 2, section: 0, subSection: 1, backgroundStyle: "inverse-background" }
	],
	["customer-setup/customer-contact", { step: 2, substep: 3, section: 1, subSection: 0 }],
	[
		"customer-setup/relationship",
		{ step: 2, substep: 4, section: 2, subSection: 0, nextUrl: "../../currency-payment/" }
	],
	["customer-setup/currency-payment", { step: 2, substep: 5, section: 2, subSection: 1 }],
	[
		"customer-setup/customer-revenue",
		{ step: 2, substep: 6, section: 2, subSection: 2, nextUrl: "../../invoice-platform/" }
	],
	[
		"customer-setup/invoice-platform",
		{ step: 2, substep: 7, section: 3, subSection: 0, nextUrl: "../../status-platform/" }
	],
	[
		"customer-setup/status-platform",
		{ step: 2, substep: 8, section: 3, subSection: 1, nextUrl: "../../remittance-platform/" }
	],
	["customer-setup/remittance-platform", { step: 2, substep: 9, section: 3, subSection: 2, nextUrl: "../../noa/" }],
	[
		"customer-setup/notice-of-assignment",
		{ step: 2, substep: 10, section: 4, subSection: 0, nextUrl: "../../autoXcel/" }
	],
	["customer-setup/autoXcel", { step: 2, substep: 11, section: 4, subSection: 1, nextUrl: "../../invoice-approvals/" }],
	[
		"customer-setup/invoice-approvals",
		{ step: 2, substep: 12, section: 4, subSection: 2, nextUrl: "../../expedited-financing/" }
	],
	[
		"customer-setup/expedited-financing",
		{ step: 2, substep: 13, section: 4, subSection: 3, nextUrl: "../../preferred-payment/" }
	],
	["customer-setup/preferred-payment", { step: 2, substep: 14, section: 4, subSection: 4, nextUrl: "../../review" }],
	["customer-setup/review", { step: 2, backgroundStyle: "inverse-background", nextUrl: "../../review-application" }],
	[
		"review-application",
		{ step: 3, substep: 0, featureFlag: FeatureFlags.Onboarding_V1_0_0, nextUrl: "../application-complete" }
	]
]);
