import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, filter, map, Subject, takeUntil } from "rxjs";
import { CompanyProfile, CompanyProfileRelationship } from "src/app/Models";
import { CompanyProfileSelectors } from "../../../../../../../AppStateManagement";
import { Store } from "@ngrx/store";
import {
	GenericLookup,
	GenericSelectors,
	CurrencyCode,
	LookupTables,
	FeatureFlagSelectors,
	FeatureFlags
} from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-customer-relationship-readonly",
	templateUrl: "./customer-relationship-readonly.component.html"
})
export class CustomerRelationshipReadonlyComponent implements OnInit, OnDestroy {
	private _componentTeardown$: Subject<any> = new Subject<any>();
	private _companyProfileRelationship?: CompanyProfileRelationship;
	public companyProfile?: CompanyProfile;
	public relationshipDurations?: GenericLookup<string>[];
	public onboardPaymentMethods?: GenericLookup<string>[];
	public onboardRevenueRanges?: GenericLookup<string>[];
	public onboardInstrumentRanges?: GenericLookup<string>[];
	public isCalculating?: boolean = false;
	public currencyCode? = CurrencyCode.USD;
	isV102Enabled = false;
	@Input() set companyProfileRelationship(companyProfileRelationship: CompanyProfileRelationship | undefined) {
		if (companyProfileRelationship) {
			this._companyProfileRelationship = companyProfileRelationship;
		}
	}
	get companyProfileRelationship(): CompanyProfileRelationship | undefined {
		return this._companyProfileRelationship;
	}
	@Input() readOnly = true;
	constructor(
		private store: Store,
		private companyProfileSelectors: CompanyProfileSelectors,
		private genericSelectors: GenericSelectors,
		private ffSelectors: FeatureFlagSelectors
	) {}

	ngOnDestroy(): void {
		this._componentTeardown$.next(null);
		this._componentTeardown$.complete();
	}

	ngOnInit(): void {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.RelationshipDuration)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardInstrumentRange)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardRevenueRange)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardPaymentMethod)),
			this.store.select(this.ffSelectors.selectFeatureFlags)
		])
			.pipe(
				filter(([cp, rd, oir, rr, opm, ff]) => !!cp && !!rd && !!oir && !!rr && !!opm && !!ff),
				takeUntil(this._componentTeardown$),
				map(([cp, rd, oir, orr, opm, ff]) => {
					const f = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!;
					this.isV102Enabled = f.enabled;
					this.companyProfile = cp;
					this.isCalculating =
						!this.companyProfileRelationship?.isManualSetup &&
						!this.companyProfileRelationship?.isCodatCalculationsComplete;
					this.relationshipDurations = rd;
					this.onboardPaymentMethods = opm;
					this.onboardRevenueRanges = orr;
					this.onboardInstrumentRanges = oir;
					this.currencyCode = this.companyProfileRelationship?.isOtherCurrency
						? this.currencyCode
						: this.companyProfileRelationship?.currencyCode;
				})
			)
			.subscribe();
	}
}
