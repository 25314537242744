import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, withLatestFrom } from "rxjs";
import { CompanyDocumentService } from "../../Services";
import { switchMap } from "rxjs/operators";
import { LsHttpErrorResponse } from "../../../../Models";
import { HttpErrorResponse } from "@angular/common/http";
import { CompanyDocumentActions } from "./company-document-actions";
import { Store } from "@ngrx/store";
import { CompanyDocumentSelectors } from "./company-document-selectors";
import { CompanyDocumentType } from "../../../../Models/Enums";
import { ExportService } from "../../../../../services";

@Injectable()
export class CompanyDocumentEffects {
	constructor(
		private actions$: Actions,
		private companyDocumentService: CompanyDocumentService,
		private store: Store,
		private companyDocumentSelectors: CompanyDocumentSelectors,
		private exportService: ExportService
	) {}

	fetchCompanyDocuments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyDocumentActions.getFiles),
			mergeMap((act) =>
				this.companyDocumentService.getCompanyDocuments(act.companyId, act.documentType).pipe(
					switchMap((documents) => {
						return [CompanyDocumentActions.setFiles({ documentType: act.documentType, documents })];
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [CompanyDocumentActions.saveUnsuccessful({ result: errResp })];
					})
				)
			)
		)
	);

	deleteCompanyDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyDocumentActions.deleteCompanyDocument),
			mergeMap((act) =>
				of(act).pipe(
					withLatestFrom(this.store.select(this.companyDocumentSelectors.selectDocumentsFor(act.documentType)))
				)
			),
			mergeMap(([act, docs]) =>
				this.companyDocumentService.deleteCompanyDocument(act.id, act.document).pipe(
					mergeMap(() => {
						const filteredDocs = docs!.filter((doc) => doc.fileName !== act.document.fileName);
						return [CompanyDocumentActions.setFiles({ documentType: act.documentType, documents: filteredDocs })];
					})
				)
			)
		)
	);

	downloadCompanyDocument$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(CompanyDocumentActions.downloadFile),
				mergeMap((act) =>
					this.companyDocumentService.downloadCompanyDocument(act.companyId, act.documentType, act.fileName).pipe(
						map((response) => {
							this.exportService.downloadFile(response);
						})
					)
				)
			),
		{ dispatch: false }
	);

	uploadCompanyDocuments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyDocumentActions.uploadFile),
			switchMap((act) =>
				this.companyDocumentService.uploadCompanyFile(act.companyId, act.documentType, act.file).pipe(
					mergeMap(() => [
						CompanyDocumentActions.getFiles({ companyId: act.companyId, documentType: act.documentType })
					]),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [CompanyDocumentActions.saveUnsuccessful({ file: act.file, result: errResp })];
					})
				)
			)
		)
	);
}
