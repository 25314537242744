<div id="case-message-reply-container">
    <ls-alert class="ls-mb-2" *ngIf="errorMessage" [alertType]="errorMessage.type" [icon]="errorMessage.icon" [small]="true">
        <label class="xs">{{ errorMessage.message }}</label>
     </ls-alert>
    <form [formGroup]="form" class="d-flex flex-column">
        <mdb-form-control>
            <textarea mdbInput maxlength="{{emailCharacterLimit}}" placeholder="Message" [formControlName]="RESPONSE" class="form-control" rows="4"></textarea>
        </mdb-form-control>
    </form>
    <ls-case-message-attachments [attachments]="attachments" [attachmentsRemovable]="true" (openAttachment)="openAttachment($event)" (removeAttachment)="removeAttachment($event)"></ls-case-message-attachments>
    <div class="d-flex flex-row justify-content-between ls-mt-4">
        <div class="d-flex flex-row">
            <button type="button" class="btn btn-primary ls-mr-2" (click)="sendMessage()" [disabled]="form.get(RESPONSE)?.value?.length < 1">
                Send Message
            </button>
            <button type="button" (click)="openFileInput($event)" class="btn btn-icon attach-file">
                <span class="material-icons">attach_file</span>
            </button>
        </div>
        <button type="button" class="btn btn-outline-tertiary float-end" (click)="close()">
            Cancel
        </button>
        <input type="file" id="upload" (change)="handleFiles($event.target)" multiple #upload/>
    </div>
</div>