import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";
import { filter, map, Observable, take } from "rxjs";
import { CodatActions, CodatSelectors } from "../AppStateManagement";

@Injectable()
export class CodatDataResolver {
	constructor(
		private store: Store<any>,
		private codatSelectors: CodatSelectors
	) {}

	integrationDataConnection$ = this.store.select(this.codatSelectors.selectIntegrationDataConnections).pipe(
		filter((idc) => !!idc),
		take(1)
	);

	resolve(): Observable<any> {
		this.initPrefetchData();
		return this.integrationDataConnection$;
	}

	initPrefetchData(): void {
		this.store
			.select(this.codatSelectors.selectIntegrationDataConnections)
			.pipe(
				take(1),
				filter((idc) => !idc),
				map(() => this.store.dispatch(CodatActions.getIntegrations()))
			)
			.subscribe();
	}
}
