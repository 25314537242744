<form [formGroup]="formGroup" class="d-flex flex-column">
  <div class="name d-flex flex-column">
    <div *ngFor="let name of dbaNameControls.controls; let i = index" class="d-flex flex-column flex-md-row">
      <div class="ls-w-100 ls-w-md-50 ls-mb-0"
           [class.d-none]="numberOfDBANames <= i"
           [class.ls-mb-4]="numberOfDBANames > i + 1">
        <mdb-form-control>
          <input mdbInput [formControl]="control(name)" type="text" id="name" class="form-control form-control-lg"/>
          <label mdbLabel class="form-label" for="name">Doing Business As Name</label>
        </mdb-form-control>
      </div>
      <button [class.d-none]="numberOfDBANames !== i + 1 || numberOfDBANames >= 4"
          class="btn btn-icon btn-primary-borderless primary" [disabled]="formGroup.disabled" 
          (click)="addDBAName()">
        <span class="material-icons md-18"> add</span> Add Name
      </button>
    </div>
  </div>
</form>