import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { OnboardCompanyProgress } from "src/app/Models";
import { HttpService } from "src/services/HTTP";

@Injectable()
export class CompanyProgressService extends HttpService {
	constructor(public http: HttpClient) {
		super(http);
		this.path = "/api/onboardcompanyprogress/companyProfile";
	}

	getOnboardCompanyProgress(companyId: number): Observable<OnboardCompanyProgress> {
		const url = `${this.path}/${companyId}`;
		return this.http
			.get<OnboardCompanyProgress>(url)
			.pipe(
				map(
					(resp: OnboardCompanyProgress) =>
						new OnboardCompanyProgress(resp.companyId, resp.isOnboardingComplete, resp.currentStep, resp.currentSubStep)
				)
			);
	}

	updateOnboardCompanyProgress(progress: OnboardCompanyProgress): Observable<OnboardCompanyProgress> {
		const url = `${this.path}/${progress.companyId}`;
		return this.http
			.put<OnboardCompanyProgress>(url, progress)
			.pipe(
				map(
					(resp: OnboardCompanyProgress) =>
						new OnboardCompanyProgress(resp.companyId, resp.isOnboardingComplete, resp.currentStep, resp.currentSubStep)
				)
			);
	}
}
