import { Component, ViewChild } from "@angular/core";
import { CompanyProfileRelationship } from "src/app/Models";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import copy from "fast-copy";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { Actions } from "@ngrx/effects";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";

@Component({
	selector: "ls-autoxcel",
	templateUrl: "./autoxcel.component.html",
	styleUrls: ["./autoxcel.component.scss"]
})
export class AutoXcelComponent extends CustomerRelationshipBaseComponent {
	public companyProfileRelationship?: CompanyProfileRelationship;
	private _isAutoXcel = true;
	toggledState = "Disabled";
	get isAutoXcel() {
		return this._isAutoXcel;
	}
	set isAutoXcel(isAutoXcel: boolean) {
		this._isAutoXcel = isAutoXcel;
		this.toggledState = isAutoXcel ? "Enabled" : "Disabled";
	}
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors
	) {
		super(router, activatedRoute, store, actions$, companyProfileSelectors);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.isAutoXcel = cpr!.isAutoXcel!;
					if (cpr?.relationshipCompanyName && this.breadcrumbs && this.breadcrumbs.length < 2)
						this.breadcrumbs.push({ routerPath: "", displayText: cpr?.relationshipCompanyName ?? "" });
				})
			)
			.subscribe();
	}

	continue() {
		if (this.isAutoXcel != this.companyProfileRelationship?.isAutoXcel) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.isAutoXcel = this.isAutoXcel;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
}
