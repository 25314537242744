export class AccountSubmissionForm {
	legalBusinessName?: string;
	annualRevenueRangeId?: string;
	firstName?: string;
	lastName?: string;
	title?: string;
	phoneCountryCode?: string;
	phoneNumber?: string;
	phoneExt?: string;
	email?: string;
	agreedToTerms?: boolean;

	constructor(
		legalBusinessName?: string,
		annualRevenueRangeId?: string,
		firstName?: string,
		lastName?: string,
		title?: string,
		phoneCountryCode?: string,
		phoneNumber?: string,
		phoneExt?: string,
		email?: string,
		agreedToTerms?: boolean
	) {
		this.legalBusinessName = legalBusinessName;
		this.annualRevenueRangeId = annualRevenueRangeId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.title = title;
		this.phoneCountryCode = phoneCountryCode;
		this.phoneNumber = phoneNumber;
		this.phoneExt = phoneExt;
		this.email = email;
		this.agreedToTerms = agreedToTerms;
	}
}
