<div class="d-flex flex-column">
  <h1 class="l ls-mb-8">Link your business bank account</h1>
  <p class="l ls-mb-2">Visibility into your business bank account gives Raistone clarity into customer relationships and payments which is helpful as we build out the most attractive financing options for you.</p>
  <p class="l ls-mb-11">You’ll be asked to log into your bank in order to authorize access.</p>
  <div class="d-flex flex-md-row flex-column justify-content-between">
    <button type="button" class="btn btn-primary btn-lg ls-mb-3 ls-mb-md-0" (click)="connectAccounts()">Connect bank accounts</button>
    <button type="button" class="btn btn-primary-borderless btn-do-it-later" (click)="openModal()">I'll do it later</button>
  </div>
</div>

