import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CompanyDocumentSelectors } from "../../../OnboardingStateManagement";
import { Actions } from "@ngrx/effects";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { FileUploadBaseComponent } from "../../../../../Core/file-upload-base.component";
import { Location } from "@angular/common";
import { combineLatest, filter, map, takeUntil, withLatestFrom } from "rxjs";
import { CompanyProfileActions, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { FeatureFlagSelectors, FeatureFlags } from "@limestone/ls-shared-modules";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import copy from "fast-copy";
import { CompanyProfile } from "src/app/Models";

@Component({
	selector: "ls-w9-tax-form-upload",
	templateUrl: "./w9-tax-form-upload.component.html",
	styleUrls: ["./w9-tax-form-upload.component.scss"]
})
export class W9TaxFormUploadComponent extends FileUploadBaseComponent {
	public companyProfile?: CompanyProfile;
	public isV1_0_2_FlagEnabled?: boolean;

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public store: Store,
		public companyDocumentSelectors: CompanyDocumentSelectors,
		public dialog: MdbModalService,
		public companyProfileSelectors: CompanyProfileSelectors,
		public actions$: Actions,
		public location: Location,
		public featureFlagSelectors: FeatureFlagSelectors
	) {
		super(router, activatedRoute, store, dialog, actions$, location);
		this.allowedFileExtensions = ["JPEG", "PNG", "GIF", "PDF"];
		this.maxFileCount = 1;
		this.initData();
	}

	TAX_ID = "taxId";
	taxIdForm: FormGroup = new FormGroup({});

	public navTo() {
		let url: string = this.activatedRoute.snapshot.data["nextUrl"];
		if (this.isV1_0_2_FlagEnabled) {
			url = url.concat(this.companyProfile?.isCustomersInProgress ? "/review" : "/manual");
		}
		this.router.navigate([url], { relativeTo: this.activatedRoute });
	}

	controlHasError(controlName: string): boolean {
		if (this.taxIdForm.get(controlName)) {
			return (
				this.taxIdForm.get(controlName)!.invalid &&
				(this.taxIdForm.get(controlName)!.dirty || this.taxIdForm.get(controlName)!.touched)
			);
		}
		return false;
	}

	getControlError(controlName: string): string {
		const error: string[] = [];
		if (this.taxIdForm.get(controlName)?.errors!["pattern"]) {
			error.push("Please limit EIN to 9 numbers.");
		}
		return error.join(" ");
	}

	public isNextButtonDisabled(): boolean {
		return this.isV1_0_2_FlagEnabled ? this.controlHasError(this.TAX_ID) : this.isDisabled();
	}

	public continue() {
		if (this.isV1_0_2_FlagEnabled) {
			const updatedCompanyProfile = copy(this.companyProfile!);
			updatedCompanyProfile.taxId = this.taxIdForm.get(this.TAX_ID)?.value;
			this.store.dispatch(
				CompanyProfileActions.updateCompanyProfile({
					companyProfile: updatedCompanyProfile
				})
			);
		}
		this.navTo();
	}

	public initData(): void {
		combineLatest([
			this.store.select(this.companyDocumentSelectors.selectDocumentsFor(this.getDocType())),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				takeUntil(this.getComponentTearDown()),
				withLatestFrom(this.store.select(this.companyProfileSelectors.selectCompanyProfile)),
				filter(([[docs, ff], cp]) => !!docs && !!cp && !!ff),
				map(([[docs, ff], cp]) => {
					this.files = docs;
					this.companyProfile = cp;
					this.disabled = this.files!.length! >= this.maxFileCount;
					this.companyProfile = cp;
					this.setCompanyId(cp!.companyId!);
					this.errors = [];
					this.submitted = cp!.hasSubmittedApplications ?? false;
					this.isV1_0_2_FlagEnabled = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!.enabled;
				})
			)
			.subscribe();
		if (this.isV1_0_2_FlagEnabled) {
			this.taxIdForm = new FormGroup({
				taxId: new FormControl(this.companyProfile?.taxId, [Validators.pattern(/^\d{0,9}$/)])
			});
		}
	}
}
