import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable, takeUntil } from "rxjs";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import {
	FeatureFlagSelectors,
	FeatureFlags,
	GenericLookup,
	GenericSelectors,
	LookupTables
} from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { CompanyProfile } from "../../../../../Models";

@Component({
	selector: "ls-company-industry",
	templateUrl: "./company-industry.component.html",
	styleUrls: ["./company-industry.component.scss"]
})
export class CompanyIndustryComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store,
		private companyProfileSelectors: CompanyProfileSelectors,
		private genericSelectors: GenericSelectors,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public featureFlagSelectors: FeatureFlagSelectors
	) {
		super(store);
	}

	INDUSTRY = "industry";
	INDUSTRY_SEARCH = "industrySearch";

	industries: GenericLookup<string>[] = [];
	filteredIndustries: GenericLookup<string>[] = [];
	private isUSBased?: boolean;
	public isV1_0_2_FlagEnabled?: boolean;

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		updatedCompanyProfile.industryId = this.form.get(this.INDUSTRY)?.value;
		this.emitSave(updatedCompanyProfile);
		const path = this.isUSBased
			? "../w-9"
			: this.isV1_0_2_FlagEnabled
			? "../../customer-setup"
			: "../../financing-intentions";
		this.router.navigate([path], { relativeTo: this.activatedRoute });
	}

	isInvalid() {
		return this.form.invalid;
	}

	init(): Observable<CompanyProfile | undefined> {
		this.form = new FormGroup({
			industrySearch: new FormControl(""),
			industry: new FormControl(null, Validators.required)
		});
		combineLatest([
			this.store.select(this.genericSelectors.selectLookup(LookupTables.Industry)),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				filter(([ind, ff]) => !!ind && !!ff),
				takeUntil(this.componentTeardown$),
				map(([ind, ff]) => {
					this.industries = ind!;
					this.filteredIndustries = ind!;
					this.isV1_0_2_FlagEnabled = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!.enabled;
				})
			)
			.subscribe();

		this.form
			.get(this.INDUSTRY_SEARCH)!
			.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((searchVal) => {
					this.filteredIndustries = this.industries.filter((ind) =>
						ind.name!.toLowerCase().includes(searchVal.toLowerCase())
					);
				})
			)
			.subscribe();

		return combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.IsUSBased)
		]).pipe(
			filter(([cp, isUSBased]) => !!cp && isUSBased !== undefined),
			map(([cp, isUSBased]) => {
				this.companyProfile = cp;
				this.isUSBased = isUSBased;
				this.form.get(this.INDUSTRY)?.patchValue(this.companyProfile?.industryId);
				return cp;
			})
		);
	}
}
