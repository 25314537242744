import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { combineLatest, filter, map, Subject, take, takeUntil } from "rxjs";
import { ContinueApplicationService } from "../../../../../services";
import {
	CompanyProfileSelectors,
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileActions
} from "../../../../AppStateManagement";
import { CompanyProfile, CompanyProfileRelationship, OnboardCompanyProgress, Page } from "../../../../Models";
import { ApplicationSection } from "../../../../Models/Enums";
import { SubmitPartialApplicationComponent } from "../../Modals";
import { ModalActions } from "../../../../Core";
import { OnboardApplicationStatus } from "@limestone/ls-shared-modules";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-review-application",
	templateUrl: "./review-application.component.html",
	styleUrls: ["./review-application.component.scss"]
})
export class ReviewApplicationComponent implements OnInit, OnDestroy {
	private readonly ICON_COMPLETE: string = "check_circle";
	private readonly ICON_PARTIAL: string = "warning";
	private readonly ICON_INCOMPLETE: string = "error";

	private readonly ICON_CLASS_COMPLETE: string = "ls-icon-primary-no-background";
	private readonly ICON_CLASS_PARTIAL: string = "ls-icon-tertiary-no-background";
	private readonly ICON_CLASS_INCOMPLETE: string = "ls-icon-error-no-background";

	protected componentTeardown$ = new Subject();
	companyProfile?: CompanyProfile;
	onboardCompanyProgress?: OnboardCompanyProgress;
	companyProfileRelationships?: CompanyProfileRelationship[];
	completedCustomers?: CompanyProfileRelationship[];
	incompleteCustomers?: CompanyProfileRelationship[];

	isConnectFinancialsComplete = false;
	isCustomerSetupComplete = false;
	canSubmitApplication = false;

	constructor(
		private store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public companyProfileSelectors: CompanyProfileSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		private continueApplicationService: ContinueApplicationService,
		private dialog: MdbModalService
	) {}

	public get ApplicationSection(): typeof ApplicationSection {
		return ApplicationSection;
	}

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships)
		])
			.pipe(
				filter(([cp, ocp, cprs]) => !!cp && !!ocp && !!cprs),
				takeUntil(this.componentTeardown$),
				map(([cp, ocp, cprs]) => {
					this.companyProfile = cp;
					this.onboardCompanyProgress = ocp;
					// only customers that have not been submitted yet
					this.companyProfileRelationships = cprs!.filter(
						(cpr) => !cpr.applicationStatusId || cpr.applicationStatusId === OnboardApplicationStatus.INPROGRESS
					);
					this.completedCustomers = [];
					this.incompleteCustomers = [];

					// Connect Financials
					this.isConnectFinancialsComplete =
						this.companyProfile!.isAccountingPlatformConnected! && this.companyProfile!.isBankingPlatformConnected!;

					// Customer Setup
					this.companyProfileRelationships.forEach((cpr) => {
						if (cpr.isCustomerSetupComplete) {
							this.completedCustomers!.push(cpr);
						} else {
							this.incompleteCustomers!.push(cpr);
						}
					});

					this.isCustomerSetupComplete =
						this.companyProfileRelationships!.length > 0 &&
						this.incompleteCustomers?.length === 0 &&
						this.completedCustomers?.length === this.companyProfileRelationships!.length;
					this.canSubmitApplication =
						(this.isCustomerSetupComplete || (this.completedCustomers && this.completedCustomers.length > 0)) ?? false;
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	navToConnectFinancials() {
		this.router.navigate(["../connect-financials/introduction"], { relativeTo: this.activatedRoute });
	}

	navToCompanyDetails() {
		this.router.navigate(["../company-details/introduction"], { relativeTo: this.activatedRoute });
	}

	navToFinancingIntentions() {
		this.router.navigate(["../financing-intentions/amount-frequency"], { relativeTo: this.activatedRoute });
	}

	navToCustomerSetup() {
		this.router.navigate(["../customer-setup/manual"], { relativeTo: this.activatedRoute });
	}

	navToCustomer(customer: CompanyProfileRelationship) {
		if (customer.isCustomerSetupComplete) {
			this.router.navigate([`../customer-setup/customer-contact/${customer.relationshipCompanyId}`], {
				relativeTo: this.activatedRoute
			});
		} else {
			this.continueApplicationService.continueCustomerSetup(customer.relationshipCompanyId!, customer.currentSubStep!);
		}
	}

	getSectionIcon(appSection: ApplicationSection): string {
		switch (appSection) {
			case ApplicationSection.CONNECT_FINANCIALS:
				return this.getConnectFinancialsIcon();
			case ApplicationSection.CUSTOMER_SETUP:
				return this.getCustomerSetupIcon();
		}
		return "";
	}

	getSectionIconClass(appSection: ApplicationSection): string {
		switch (appSection) {
			case ApplicationSection.CONNECT_FINANCIALS:
				return this.getConnectFinancialsIconClass();
			case ApplicationSection.CUSTOMER_SETUP:
				return this.getCustomerSetupIconClass();
		}
		return "";
	}

	getConnectFinancialsIcon(): string {
		let icon: string = this.ICON_COMPLETE;
		if (!this.isConnectFinancialsComplete) {
			icon = this.ICON_PARTIAL;
		}

		return icon;
	}

	getConnectFinancialsIconClass(): string {
		let iconClass: string = this.ICON_CLASS_COMPLETE;
		if (!this.isConnectFinancialsComplete) {
			iconClass = this.ICON_CLASS_PARTIAL;
		}

		return iconClass;
	}

	getConnectFinancialsMessage(): string {
		let message = "";
		if (!this.companyProfile?.isAccountingPlatformConnected) {
			message = "Please connect your accounting platform";
		} else if (!this.companyProfile?.isBankingPlatformConnected) {
			message = "Please connect your bank account";
		}

		return message;
	}

	getCustomerSetupIcon(): string {
		if (this.isCustomerSetupComplete) {
			return this.ICON_COMPLETE;
		} else if (this.completedCustomers?.length === 0) {
			return this.ICON_INCOMPLETE;
		} else {
			return this.ICON_PARTIAL;
		}
	}

	getCustomerSetupIconClass(): string {
		if (this.isCustomerSetupComplete) {
			return this.ICON_CLASS_COMPLETE;
		} else if (this.completedCustomers?.length === 0) {
			return this.ICON_CLASS_INCOMPLETE;
		} else {
			return this.ICON_CLASS_PARTIAL;
		}
	}

	submitModal() {
		if (this.incompleteCustomers!.length > 0) {
			this.dialog
				.open(SubmitPartialApplicationComponent, {
					modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
					ignoreBackdropClick: true,
					data: {
						completedCustomers: this.completedCustomers,
						incompleteCustomers: this.incompleteCustomers
					}
				})
				.onClose.pipe(
					take(1),
					filter((action: ModalActions) => action === ModalActions.PRIMARY),
					map(() => this.submitApplication())
				)
				.subscribe();
		} else {
			this.submitApplication();
		}
	}

	submitApplication() {
		this.store.dispatch(
			CompanyProfileRelationshipActions.submitApplication({ companyProfileId: this.companyProfile!.companyId! })
		);
		this.onboardCompanyProgress!.isOnboardingComplete = true;
		this.store.dispatch(CompanyProfileActions.updateOnboardCompanyProgress({ progress: this.onboardCompanyProgress! }));
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute });
	}
}
