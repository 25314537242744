import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-company-details-intro",
	templateUrl: "./company-details-intro.component.html",
	styleUrls: ["./company-details-intro.component.scss"]
})
export class CompanyDetailsIntroComponent {
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute
	) {}

	onClick() {
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute, replaceUrl: true });
	}
}
