import { Component, ViewChild } from "@angular/core";
import { CompanyProfileRelationship } from "src/app/Models";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import copy from "fast-copy";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { Actions } from "@ngrx/effects";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-preferred-payment",
	templateUrl: "./preferred-payment.component.html",
	styleUrls: ["./preferred-payment.component.scss"]
})
export class PreferredPaymentComponent extends CustomerRelationshipBaseComponent {
	PREFERRED_PAYMENT_METHOD = "preferredPaymentMethod";
	VIRTUAL_CARD = "CARD";
	ppmForm: FormGroup = new FormGroup({});

	nextButtonText = "Complete Customer Setup";

	public companyProfileRelationship?: CompanyProfileRelationship;
	public preferredPaymentMethods?: GenericLookup<string>[];

	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors
	) {
		super(router, activatedRoute, store, actions$, companyProfileSelectors);
		this.initData();
	}

	initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					if (cpr?.relationshipCompanyName && this.breadcrumbs && this.breadcrumbs.length < 2)
						this.breadcrumbs.push({ routerPath: "", displayText: cpr?.relationshipCompanyName ?? "" });
				})
			)
			.subscribe();

		this.store
			.select(this.genericSelectors.selectLookup(LookupTables.PreferredPaymentMethod))
			.pipe(
				filter((ppm) => !!ppm && ppm.length > 0),
				takeUntil(this.componentTeardown$),
				map((ppm) => {
					this.preferredPaymentMethods = ppm!;
				})
			)
			.subscribe();

		this.ppmForm = new FormGroup({
			preferredPaymentMethod: new FormControl(
				this.companyProfileRelationship?.preferredPaymentMethodId,
				Validators.required
			)
		});
	}

	continue() {
		if (this.ppmForm.dirty) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.preferredPaymentMethodId = this.ppmForm.get(this.PREFERRED_PAYMENT_METHOD)
				?.value;
			updatedCompanyProfileRelationship.isCustomerSetupComplete = true;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}

	navigateToNextScreen(): void {
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute });
	}
}
