<ls-questionnaire-toggle #questionnaireForm
        toggleLabel="Confirm Invoice Approvals"
        [(enabled)] = isInstrumentApprovalConfirmed
        [toggledState] = "toggledState"
        header="Do you want to wait until Raistone receives invoice approvals from your customer before getting financing?"
        [breadcrumbs]="breadcrumbs"
        (buttonClicked)="continue()">
  <div>
    <p class="l ls-mb-0">Getting <a class="ls-hyperlink" href="https://raistone.com/help/customer-invoice-approval/" target="_blank">invoice approval from your customers</a> will result in:</p>
    <ul class="ls-mb-0">
      <li>Lower discount fee — we can provide you with better pricing.</li>
      <li>Increased advance rate — we’ll be able to buy a larger amount of each invoice.</li>
      <li>Larger line of credit we can provide your business.</li>
    </ul>
  </div>
</ls-questionnaire-toggle>  