<div class="d-flex flex-column">
    <form class="d-flex flex-column ls-mb-6" [formGroup]="form">
        <h1 class="no-text-transform ls-mb-6 l">Let’s get the rest of your company info</h1>
        <div class="d-flex flex-column">
            <p class="no-text-transform title m ls-mb-3">What’s the main office phone number?</p>
            <ls-phone-form-control class="phone-address-form ls-mb-6" [formControlName]="PHONE"></ls-phone-form-control>
            <div class="d-flex flex-column ls-mb-6">
                <p class="no-text-transform title m ls-mb-2">What’s your company’s primary place of business?</p>
                <p class="ls-mb-3">It can also be your company’s headquarters.</p>
                <ls-address-form-control class="phone-address-form" [formControlName]="PRIMARY"></ls-address-form-control>
            </div>
            <ng-container *ngIf="!isV1_0_2_FlagEnabled">
                <div class="d-flex" [class.ls-mb-3]="toggled">
                    <input
                        mdbCheckbox
                        class="form-check-input ls-mr-3"
                        type="checkbox"
                        value=""
                        id="billingAddressCheck"
                        [checked]="toggled"
                        [disabled]="form.disabled"
                        (click)="toggleBillingForm()"
                    />
                    <label class="form-check-label xl" for="billingAddressCheck">
                        Different billing address
                    </label>
                </div>
                <ls-address-form-control class="phone-address-form" [hidden]="!toggled" [formControlName]="BILLING"></ls-address-form-control>
            </ng-container>
        </div>
    
    </form>
    <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" [disabled]="isInvalid()"  #lsNextBtn>
        Next <span class="material-icons md-18"> arrow_forward</span>
    </button>
</div>