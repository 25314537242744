<div *ngIf="!readOnly; else readOnlyView" class="d-flex flex-column" [formGroup]="platformsUsedForm">
    <ls-platform-select 
        [platforms]="platforms" 
        [formControlName]="INSTRUMENT_PLATFORM" 
        label="Invoice Source">
    </ls-platform-select>
    <ls-platform-select 
        [platforms]="platforms" 
        [formControlName]="INSTRUMENT_APPROVAL_PLATFORM" 
        label="Invoice Approval Status Source">
    </ls-platform-select>
    <ls-platform-select 
        [platforms]="platforms" 
        [formControlName]="REMITTANCE_PLATFORM" 
        label="Remittance Data Source">
    </ls-platform-select>
</div>
  
<ng-template #readOnlyView>
    <ls-platforms-used-readonly [companyProfileRelationship]="companyProfileRelationship"></ls-platforms-used-readonly>
</ng-template>