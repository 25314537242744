import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConnectFinancialsResultService } from "src/services/connect-financials-result.service";
import { CodatConnectionType } from "src/app/Models/Enums/CodatConnectionType";
import { BehaviorSubject, Subject } from "rxjs";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-account-platform-result",
	templateUrl: "./account-platform-result.component.html",
	styleUrls: ["./account-platform-result.component.scss"],
	providers: [ConnectFinancialsResultService]
})
export class AccountPlatformResultComponent implements OnInit {
	public componentTeardown$ = new Subject();
	public success$: BehaviorSubject<boolean> = this.connectFinancialsResultService.success$;

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private connectFinancialsResultService: ConnectFinancialsResultService
	) {}

	public ngOnInit() {
		this.connectFinancialsResultService.init(CodatConnectionType.ACCOUNTING);
	}

	public tryAgain() {
		this.router.navigate(["../accounting"], { relativeTo: this.activatedRoute });
	}

	public continue() {
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute });
	}
}
