<div class="case-message ls-mb-4 w-100" (click)="expanded = true"
    [ngClass]="{'internal-user': message.fromAddress !== userEmail, 'external-user': message.fromAddress === userEmail, 'collapsed': !expanded }">
    <div class="d-flex flex-row justify-content-between ls-mb-3">
        <label class="m-caps">{{ message.fromAddress }}</label>
        <label class="xs message-date">{{ message.messageDate | date }}</label>
    </div>
    <div class="d-flex flex-row message-body ls-mb-3">
        <span [innerHTML]="message.htmlBody" *ngIf="expanded && message.htmlBody; else textBody"></span>
        <ng-template #textBody>
            <span >{{message.textBody}}</span>
        </ng-template>
    </div>
    <div class="d-flex flex-row justify-content-xxl-center" *ngIf="expanded && message.hasAttachment">
        <ls-case-message-attachments class="ls-w-100" [attachments]="message.attachments" (downloadAttachment)="downloadFile($event)"></ls-case-message-attachments>
    </div>
</div>