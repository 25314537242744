<div class="d-flex flex-column">
    <ng-content select="[header]"></ng-content>
    <ng-content select="[description]"></ng-content>
    <ls-upload-widget
            [maxFileCount]="maxFileCount"
            [maxFileSize]="maxFileSize"
            [disabled]="disabled"
            (files)="emitFiles($event)"
            [allowedFileExtensions]="allowedFileExtensions"
            (fileUploadError)="emitError($event)"
            ></ls-upload-widget>
    <ng-content select="[errors]"></ng-content>
    <ls-file-data-grid *ngIf="files && files.length > 0" [files]="files" class="ls-mt-8"
                       [submitted]="submitted"
                       (sortChanged)="handleSortChange($event)"
                       (openFileClicked)="openFile($event)"
                       (deleteFileClicked)="deleteFile($event)"></ls-file-data-grid>
    <ng-content select="[content]"></ng-content>
    <ng-content select="[input]"></ng-content>
    <p [class.ls-mt-11]="submitted" class="ls-mb-4" *ngIf="submitted">If you wish to modify the information above, please reach out to <a href="mailto:{{environment.supportEmailAddress}}"><strong>{{environment.supportEmailAddress}}</strong></a></p>
    <div [class.ls-mt-11]="!submitted">
        <button type="button" (click)="onClick()" class="btn btn-primary btn-lg btn-icon" [disabled]="nextButtonDisabled">
            Next <span class="material-icons md-18">arrow_forward</span>
        </button>
    </div>
</div>