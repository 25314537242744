import { Component, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { FinanceDisclosureCode, GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-notice-of-assignment",
	templateUrl: "./notice-of-assignment.component.html",
	styleUrls: ["./notice-of-assignment.component.scss"]
})
export class NoticeOfAssignmentComponent extends CustomerRelationshipBaseComponent {
	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public genericSelectors: GenericSelectors,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors
	) {
		super(router, activatedRoute, store, actions$, companyProfileSelectors);
		this.initData();
	}

	NOA = "noa";
	noticeOfAssignmentForm: FormGroup = new FormGroup({});
	companyProfileRelationship?: CompanyProfileRelationship;
	public financeDisclosures?: GenericLookup<string>[] = [];
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	public tooltips: Map<string, string> = new Map([
		[
			FinanceDisclosureCode.DD,
			"If you decide to continue to collect payment from your customer, you’ll get less favorable pricing.\n\n" +
				"You wouldn’t disclose this financing to your customer."
		],
		[
			FinanceDisclosureCode.DN,
			"If you decide to notify your customer to pay Raistone directly, we'll provide them with a Notice of Assignment (NOA) to sign and return.\n\n" +
				"We can manage the notification and do all the collections without your intervention.\n\n" +
				"You can receive more favorable pricing if you notify your customer to pay Raistone."
		],
		[
			FinanceDisclosureCode.IC,
			"You wouldn’t have to disclose this financing to your customer, simply tell your customer to pay you in a different bank account.\n\n" +
				"It’s an existing bank account that Raistone manages. We’ll provide you with the account information."
		]
	]);

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					if (cpr?.relationshipCompanyName && this.breadcrumbs && this.breadcrumbs.length < 2)
						this.breadcrumbs.push({ routerPath: "", displayText: cpr?.relationshipCompanyName ?? "" });
				})
			)
			.subscribe();

		this.store
			.select(this.genericSelectors.selectLookup(LookupTables.FinanceDisclosure))
			.pipe(
				filter((fd) => !!fd),
				takeUntil(this.componentTeardown$),
				map((fd) => (this.financeDisclosures = fd))
			)
			.subscribe();

		this.noticeOfAssignmentForm = new FormGroup({
			noa: new FormControl(this.companyProfileRelationship?.financeDisclosureId, Validators.required)
		});
	}

	isFormInvalid() {
		return this.noticeOfAssignmentForm.invalid;
	}

	continue() {
		if (this.noticeOfAssignmentForm.dirty) {
			const selectedFinanceDisclosure = this.noticeOfAssignmentForm.get(this.NOA)?.value;
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.financeDisclosureId = selectedFinanceDisclosure;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
}
