import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: "ls-manual",
	templateUrl: "./manual.component.html",
	styleUrls: ["./manual.component.scss"]
})
export class ManualComponent {
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute
	) {}

	navToAccounting() {
		this.router.navigate(["../../connect-financials/accounting"], { relativeTo: this.activatedRoute });
	}

	continue() {
		this.router.navigate(["../customer-contact/new"], { relativeTo: this.activatedRoute });
	}
}
