import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CompanyProfileRelationship } from "../../../../../Models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import copy from "fast-copy";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import {
	CurrencyCode,
	FeatureFlagSelectors,
	FeatureFlags,
	GenericLookup,
	GenericSelectors,
	LookupTables
} from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-customer-revenue",
	templateUrl: "./customer-revenue.component.html",
	styleUrls: ["./customer-revenue.component.scss"]
})
export class CustomerRevenueComponent extends CustomerRelationshipBaseComponent {
	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public genericSelectors: GenericSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public featureFlagSelectors: FeatureFlagSelectors
	) {
		super(router, activatedRoute, store, actions$, companyProfileSelectors);
		this.initData();
	}

	public isV1_0_2_FlagEnabled?: boolean;

	ONBOARD_INSTRUMENT_RANGE = "onboardInstrumentRangeControl";
	ONBOARD_REVENUE_RANGE = "onboardRevenueRangeControl";
	ACCOUNTS_RECEIVABLE_REVENUE_RANGE = "accountsReceivableRevenueRangesControl";
	customerRelationship3Form: FormGroup = new FormGroup({});
	companyProfileRelationship?: CompanyProfileRelationship;
	public onboardInstrumentRanges: GenericLookup<string>[] = [];
	public onboardRevenueRanges: GenericLookup<string>[] = [];
	public accountsReceivableRevenueRanges: GenericLookup<string>[] = [];
	currencyCode = CurrencyCode.USD;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	public initData(): void {
		combineLatest([
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				filter(([cpr, ff]) => !!cpr && !!ff),
				takeUntil(this.componentTeardown$),
				map(([cpr, ff]) => {
					this.isV1_0_2_FlagEnabled = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!.enabled;
					this.companyProfileRelationship = cpr;
					this.currencyCode = cpr!.isOtherCurrency ? CurrencyCode.USD : cpr!.currencyCode!;
					if (cpr?.relationshipCompanyName && this.breadcrumbs && this.breadcrumbs.length < 2)
						this.breadcrumbs.push({ routerPath: "", displayText: cpr?.relationshipCompanyName ?? "" });
				})
			)
			.subscribe();

		combineLatest([
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardInstrumentRange)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardRevenueRange))
		])
			.pipe(
				filter(([oir, rr]) => !!oir && !!rr),
				takeUntil(this.componentTeardown$),
				map(([oir, rr]) => {
					this.onboardRevenueRanges = rr!;
					if (!this.isV1_0_2_FlagEnabled) {
						this.onboardInstrumentRanges = oir!;
						this.accountsReceivableRevenueRanges = rr!;
					}
				})
			)
			.subscribe();

		if (this.isV1_0_2_FlagEnabled) {
			this.customerRelationship3Form = new FormGroup({
				onboardRevenueRangeControl: new FormControl(
					this.companyProfileRelationship?.onboardRevenueRangeId,
					Validators.required
				)
			});
		} else {
			this.customerRelationship3Form = new FormGroup({
				onboardInstrumentRangeControl: new FormControl(
					this.companyProfileRelationship?.onboardInstrumentRangeId,
					Validators.required
				),
				onboardRevenueRangeControl: new FormControl(
					this.companyProfileRelationship?.onboardRevenueRangeId,
					Validators.required
				),
				accountsReceivableRevenueRangesControl: new FormControl(
					this.companyProfileRelationship?.arBalanceRangeId,
					Validators.required
				)
			});
		}
	}

	isFormInvalid() {
		return this.customerRelationship3Form.invalid;
	}

	continue() {
		if (this.customerRelationship3Form.dirty) {
			const selectedAnnualSales = this.customerRelationship3Form.get(this.ONBOARD_REVENUE_RANGE)?.value;
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.onboardRevenueRangeId = selectedAnnualSales;
			if (!this.isV1_0_2_FlagEnabled) {
				const selectedAverageInvoiceAmount = this.customerRelationship3Form.get(this.ONBOARD_INSTRUMENT_RANGE)?.value;
				const selectedAccountsReceivableTotal = this.customerRelationship3Form.get(
					this.ACCOUNTS_RECEIVABLE_REVENUE_RANGE
				)?.value;
				updatedCompanyProfileRelationship.onboardInstrumentRangeId = selectedAverageInvoiceAmount;
				updatedCompanyProfileRelationship.arBalanceRangeId = selectedAccountsReceivableTotal;
			}
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
}
