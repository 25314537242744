<div class="d-flex flex-column ls-mt-8 ls-mb-8">
  <form [formGroup]="companyProfileReadonlyForm">

      <div class="d-flex flex-column ls-mb-7" >
        <div class="ls-mb-3">
          <label class="s-caps">BUSINESS NAMES</label>
        </div>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column notEditable ls-mr-15">
            <label class="s ls-mb-1">Legal Business Name</label>
            <label class="l-bold">{{ companyProfile?.legalBusinessName }}</label>
          </div>
          <div class="d-flex flex-column" *ngIf="!_isV1_0_2_FlagEnabled">
            <label class="s ls-mb-1">Doing Business As Name</label>
            <div *ngFor="let dbaName of companyProfile?.dbaNames">
              <label class="l-bold">{{ dbaName }}</label>
            </div>
          </div>
        </div>
      </div>
      
      <div class="ls-mb-7 ls-w-lg-50">
        <div class="ls-mb-3">
          <label class="s-caps">OFFICE PHONE NUMBER</label>
        </div>
        <ls-phone-form-control [formControlName]="PHONE" ></ls-phone-form-control>
      </div>
      
      <div class="d-flex flex-column ls-mb-8">
        <div class="ls-mb-3">
          <label class="s-caps">PRIMARY ADDRESS</label>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-column notEditable ls-mr-15 ls-mb-7">
            <label class="s ls-mb-1">Legal Address</label>
            <label class="l-bold">{{ primaryAddress?.address?.address1 }}</label>
            <label class="l-bold">{{ primaryAddress?.address?.address2 }}</label>
            <label class="l-bold">{{ primaryAddress?.address?.city }}</label>
            <label class="l-bold">{{ primaryAddress?.address?.state?.name }}</label>
            <label class="l-bold">{{ primaryAddress?.address?.postalCode }}</label>
            <label class="l-bold">{{ primaryAddress?.address?.country?.name }}</label>
          </div>
          <div class="justify-content-start w-50" *ngIf="!_isV1_0_2_FlagEnabled">
            <div class="d-flex flex-row">
              <input
                      mdbCheckbox
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="billingAddressCheck"
                      [checked]="toggled"
                      disabled
              />
              <label [class.ls-mb-3]="toggled" class="form-check-label xl" for="billingAddressCheck">
                Different billing address
              </label>
            </div>
            <div [class.d-none]="!toggled" class="d-flex flex-column">
              <div class="d-flex flex-column notEditable ls-mr-15">
                <label class="l-bold">{{ billingAddress?.address?.address1 }}</label>
                <label class="l-bold">{{ billingAddress?.address?.address2 }}</label>
                <label class="l-bold">{{ billingAddress?.address?.city }}</label>
                <label class="l-bold">{{ billingAddress?.address?.state?.name }}</label>
                <label class="l-bold">{{ billingAddress?.address?.postalCode }}</label>
                <label class="l-bold">{{ billingAddress?.address?.country?.name }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <p class="ls-mb-0">If you wish to modify your business names or legal address, please reach out to <a class="email-link" href="mailto:{{environment.supportEmailAddress}}">{{environment.supportEmailAddress}}.</a></p>

  </form>
</div>




