import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Subject, delay, map, takeUntil } from "rxjs";
import { CodatActions } from "src/app/AppStateManagement";
import { CompanyProfile } from "src/app/Models";
import { IntegrationMessageType } from "src/app/Models/Enums";
import { CodatConnectionType } from "src/app/Models/Enums/CodatConnectionType";
import { IAlert } from "src/app/Models/Interfaces";
import { ConnectFinancialsResultService } from "src/services/connect-financials-result.service";

@Component({
	selector: "ls-integrations",
	templateUrl: "./integrations.component.html",
	styleUrls: ["./integrations.component.scss"],
	providers: [ConnectFinancialsResultService]
})
export class IntegrationsComponent implements OnInit, OnDestroy {
	@Input() get companyProfile() {
		return this._companyProfile;
	}
	set companyProfile(cp) {
		this._companyProfile = cp;
		this.accountingButtonText = cp?.isAccountingPlatformConnected ? "Disconnect Accounting" : "Connect Accounting";
		this.bankingButtonText = cp?.isBankingPlatformConnected ? "Disconnect Banking" : "Connect Banking";
	}
	@Output() showMessage: EventEmitter<IAlert> = new EventEmitter();

	public accountingButtonText?: string;
	public bankingButtonText?: string;
	private _companyProfile?: CompanyProfile;
	private _componentTeardown$: Subject<any> = new Subject<any>();

	constructor(
		private _store: Store,
		private _router: Router,
		public actions$: Actions,
		private _activatedRoute: ActivatedRoute,
		private _connectFinancialsResultService: ConnectFinancialsResultService
	) {}

	public ngOnInit(): void {
		this._activatedRoute.queryParams
			?.pipe(
				takeUntil(this._componentTeardown$),
				map((params) => {
					const connectionType = params["connectType"];
					if (connectionType) {
						this._handleSuccessSubscription(connectionType);
						this._connectFinancialsResultService.init(connectionType);
					}
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this._componentTeardown$),
				ofType(CodatActions.deleteConnectionSuccessful, CodatActions.deleteCompanyPlatformSuccessful),
				map((action) => {
					const platform =
						action.type === CodatActions.deleteCompanyPlatformSuccessful.type ? "accounting system" : "bank accounts";
					this.showMessage.emit({
						message: `You successfully disconnected your ${platform}.`,
						type: IntegrationMessageType.DISCONNECT_SUCCESS,
						icon: "info"
					});
				}),
				delay(5000),
				map(() => this.showMessage.emit(undefined))
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this._componentTeardown$.next(null);
		this._componentTeardown$.complete();
	}

	private _handleSuccessSubscription(connectionType: string): void {
		const platform = connectionType === CodatConnectionType.ACCOUNTING ? "accounting system" : "bank accounts";
		this._connectFinancialsResultService.success$
			.pipe(
				takeUntil(this._componentTeardown$),
				map((result) => {
					let newAlert: IAlert;
					if (result) {
						newAlert = {
							message: `You successfully connected your ${platform}`,
							type: IntegrationMessageType.CONNECT_SUCCESS,
							icon: "check_circle"
						};
					} else {
						newAlert = {
							message: `There was an error connecting your ${platform}. Please try again.`,
							type: IntegrationMessageType.ERROR,
							icon: "error"
						};
					}
					this.showMessage.emit(newAlert);
				}),
				delay(5000),
				map(() => {
					this._resetQueryParams();
					this.showMessage.emit(undefined);
				})
			)
			.subscribe();
	}

	public createOrDeleteAccountingConnection() {
		if (this.companyProfile?.isAccountingPlatformConnected) {
			this._store.dispatch(CodatActions.deleteCompanyPlatform({ companyProfile: this.companyProfile! }));
		} else {
			this._router.navigate(["../../home/settings/connect-accounting"], { relativeTo: this._activatedRoute });
		}
	}

	public createOrDeleteBankConnections() {
		if (this.companyProfile?.isBankingPlatformConnected) {
			this._store.dispatch(CodatActions.deleteConnection({ companyProfile: this.companyProfile! }));
		} else {
			const path = "home/settings";
			this._store.dispatch(CodatActions.createConnection({ companyProfile: this.companyProfile!, redirectPath: path }));
		}
	}

	private _resetQueryParams(): void {
		this._router.navigate([], {
			queryParams: {
				connectType: null,
				statuscode: null,
				connectionId: null,
				platform: null
			},
			queryParamsHandling: "merge"
		});
	}
}
