<div style="--bs-columns: 3.5" class="grid ls-mt-8">
  <div *ngIf="!isV102Enabled" class="g-col-12 g-col-md-4">
    <ng-container *ngTemplateOutlet="!isCalculating ? label : calcLabel; context: { $implicit: 'Average Invoice Amount' }"></ng-container>
    <p class="ls-mt-1 l-bold" *lsIsCalculating="isCalculating!" >{{ currencyCode}} {{ companyProfileRelationship?.onboardInstrumentRangeId! | lookup : onboardInstrumentRanges}}</p>
  </div>
  <div class="g-col-12 g-col-md-4">
    <ng-container *ngTemplateOutlet="!isCalculating ? label : calcLabel; context: { $implicit: 'Length of Relationship' }"></ng-container>
    <p class="ls-mt-1 l-bold" *lsIsCalculating="isCalculating!">{{companyProfileRelationship?.relationshipDurationId! | lookup : relationshipDurations}}</p>
  </div>
  <div class="g-col-12 g-col-md-4">
    <ng-container *ngTemplateOutlet="!isCalculating ? label : calcLabel; context: { $implicit: 'Payment Terms' }"></ng-container>
    <p class="ls-mt-1 l-bold" *lsIsCalculating="isCalculating!">Net {{companyProfileRelationship?.paymentTerms!}} days</p>
  </div>
  <div class="g-col-12 g-col-md-4">
    <span><label class="xs-bold">Currency</label></span>
    <p class="ls-mt-1 l-bold"><label class="label l-bold">{{ companyProfileRelationship?.currencyCode }}</label></p>
  </div>
  <div class="g-col-12 g-col-md-4">
    <ng-container *ngTemplateOutlet="!isCalculating ? label : calcLabel; context: { $implicit: 'Annual Sales' }"></ng-container>
    <p class="ls-mt-1 l-bold" *lsIsCalculating="isCalculating!">{{ currencyCode }} {{ companyProfileRelationship?.onboardRevenueRangeId! | lookup : onboardRevenueRanges }}</p>
  </div>
  <div class="g-col-12 g-col-md-4">
    <span><label class="xs-bold">Payment Method</label></span>
    <p class="ls-mt-1 l-bold"><label class="label l-bold">{{ companyProfileRelationship?.onboardPaymentMethodId! | lookup : onboardPaymentMethods }}</label></p>
  </div>
  <div *ngIf="!isV102Enabled" class="g-col-12 g-col-md-4">
    <ng-container *ngTemplateOutlet="!isCalculating ? label : calcLabel; context: { $implicit: 'Total AR Balance' }"></ng-container>
    <p class="ls-mt-1 l-bold" *lsIsCalculating="isCalculating!">{{ currencyCode }} {{ companyProfileRelationship?.arBalanceRangeId! | lookup :  onboardRevenueRanges }}</p>
  </div>
</div>

<ng-template #label let-label>
  <label class="xs-bold">{{label}}</label>
</ng-template>
<ng-template #calcLabel let-label>
  <label class="xs-bold">{{label}}
    <ls-tooltip>
      <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
      <div content>
        <p class="m ls-mb-0 text-wrap">We're currently calculating this field using your accounting and/or banking data you gave Raistone access to. Please come back later to see the result.</p>
      </div>
    </ls-tooltip>
  </label>
</ng-template>