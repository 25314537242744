import { Component, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { filter, map, takeUntil, withLatestFrom } from "rxjs";
import { Address, CompanyProfile, CompanyProfileRelationship, Contact } from "src/app/Models";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { FeatureFlagSelectors, FeatureFlags, OnboardApplicationStatus } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-customer-contact",
	templateUrl: "./customer-contact.component.html",
	styleUrls: ["./customer-contact.component.scss"]
})
export class CustomerContactComponent extends CustomerRelationshipBaseComponent {
	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public featureFlagSelectors: FeatureFlagSelectors
	) {
		super(router, activatedRoute, store, actions$, companyProfileSelectors);
		this.initData();
	}

	LEGAL_NAME = "legalName";
	BILLING = "billing";
	PRIMARY_CONTACT = "primaryContact";
	ACCOUNTS_PAYABLE_CONTACT = "accountsPayableContact";
	HAS_ACCOUNTS_PAYABLE_CONTACT = "hasAccountsPayableContact";

	public customerContactForm: FormGroup = new FormGroup({});
	public companyProfile?: CompanyProfile;
	public companyProfileRelationship?: CompanyProfileRelationship;
	public hideAccountsPayableContactForm = true;
	public isV102Enabled = false;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	initData() {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				withLatestFrom(this.store.select(this.featureFlagSelectors.selectFeatureFlags)),
				takeUntil(this.componentTeardown$),
				map(([cpr, ff]) => {
					this.companyProfileRelationship = cpr;
					const flag = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!;
					this.isV102Enabled = flag.enabled;

					this.hideAccountsPayableContactForm = !cpr?.accountsPayableContact;
					if (cpr?.relationshipCompanyName && this.breadcrumbs && this.breadcrumbs.length < 2)
						this.breadcrumbs.push({ routerPath: "", displayText: cpr?.relationshipCompanyName ?? "" });
					this.setFormGroup();
				})
			)
			.subscribe();
	}

	navigateToNextScreen() {
		if (this.companyProfileRelationship?.isManualSetup) {
			this.router.navigate(["../../relationship/" + this.companyProfileRelationship?.relationshipCompanyId], {
				relativeTo: this.activatedRoute
			});
		} else {
			this.router.navigate(["../../currency-payment/" + this.companyProfileRelationship?.relationshipCompanyId], {
				relativeTo: this.activatedRoute
			});
		}
	}

	setFormGroup() {
		const address = this.companyProfileRelationship?.billingAddress ?? null;

		if (!this.isV102Enabled) {
			this.customerContactForm = new FormGroup({
				legalName: new FormControl(this.companyProfileRelationship?.relationshipCompanyName, Validators.required),
				billing: new FormControl(address, Validators.required),
				hasAccountsPayableContact: new FormControl(!!this.companyProfileRelationship?.accountsPayableContact),
				primaryContact: new FormControl(this.companyProfileRelationship?.primaryContact, Validators.required),
				accountsPayableContact: new FormControl({
					value: this.companyProfileRelationship?.accountsPayableContact ?? null,
					disabled: this.hideAccountsPayableContactForm
				})
			});
			this.customerContactForm.get(this.HAS_ACCOUNTS_PAYABLE_CONTACT)?.valueChanges.subscribe((value) => {
				this.hideAccountsPayableContactForm = !value;
				if (value) {
					this.customerContactForm.get(this.ACCOUNTS_PAYABLE_CONTACT)?.enable();
				} else {
					this.customerContactForm.get(this.ACCOUNTS_PAYABLE_CONTACT)?.disable();
				}
			});
		} else {
			this.customerContactForm = new FormGroup({
				legalName: new FormControl(this.companyProfileRelationship?.relationshipCompanyName, Validators.required),
				billing: new FormControl(address, Validators.required)
			});
		}
	}

	continue() {
		if (this.customerContactForm.dirty) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			const addressForm: Address = this.customerContactForm.get(this.BILLING)?.value;
			const applicationStatusId =
				updatedCompanyProfileRelationship.applicationStatusId! in OnboardApplicationStatus
					? updatedCompanyProfileRelationship.applicationStatusId
					: OnboardApplicationStatus.INPROGRESS;
			updatedCompanyProfileRelationship.relationshipCompanyName = this.customerContactForm.get(this.LEGAL_NAME)?.value;
			updatedCompanyProfileRelationship.billingAddress = addressForm;
			updatedCompanyProfileRelationship.billingAddress.id = this.companyProfileRelationship?.billingAddress?.id;
			updatedCompanyProfileRelationship.applicationStatusId = applicationStatusId;
			if (!this.isV102Enabled) {
				const primaryContactForm: Contact = this.customerContactForm.get(this.PRIMARY_CONTACT)?.value as Contact;
				const accountsPayableContactForm: Contact = this.customerContactForm.get(this.ACCOUNTS_PAYABLE_CONTACT)?.value;
				updatedCompanyProfileRelationship.primaryContact = primaryContactForm;
				updatedCompanyProfileRelationship.primaryContact.id = this.companyProfileRelationship?.primaryContact?.id;
				if (!this.hideAccountsPayableContactForm && accountsPayableContactForm) {
					updatedCompanyProfileRelationship.accountsPayableContact = accountsPayableContactForm;
					updatedCompanyProfileRelationship.accountsPayableContact.id =
						this.companyProfileRelationship?.accountsPayableContact?.id;
				}
			}

			if (this.companyProfileRelationship?.relationshipCompanyId) {
				this.store.dispatch(
					CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
						companyProfileRelationship: updatedCompanyProfileRelationship
					})
				);
			} else {
				updatedCompanyProfileRelationship.isManualSetup = true;
				this.store.dispatch(
					CompanyProfileRelationshipActions.createCompanyProfileRelationship({
						companyProfileRelationship: updatedCompanyProfileRelationship
					})
				);
			}
		} else {
			this.navigateToNextScreen();
		}
	}
}
