import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, filter, map, takeUntil, combineLatest } from "rxjs";
import { Store } from "@ngrx/store";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "../../../../AppStateManagement";
import { ActivatedRoute, Router } from "@angular/router";
import { CompanyProfile, OnboardCompanyProgress } from "../../../../Models";
import { ContinueApplicationService } from "../../../../../services";
import { IColumnHeader } from "src/app/Models/Interfaces/IColumnHeader";
import { Application } from "src/app/Models/Application";
import { GridService } from "src/services";
import { DatePipe } from "@angular/common";
import { GridSort, SortDirection, OnboardApplicationStatus, PageRequest } from "@limestone/ls-shared-modules";
@Component({
	selector: "ls-applications",
	templateUrl: "./applications.component.html",
	styleUrls: ["./applications.component.scss"]
})
export class ApplicationsComponent implements OnInit, OnDestroy {
	public static INITIAL_SORT: GridSort = { active: "lastActivity", direction: SortDirection.DESC };
	protected componentTeardown$ = new Subject();
	constructor(
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private continueApplicationService: ContinueApplicationService,
		public gridService: GridService<Application>,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		private datePipe: DatePipe,
		private companyProfileSelectors: CompanyProfileSelectors
	) {
		gridService.activeSort = { active: Application.DefaultSort, direction: SortDirection.DESC };
	}

	public companyProfile?: CompanyProfile;
	public applications: Application[] = [];
	public columns: IColumnHeader[] = Application.ColumnNames;

	public get OnboardApplicationStatus(): typeof OnboardApplicationStatus {
		return OnboardApplicationStatus;
	}

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileRelationshipSelectors.selectApplications)
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				filter(([cp, apps]) => !!cp && !!apps),
				map(([cp, apps]) => {
					this.companyProfile = cp;
					this.gridService.activePage = apps;
					this.applications = apps!.content!;
				})
			)
			.subscribe();

		this.gridService.sortFilterChange$
			.pipe(
				takeUntil(this.componentTeardown$),
				map((pr: PageRequest) => {
					this.store.dispatch(
						CompanyProfileRelationshipActions.getApplications({
							companyProfileId: this.companyProfile!.companyId!,
							pr
						})
					);
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	moreFinancing() {
		this.router.navigate(["../../onboarding/customer-setup/manual"], { relativeTo: this.activatedRoute });
	}

	onRowClicked(app: Application) {
		this.router.navigate([`../applications/${app.companyId}`], { relativeTo: this.activatedRoute });
	}

	continueApplication() {
		this.continueApplicationService.continueApplication("../onboarding/");
	}

	getLastActivity(date?: Date): string {
		if (date) {
			return this.calculateDiff(date);
		} else {
			return "";
		}
	}

	calculateDiff(date: Date, date2: Date = new Date()): string {
		let dateStr = this.datePipe.transform(date, "MMM dd, YYYY")!;
		if (
			date2.getUTCFullYear() === date.getUTCFullYear() &&
			(date2.getUTCMonth() === date.getUTCMonth() || date2.getUTCMonth() - date.getUTCMonth() === 1)
		) {
			const diff = date2.getUTCDate() - date.getUTCDate();
			if (date2.getUTCMonth() - date.getUTCMonth() === 1) {
				date2.setUTCDate(date2.getUTCDate() - 1);

				if (date2.getUTCDate() === date.getUTCDate()) {
					dateStr = "Yesterday";
				}
			} else if (diff < 1) {
				dateStr = "Today";
			} else if (diff >= 1 && diff < 2) {
				dateStr = "Yesterday";
			}
		}
		return dateStr;
	}
}
