<div class="d-none d-xxl-block">
	<table class="table align-middle ls-mb-0 bg-white">
		<thead>
		<tr>
			<th *ngFor="let column of columns" class="header-cell">
				<div class="d-flex flex-row justify-content-between align-items-center" *ngIf="column.canSort">
					{{column.column}}
					<span type="button" class="material-icons" (click)="sortColumn(column)">
						{{getIcon(column)}}
					</span>
				</div>
			</th>
		</tr>
		</thead>
		<tbody>
			<tr *ngFor="let file of files; let i = index">
				<td>
					<div class="ls-ml-2">
						<p class="ls-mb-1 m"><strong>{{file.fileName}}</strong></p>
					</div>
				</td>
				<td>
					<div class="ls-ml-2">
						<p class="ls-mb-1 m">{{file.fileSize}}</p>
					</div>
				</td>
				<td>
					<div class="ls-ml-2">
						<p class="ls-mb-1 m">{{file.uploadDate |  date: 'mediumDate'}}</p>
					</div>
				</td>
				<td>
					<ng-container *ngTemplateOutlet="uploadBadge; context: { $implicit: file }"></ng-container>
				</td>
				<td>
					<ng-container *ngTemplateOutlet="uploadComplete; context: { $implicit: file }"></ng-container>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<!-- Below XXL screens -->
<div class="d-xxl-none">
	<div class="inverse-background d-row ls-p-1_75 rounded-7 justify-content-between ls-mb-1" *ngFor="let file of files">
		<div class="d-flex flex-row justify-content-between flex-lg-row ls-mb-3">
			<label class="l ls-mb-3">{{file.fileName}}</label>
			<div class="d-lg-none">
				<ng-container *ngTemplateOutlet="uploadComplete; context: { $implicit: file }"></ng-container>
			</div>
			<div class="d-none d-lg-block d-xxl-none">
				<ng-container *ngTemplateOutlet="uploadBadge; context: { $implicit: file }"></ng-container>
			</div>
		</div>
		<div class="d-none d-lg-block d-xxl-none d-flex justify-content-between">
			<ng-container *ngTemplateOutlet="fileInfo; context: { $implicit: file }"></ng-container>
		</div>
		<!-- Medium and smaller screens -->
		<div class="d-lg-none d-flex flex-column">
			<ng-container *ngTemplateOutlet="fileInfo; context: { $implicit: file }"></ng-container>
			<ng-container *ngTemplateOutlet="uploadBadge; context: { $implicit: file }"></ng-container>
		</div>
	</div>
</div>

<ng-template #uploadComplete let-file>
	<div class="d-flex flex-row file-upload-icon-container" *ngIf="file!.status === UploadStatus.COMPLETE">
		<span class="material-icons-outlined hoverable ls-mr-1_75" (click)="openFile(file!)">
			file_download
		</span>
		<span class="material-icons-outlined hoverable" (click)="deleteFile(file!)"
			  [class.disabled]="submitted">
			delete
		</span>
	</div>
</ng-template>

<ng-template #uploadBadge let-file>
	<div class="ls-icon-badge upload-badge"
			 [class.ls-badge-primary]="file?.status === UploadStatus.COMPLETE"
			 [class.ls-badge-neutral-variant]="file?.status === UploadStatus.IN_PROGRESS"
			 [class.ls-badge-error-dark]="file?.status === UploadStatus.FAILED">
		<span class="material-icons">{{getBadgeIcon(file?.status!)}}</span>
		<label class="xs-badge">{{file?.status}}</label>
	</div>
</ng-template>

<ng-template #fileInfo let-file>
	<div class="d-flex flex-row justify-content-between align-items-center">
		<div class="ls-mb-3 ls-mb-lg-0">
			<label class="ls-mb-1 s">File Size</label>
			<p class="ls-mb-0 l-bold">{{file.fileSize}}</p>
		</div>
		<div class="ls-mb-3 ls-mb-lg-0">
			<label class="ls-mb-1 s">Date Uploaded</label>
			<p class="ls-mb-0 l-bold">{{file.uploadDate | date: 'MMM dd, YYYY'}}</p>
		</div>
		<div class="d-none d-lg-block d-xxl-none">
			<ng-container *ngTemplateOutlet="uploadComplete; context: { $implicit: file }"></ng-container>
		</div>
	</div>
</ng-template>