import { Address } from "./";
import { AddressType, GenericLookup } from "@limestone/ls-shared-modules";

export class CompanyAddress {
	addressType?: GenericLookup<string>;
	isActive?: boolean;
	address?: Address;

	constructor(addressType?: GenericLookup<string>, isActive?: boolean, address?: Address) {
		this.addressType = addressType;
		this.isActive = isActive;
		this.address = address;
	}
}
