import { Component, OnDestroy } from "@angular/core";
import {
	AbstractControl,
	ControlValueAccessor,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { map, Subject, takeUntil } from "rxjs";

@Component({
	selector: "ls-business-names",
	templateUrl: "./business-names-form-control.component.html",
	styleUrls: ["./business-names-form-control.component.scss"],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: BusinessNamesFormControlComponent, multi: true },
		{ provide: NG_VALIDATORS, useExisting: BusinessNamesFormControlComponent, multi: true }
	]
})
export class BusinessNamesFormControlComponent implements OnDestroy, ControlValueAccessor, Validator {
	protected componentTeardown$ = new Subject();
	formGroup: FormGroup = new FormGroup({
		dbaNames: new FormArray([this.fb.control("")])
	});
	get dbaNameControls(): FormArray {
		return this.formGroup.get("dbaNames") as FormArray;
	}
	numberOfDBANames = 1;
	maxNumberOfDBANames = 4;
	nameControlArray: string[] = [];

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public fb: FormBuilder
	) {}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	writeValue(dbaNameFormData: string[]): void {
		if (dbaNameFormData?.length > 0) this.dbaNameControls.clear();
		dbaNameFormData?.forEach((dbaName, i) => {
			this.dbaNameControls.push(this.fb.control(dbaName));
		});
		this.numberOfDBANames = Math.max(dbaNameFormData?.length ?? 1, this.numberOfDBANames);
	}

	registerOnChange(onChange: (value: string[] | null) => void): void {
		this.formGroup.valueChanges.pipe(takeUntil(this.componentTeardown$), map(onChange)).subscribe();
	}

	control(c: AbstractControl): FormControl {
		return c as FormControl;
	}

	onTouched() {}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		isDisabled ? this.formGroup.disable() : this.formGroup.enable();
	}

	validate(): ValidationErrors | null {
		if (this.formGroup.valid) {
			return null;
		}
		let errors: any = {};
		for (let i = 0; i < this.maxNumberOfDBANames; i++) {
			errors = this.addControlErrors(errors, i);
		}
		return errors;
	}

	addControlErrors(allErrors: any, controlIndex: number) {
		const errors = { ...allErrors };
		if (this.formGroup.controls[controlIndex]) {
			const controlErrors = this.formGroup.controls[controlIndex].errors;
			if (controlErrors) {
				errors[controlIndex] = controlErrors;
			}
			return errors;
		}
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.validatorChange = fn;
	}

	validatorChange() {}

	controlHasError(controlIndex: number): boolean {
		if (this.formGroup.controls[controlIndex]) {
			return (
				this.formGroup.controls[controlIndex]!.invalid &&
				(this.formGroup.controls[controlIndex]!.dirty || this.formGroup.controls[controlIndex]!.touched)
			);
		}
		return false;
	}

	getControlError(controlIndex: number): string {
		return this.formGroup.controls[controlIndex]?.errors!["error"];
	}

	addDBAName() {
		if (this.numberOfDBANames < this.maxNumberOfDBANames) {
			this.numberOfDBANames++;
			this.dbaNameControls.push(this.fb.control(""));
		}
	}
}
