import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: "ls-successful-application",
	templateUrl: "./successful-application.component.html",
	styleUrls: ["./successful-application.component.scss"]
})
export class SuccessfulApplicationComponent {
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute
	) {}

	onClick() {
		this.router.navigate(["../../home"], { relativeTo: this.activatedRoute });
	}
}
