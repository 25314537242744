<div *ngIf="!readOnly; else readOnlyView" class="contact-form-container ls-mt-8">
    <form [formGroup]="customerContactForm">
        <div class="d-flex flex-column">
            <mdb-form-control class="ls-mb-8">
                <input [formControlName]="LEGAL_NAME" mdbInput mdbValidate type="text" id="legalName"
                    class="form-control form-control-lg"/>
                <label mdbLabel class="form-label" for="legalName">Legal Business Name</label>
            </mdb-form-control>

            <div class="d-flex flex-column ls-mb-8">
                <label class="s-caps ls-mb-3">Billing Address</label>
                <ls-address-form-control [formControlName]="BILLING"></ls-address-form-control>
            </div>
            
            <ng-container *ngIf="!isV1_0_2_FlagEnabled">
                <div class="d-flex flex-column ls-mb-6">
                    <label class="s-caps ls-mb-3">Primary Contact</label>
                    <ls-contact-form-control [formControlName]="PRIMARY_CONTACT"></ls-contact-form-control>
                </div>
    
                <div class="d-flex flex-row" [class.ls-mb-8]="!hideAccountsPayableContactForm">
                    <input
                        [formControlName]="HAS_ACCOUNTS_PAYABLE_CONTACT"
                        mdbCheckbox
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="differentAccountsPayableContact"
                    />
                    <label class="form-check-label xl" for="differentAccountsPayableContact">Different accounts payable contact</label>
                </div>
    
                <div [hidden]="hideAccountsPayableContactForm" [ngClass]="{'d-flex flex-column': !hideAccountsPayableContactForm }">
                    <label class="s-caps ls-mb-3">Accounts Payable Contact</label>
                    <ls-contact-form-control [formControlName]="ACCOUNTS_PAYABLE_CONTACT"></ls-contact-form-control>
                </div>
            </ng-container>
        </div>
    </form>
</div>
  
<ng-template #readOnlyView>
    <div class="grid ls-mt-8" style="--bs-gap:3.5rem;">
        <div class="g-col-12 g-col-md-6">
            <label class="s">Legal Business Name</label>
            <p class="l-bold">{{ companyProfileRelationship?.relationshipCompanyName }}</p>
        </div>
        <div class="g-col-12 g-col-md-6">
            <label class="s">Billing Address</label>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.address1 }}</p>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.address2 }}</p>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.city }}</p>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.state?.name }}</p>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.postalCode }}</p>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.country?.name }}</p>
        </div>
        <ng-container *ngIf="!isV1_0_2_FlagEnabled">
            <div class="g-col-12 g-col-md-6">
                <label class="s">Customer Contact</label>
                <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.primaryContact?.firstName }} {{ companyProfileRelationship?.primaryContact?.lastName }}</p>
                <p class="label l-bold ls-mb-0">{{ companyProfileRelationship?.primaryContact?.phone?.formattedPhoneNumber() }}</p>
                <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.primaryContact?.email }}</p>
            </div>
            <div class="g-col-12 g-col-md-6">
                <label class="s">Accounts Payable Contact</label>
                <p class="label l-bold ls-mb-0">{{ companyProfileRelationship?.accountsPayableContact?.firstName }} {{ companyProfileRelationship?.accountsPayableContact?.lastName }}</p>
                <p class="label l-bold ls-mb-0">{{ companyProfileRelationship?.accountsPayableContact?.phone?.formattedPhoneNumber() }}</p>
                <p class="label l-bold ls-mb-0">{{ companyProfileRelationship?.accountsPayableContact?.email }}</p>
            </div>
        </ng-container>
    </div>
</ng-template>