import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map, Subject } from "rxjs";
import { ConnectFinancialsPersuasionComponent } from "../../../Modals";
import { take } from "rxjs/operators";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { ModalActions } from "../../../../../Core";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-connect-financials-intro",
	templateUrl: "./connect-financials-intro.component.html",
	styleUrls: ["./connect-financials-intro.component.scss"]
})
export class ConnectFinancialsIntroComponent {
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private dialog: MdbModalService
	) {}
	onClick() {
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute });
	}

	openModal() {
		this.dialog
			.open(ConnectFinancialsPersuasionComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true
			})
			.onClose.pipe(
				take(1),
				map((action: ModalActions) => {
					if (action === ModalActions.PRIMARY) {
						this.router.navigate(["../accounting"], { relativeTo: this.activatedRoute });
					} else {
						this.router.navigate(["../banking-intro"], { relativeTo: this.activatedRoute });
					}
				})
			)
			.subscribe();
	}
}
