import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable, takeUntil } from "rxjs";
import { CompanyAddress, CompanyProfile, Phone } from "src/app/Models";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import {
	AddressType,
	CountryCode,
	FeatureFlagSelectors,
	FeatureFlags,
	GenericLookup
} from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";

@Component({
	selector: "ls-company-phone-address",
	templateUrl: "./company-phone-address.component.html",
	styleUrls: ["./company-phone-address.component.scss"]
})
export class CompanyPhoneAddressComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private companyProfileSelectors: CompanyProfileSelectors,
		private _featureFlagSelectors: FeatureFlagSelectors
	) {
		super(store);
	}

	public PHONE = "PHONE";
	public PRIMARY = "PRIMARY";
	public BILLING = "BILLING";

	public companyProfile?: CompanyProfile;
	public primaryOfficeAddress?: CompanyAddress;
	public billingAddress?: CompanyAddress;

	public toggled = false;
	public isUSorCABased?: boolean;
	public isV1_0_2_FlagEnabled = false;

	setFormControls() {
		const phone = new Phone(
			this.companyProfile?.phoneCountryCode,
			this.companyProfile?.phoneNumber,
			this.companyProfile?.phoneExt
		);
		this.form.addControl(this.PHONE, new FormControl(phone, Validators.required));
		this.form.addControl(this.PRIMARY, new FormControl(this.primaryOfficeAddress?.address, Validators.required));
		this.form.addControl(
			this.BILLING,
			new FormControl({ value: this.billingAddress?.address, disabled: !this.toggled })
		);
	}

	toggleBillingForm() {
		this.toggled = !this.toggled;
		this.toggled ? this.form.get(this.BILLING)?.enable() : this.form.get(this.BILLING)?.disable();
		if (this.toggled) {
			this.form.get(this.BILLING)?.setValue(this.billingAddress?.address);
		}
		this.form.get(this.BILLING)?.updateValueAndValidity();
		if (this.billingAddress) {
			this.billingAddress!.isActive = this.toggled;
		}
	}

	onClick() {
		if (this.form.dirty) {
			const updatedCompanyProfile = copy(this.companyProfile!);
			updatedCompanyProfile.addresses = [];

			const phone: Phone = this.form.get(this.PHONE)?.value;
			updatedCompanyProfile.phoneCountryCode = phone.countryCode;
			updatedCompanyProfile.phoneNumber = phone.number;
			updatedCompanyProfile.phoneExt = phone.extension;

			const pa = this.form.get(this.PRIMARY)?.value;
			if (pa) {
				pa.id = this.primaryOfficeAddress?.address?.id;
				const PrimaryAddress = new CompanyAddress(
					new GenericLookup<string>(AddressType.PRIMARY, "Primary Office / Headquarters", true),
					true,
					pa
				);
				updatedCompanyProfile.addresses?.push(PrimaryAddress);
			}

			const ba = this.form.get(this.BILLING)?.value;
			if (ba && this.toggled) {
				ba.id = this.billingAddress?.address?.id;
				const billingAddress = new CompanyAddress(
					new GenericLookup<string>(AddressType.BILLING, "Billing", true),
					this.toggled,
					ba
				);
				updatedCompanyProfile.addresses?.push(billingAddress);
			} else if (this.billingAddress?.address) {
				const billingAddress = new CompanyAddress(
					new GenericLookup<string>(AddressType.BILLING, "Billing", true),
					this.toggled,
					this.billingAddress?.address
				);
				updatedCompanyProfile.addresses?.push(billingAddress);
			}
			this.isUSorCABased = this.isUsOrCABased(updatedCompanyProfile);

			this.emitSave(updatedCompanyProfile);
		}
		if (this.isUSorCABased) {
			this.router.navigate(["../state-of-formation"], { relativeTo: this.activatedRoute });
		} else {
			this.router.navigate(["../company-industry"], { relativeTo: this.activatedRoute });
		}
	}

	isInvalid() {
		return this.form.invalid;
	}

	init(): Observable<CompanyProfile | undefined> {
		return combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this._featureFlagSelectors.selectFeatureFlags)
		]).pipe(
			filter(([cp, ff]) => !!cp && !!ff),
			takeUntil(this.componentTeardown$),
			map(([cp, ff]) => {
				this.companyProfile = cp;
				this.primaryOfficeAddress = cp?.addresses?.find((a) => a.addressType?.id === AddressType.PRIMARY);
				this.billingAddress = cp?.addresses?.find((a) => a.addressType?.id === AddressType.BILLING);
				if (this.billingAddress?.isActive) {
					this.toggled = true;
				}
				this.isV1_0_2_FlagEnabled = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!.enabled;
				this.isUSorCABased = this.isUsOrCABased(cp!);
				this.setFormControls();
				return cp;
			})
		);
	}

	isUsOrCABased(cp: CompanyProfile): boolean {
		return !this.isV1_0_2_FlagEnabled
			? cp?.isAddressOfCountry(CountryCode.US)
			: cp?.isAddressOfCountry(CountryCode.US) || cp?.isAddressOfCountry(CountryCode.CA);
	}
}
