import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Subject, takeUntil } from "rxjs";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { Address, CompanyProfileRelationship, Contact } from "src/app/Models";
import { FormGroup } from "@angular/forms";
import { ApplicationContactInfoComponent } from "./application-contact-info/application-contact-info.component";
import { MdbNotificationService } from "mdb-angular-ui-kit/notification";
import { CustomerSettingsComponent, CustomerRelationshipComponent, PlatformsUsedComponent } from "../../../Components";
import {
	CurrencyCode,
	FeatureFlagSelectors,
	FeatureFlags,
	GenericLookup,
	OnboardApplicationStatus
} from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-application-details",
	templateUrl: "./application-details.component.html",
	styleUrls: ["./application-details.component.scss"]
})
export class ApplicationDetailsComponent implements OnInit, OnDestroy {
	private componentTeardown$: Subject<any> = new Subject<any>();
	public companyProfileRelationship?: CompanyProfileRelationship;
	public formGroup: FormGroup = new FormGroup({});
	public isSUBMITTED = false;
	public readOnly = true;
	public tabIndex = 0;
	public tabNames: string[] = ["Contact Info", "Customer Relationship", "Platforms Used", "Customer Settings"];
	public isV1_0_2_FlagEnabled = false;

	@ViewChild("successToast", { static: true }) successToast?: TemplateRef<any>;
	constructor(
		private store: Store,
		public actions$: Actions,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		private notificationService: MdbNotificationService,
		private _featureFlagSelectors: FeatureFlagSelectors
	) {}
	public get OnboardApplicationStatus(): typeof OnboardApplicationStatus {
		return OnboardApplicationStatus;
	}

	public ngOnInit(): void {
		combineLatest([
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
			this.store.select(this._featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				filter(([cpr, ff]) => !!cpr && !!ff),
				takeUntil(this.componentTeardown$),
				map(([cpr, ff]) => {
					this.isV1_0_2_FlagEnabled = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!.enabled;
					this.companyProfileRelationship = cpr;
					this.isSUBMITTED = cpr!.applicationStatusId === OnboardApplicationStatus.SUBMITTED;
					this.readOnly = cpr!.applicationStatusId !== OnboardApplicationStatus.SUBMITTED;
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				ofType(CompanyProfileRelationshipActions.companyProfileRelationshipSaveSuccessful),
				takeUntil(this.componentTeardown$),
				map(() => {
					this.notificationService.open(this.successToast!, { autohide: true, position: "top-center" });
					this.formGroup.markAsPristine();
				})
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public onClick() {
		const cpr = this.updateCompanyProfileRelationship();
		this.store.dispatch(
			CompanyProfileRelationshipActions.updateCompanyProfileRelationship({ companyProfileRelationship: cpr })
		);
	}

	private updateCompanyProfileRelationship(): CompanyProfileRelationship {
		const formValue: ICustomerContactFormValue = this.formGroup.get(ApplicationContactInfoComponent.FORM_CONTROL_NAME)
			?.value;
		const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
		updatedCompanyProfileRelationship.relationshipCompanyName = formValue.legalName;
		updatedCompanyProfileRelationship.billingAddress = formValue.billing;
		updatedCompanyProfileRelationship.billingAddress!.id = this.companyProfileRelationship?.billingAddress?.id;
		if (!this.isV1_0_2_FlagEnabled) {
			updatedCompanyProfileRelationship.primaryContact = formValue.primaryContact;
			updatedCompanyProfileRelationship.primaryContact!.id = this.companyProfileRelationship?.primaryContact?.id;

			if (formValue.hasAccountsPayableContact) {
				updatedCompanyProfileRelationship.accountsPayableContact = formValue.accountsPayableContact;
				updatedCompanyProfileRelationship.accountsPayableContact.id =
					this.companyProfileRelationship?.accountsPayableContact?.id;
			} else {
				updatedCompanyProfileRelationship.accountsPayableContact = undefined;
			}
		}
		const custSettingsFormValue: ICustomerSettingsFormValue = this.formGroup.get(
			CustomerSettingsComponent.FORM_CONTROL_NAME
		)?.value;
		updatedCompanyProfileRelationship.financeDisclosureId = custSettingsFormValue.financeDisclosure;
		updatedCompanyProfileRelationship.preferredPaymentMethodId = custSettingsFormValue.paymentMethod;
		updatedCompanyProfileRelationship.isInstrumentApprovalConfirmed = custSettingsFormValue.instrumentApprovalConfirmed;
		updatedCompanyProfileRelationship.isAutoXcel = custSettingsFormValue.isAutoXcel;
		updatedCompanyProfileRelationship.isFinanceExpedited = custSettingsFormValue.isFinancingExpedited;
		if (!this.isV1_0_2_FlagEnabled) {
			updatedCompanyProfileRelationship.requestedCreditLimit = custSettingsFormValue.requestedCreditLimit;
		}
		const customerRelationshipFormValue: ICustomerRelationshipFormValue = this.formGroup.get(
			CustomerRelationshipComponent.FORM_CONTROL_NAME
		)?.value;
		updatedCompanyProfileRelationship.onboardInstrumentRangeId = customerRelationshipFormValue.onboardInstrumentRangeId;
		updatedCompanyProfileRelationship.relationshipDurationId = customerRelationshipFormValue.relationshipDurationId;
		updatedCompanyProfileRelationship.paymentTerms = customerRelationshipFormValue.paymentTerms;
		updatedCompanyProfileRelationship.currencyCode = customerRelationshipFormValue.currencyCode;
		updatedCompanyProfileRelationship.onboardRevenueRangeId = customerRelationshipFormValue.onboardRevenueRangeId;
		updatedCompanyProfileRelationship.onboardPaymentMethodId = customerRelationshipFormValue.onboardPaymentMethodId;
		updatedCompanyProfileRelationship.arBalanceRangeId = customerRelationshipFormValue.arBalanceRangeId;

		const platformsUsedFormValue: IPlatformsUsedFormValue = this.formGroup.get(PlatformsUsedComponent.FORM_CONTROL_NAME)
			?.value;
		updatedCompanyProfileRelationship.instrumentPlatform = platformsUsedFormValue.instrumentPlatform;
		updatedCompanyProfileRelationship.instrumentApprovalPlatform = platformsUsedFormValue.instrumentApprovalPlatform;
		updatedCompanyProfileRelationship.remittancePlatform = platformsUsedFormValue.remittancePlatform;

		return updatedCompanyProfileRelationship;
	}
}

export interface ICustomerContactFormValue {
	billing: Address;
	primaryContact: Contact;
	legalName: string;
	hasAccountsPayableContact: boolean;
	accountsPayableContact: Contact;
}

export interface ICustomerSettingsFormValue {
	financeDisclosure: string;
	paymentMethod: string;
	instrumentApprovalConfirmed: boolean;
	isAutoXcel: boolean;
	isFinancingExpedited: boolean;
	requestedCreditLimit: number;
}

export interface ICustomerRelationshipFormValue {
	onboardInstrumentRangeId: string;
	relationshipDurationId: string;
	paymentTerms: number;
	currencyCode: CurrencyCode;
	onboardRevenueRangeId: string;
	onboardPaymentMethodId: string;
	arBalanceRangeId: string;
}

export interface IPlatformsUsedFormValue {
	instrumentPlatform: GenericLookup<string>;
	instrumentApprovalPlatform: GenericLookup<string>;
	remittancePlatform: GenericLookup<string>;
}
