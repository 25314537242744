import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, Subject, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { StepperActions, StepperSelectors } from "../../OnboardingStateManagement";
import { MsalService } from "@azure/msal-angular";
import { EndSessionRequest } from "@azure/msal-browser";
import { IRouteStepData } from "src/app/Models/Interfaces";
import { DOCUMENT } from "@angular/common";
import copy from "fast-copy";
import { ActiveState } from "../../OnboardingStateManagement/Stepper/stepper-reducer";

@Component({
	selector: "ls-onboarding-stepper",
	templateUrl: "./onboarding-stepper.component.html",
	styleUrls: ["./onboarding-stepper.component.scss"]
})
export class OnboardingStepperComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	public expanded?: boolean;
	public displayState?: ActiveState;
	private parentState?: ActiveState;
	private childState?: ActiveState;
	public hasStepperContextChanged = false;
	public showActiveStep = true;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store,
		private stepperSelectors: StepperSelectors,
		private authService: MsalService,
		@Inject(DOCUMENT) private document: Document
	) {
		this.router.events
			.pipe(
				takeUntil(this.componentTeardown$),
				filter((event) => event instanceof NavigationEnd),
				map((event: any) => {
					const routeStepData = this.activatedRoute.firstChild?.snapshot.data as IRouteStepData;

					if (routeStepData.backgroundStyle && routeStepData.backgroundStyle !== "") {
						this.document.body.classList.add(routeStepData.backgroundStyle!);
					} else {
						this.document.body.className = "";
					}
					this.store.dispatch(StepperActions.setNextStep({ routeStepData }));
				})
			)
			.subscribe();
	}

	ngOnInit() {
		this.store
			.select(this.stepperSelectors.selectActiveState)
			.pipe(
				filter((actState) => !!actState),
				takeUntil(this.componentTeardown$),
				map((state) => {
					this.displayState = state;
					const routeStepData = this.activatedRoute.firstChild?.snapshot.data as IRouteStepData;
					this.showActiveStep = routeStepData?.step === this.displayState?.activeStep || state!.childStepsActive;

					if (state!.childStepsActive) {
						this.parentState = state!.parent;
						this.childState = state;
					}
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.document.body.className = "";
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	handleStepChange(index: number) {
		if (this.hasStepperContextChanged && index === this.displayState?.activeStep) {
			this.displayState = this.childState;
			this.hasStepperContextChanged = false;
		} else {
			if (this.displayState?.activeCompletedSteps.has(index)) {
				const step = copy(this.displayState?.activeSteps[index]);
				if (step.route) {
					const routeString = `../onboarding/${step.route}`;
					this.router.navigate([routeString], { relativeTo: this.activatedRoute.parent });
				}
			}
		}
	}

	handleNavClick() {
		this.hasStepperContextChanged = true;
		this.displayState = this.parentState;
	}

	handleSettingsClick() {
		this.router.navigate(["../../home"], { relativeTo: this.activatedRoute });
	}

	logout() {
		const request: EndSessionRequest = {
			account: this.authService.instance.getActiveAccount(),
			postLogoutRedirectUri: window.origin + "/logout"
		};
		this.authService.logout(request);
	}
}
