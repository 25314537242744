import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, filter, map, Subject, takeUntil } from "rxjs";
import { CompanyProfile, UserInfo } from "../../../../Models";
import { ContinueApplicationService } from "../../../../../services";
import { Store } from "@ngrx/store";
import { AppSelectors, CompanyProfileSelectors } from "../../../../AppStateManagement";

@Component({
	selector: "ls-resume-application",
	templateUrl: "./resume-application.component.html",
	styleUrls: ["./resume-application.component.scss"]
})
export class ResumeApplicationComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private continueApplicationService: ContinueApplicationService,
		private store: Store,
		private companyProfileSelectors: CompanyProfileSelectors,
		private appSelectors: AppSelectors
	) {}

	public companyProfile?: CompanyProfile;
	public userInfo?: UserInfo;

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.appSelectors.selectUserInfo)
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				filter(([cp, info]) => !!cp && !!info),
				map(([companyProfile, info]) => {
					this.companyProfile = companyProfile;
					this.userInfo = info;
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	returnToBeginning() {
		if (!this.companyProfile!.isAccountingPlatformConnected) {
			this.router.navigate(["../connect-financials/introduction"], { relativeTo: this.activatedRoute });
		} else if (!this.companyProfile!.isBankingPlatformConnected) {
			this.router.navigate(["../connect-financials/banking-intro"], { relativeTo: this.activatedRoute });
		} else {
			this.router.navigate(["../company-details/introduction"], { relativeTo: this.activatedRoute });
		}
	}

	continueApplication() {
		this.continueApplicationService.continueApplication("../onboarding/");
	}
}
