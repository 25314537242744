import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormControlStatus, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { debounce, filter, interval, map, Subject, takeUntil, tap } from "rxjs";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { Platform as PlatformType } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-platform-choices",
	templateUrl: "./platform-choices.component.html",
	styleUrls: ["./platform-choices.component.scss"]
})
export class PlatformChoicesComponent implements OnInit {
	protected componentTeardown$ = new Subject();

	PLATFORM = "PLATFORM";
	OTHER_PLATFORM_INPUT = "OTHER_PLATFORM_INPUT";
	formGroup: FormGroup = new FormGroup({});
	@Input() platform?: GenericLookup<string>;
	public platforms?: GenericLookup<string>[];
	public maxLength = 80;
	characterCount = 0;
	hideOtherPlatformInput = false;

	@Output() formValue: EventEmitter<GenericLookup<string>> = new EventEmitter<GenericLookup<string>>();
	@Output() formStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		private store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public genericSelectors: GenericSelectors
	) {}

	ngOnInit(): void {
		this.store
			.select(this.genericSelectors.selectLookup(LookupTables.Platform))
			.pipe(
				filter((p) => !!p && p.length > 0),
				takeUntil(this.componentTeardown$),
				map((p) => {
					this.platforms = p;
				})
			)
			.subscribe();

		this.setFormControls();
	}

	setFormControls() {
		this.hideOtherPlatformInput = this.platform?.id !== PlatformType.Other;
		this.formGroup = new FormGroup({
			PLATFORM: new FormControl(this.platform?.id, Validators.required)
		});
		this.formGroup.addControl(
			this.OTHER_PLATFORM_INPUT,
			new FormControl(
				{
					value: this.platform?.id === PlatformType.Other ? this.platform?.name : "",
					disabled: this.platform?.id !== PlatformType.Other
				},
				[Validators.required, Validators.minLength(1), Validators.maxLength(this.maxLength)]
			)
		);
		if (this.platform?.id === PlatformType.Other) {
			this.characterCount = this.platform?.name?.length ?? 0;
		}

		this.formGroup
			.get(this.OTHER_PLATFORM_INPUT)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				tap((value) => (this.characterCount = value?.length ?? 0)),
				debounce(() => interval(500)),
				map((value) => {
					if (this.formGroup.get(this.PLATFORM)?.value === PlatformType.Other) {
						this.formValue.emit(new GenericLookup<string>(PlatformType.Other, value, true));
					}
				})
			)
			.subscribe();

		this.formGroup
			.get(this.PLATFORM)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((value: string) => {
					if (value === PlatformType.Other) {
						this.hideOtherPlatformInput = false;
						this.formGroup.get(this.OTHER_PLATFORM_INPUT)?.enable();
					} else {
						this.hideOtherPlatformInput = true;
						this.formGroup.get(this.OTHER_PLATFORM_INPUT)?.disable();
						const newVal = this.platforms?.find((p) => p.id === value);
						this.formValue.emit(newVal);
					}
				})
			)
			.subscribe();

		this.formGroup.statusChanges
			.pipe(
				takeUntil(this.componentTeardown$),
				map((status: FormControlStatus) => this.formStatus.emit(status === "VALID"))
			)
			.subscribe();
	}
}
