<div class="ls-modal-container">
		<h2 class="no-text-transform ls-mb-3">Skip connecting bank account?</h2>
		<p class="l">By connecting your banks, we’ll be able to approve your company 3 times faster to get paid sooner.</p>
		<ls-accordion header="Why is this process lengthier?">
				<ul class="no-indent-list ls-mb-0">
						<li>
								<p class="l ls-mb-2">Manual information must be checked by our teams. In comparison, our software analyzes your data, fetches your info, and uploads it to your application.</p>
						</li>
						<li>
								<p class="l ls-mb-0">We like to be thorough with our clients and the manual process has more gaps of information. With back and forth communication involved, this usually results in the process taking 3 to 5 times longer.</p>
						</li>
				</ul>
		</ls-accordion>
		<div class="modal-action-btns">
				<button type="button" class="btn btn-outline-tertiary btn-lg" (click)="close(ModalActions.SECONDARY)">Skip</button>
				<button type="button" class="btn btn-primary btn-lg" (click)="close(ModalActions.PRIMARY)">Connect bank accounts</button>
		</div>
</div>