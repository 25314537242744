import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "validNumber" })
export class ValidNumberPipe implements PipeTransform {
	transform(value: number | undefined): boolean {
		if (Number.isNaN(value)) {
			return false;
		}

		return value! >= 0;
	}
}
