<ng-container *ngIf="(success$ | async); then connectionSuccess; else connectionFailed"></ng-container>
<ng-template #connectionSuccess>
  <h1 class="l ls-mb-4 ls-mb-xxl-8">Congrats, you’re all set!</h1>
  <p class="title m ls-mb-4 ls-mb-xxl-2">You’ve successfully connected your business bank account.</p>
  <p class="l ls-mb-6 ls-mb-xxl-11">Now you can continue your application or connect another bank <span class="text-nowrap">account.
    <ls-tooltip [position]="'right'">
      <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
      <div content>
        <label class="l-bold text-wrap">You can connect multiple bank accounts</label>
        <p class="m ls-mb-0 ls-mt-2 text-wrap">For example, some customers may deposit directly into one account, but others send you a check that you deposit into a different bank. In those situations, we’d like to see those transactions to confirm your customers have paid you in the past.</p>
      </div>
    </ls-tooltip>
    </span>
  </p>
  <div class="d-flex flex-md-row flex-column justify-content-between">
    <button type="button" (click)="connectBank()" class="btn btn-primary btn-lg ls-mb-4 ls-mb-md-0">Connect another bank account</button>
    <button type="button" (click)="continue()" class="btn btn-outline-tertiary btn-lg">Continue application</button>
  </div>
</ng-template>
<ng-template #connectionFailed>
  <h1 class="l ls-mb-4 ls-mb-xxl-8">Oh, no... Looks like you didn’t connect your bank account</h1>
  <p class="l ls-mb-6 ls-mb-xxl-11">Let’s try that again...</p>
  <div class="d-flex flex-sm-row flex-column justify-content-between">
    <button type="button" (click)="connectBank()" class="btn btn-primary btn-lg ls-mb-2 ls-mb-sm-0">Try again</button>
    <button type="button" (click)="continue()" class="btn btn-primary-borderless btn-lg btn-do-it-later ls-p-0">I'll connect my bank later</button>
  </div>
</ng-template>
