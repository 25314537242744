import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, forkJoin, map, Observable, take } from "rxjs";
import { AppSelectors, CompanyProfileActions, CompanyProfileSelectors } from "../../../AppStateManagement";
import { ActivatedRouteSnapshot } from "@angular/router";
import { IRouteStepData } from "../../../Models/Interfaces";

@Injectable({ providedIn: "root" })
export class CompanyResolverService {
	constructor(
		private store: Store<any>,
		private companyProfileSelectors: CompanyProfileSelectors,
		private appSelectors: AppSelectors
	) {}

	resolve(route: ActivatedRouteSnapshot): Observable<any> {
		this.initPrefetchData(route);
		return forkJoin([this.companyProfile$, this.onboardCompanyProgress$]);
	}
	private companyProfile$ = this.store.select(this.companyProfileSelectors.selectCompanyProfile).pipe(
		filter((c) => !!c),
		take(1)
	);
	private onboardCompanyProgress$ = this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress).pipe(
		filter((progress) => !!progress),
		take(1)
	);

	private initPrefetchData(route: ActivatedRouteSnapshot): void {
		const routeData = route.data as IRouteStepData;
		this.store
			.select(this.appSelectors.selectUserRoles)
			.pipe(
				take(1),
				filter((userRole) => !!userRole && userRole.roles.length > 0),
				map((userRole) => {
					this.store.dispatch(CompanyProfileActions.getCompanyProfile({ companyId: userRole!.roles[0].companyId }));
					this.store.dispatch(
						CompanyProfileActions.getOnboardCompanyProgress({ companyId: userRole!.roles[0].companyId, routeData })
					);
				})
			)
			.subscribe();
	}
}
