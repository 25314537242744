import { Action, createReducer, on } from "@ngrx/store";
import { CompanyProfile, OnboardCompanyProgress } from "src/app/Models";
import { CompanyProfileActions } from "./company-profile-actions";

export interface CompanyProfileState {
	companyProfile?: CompanyProfile;
	onboardCompanyProgress?: OnboardCompanyProgress;
}

export const initialState: CompanyProfileState = {};

abstract class CompanyReducerHandler {
	static appReducer = createReducer(
		initialState,
		on(CompanyProfileActions.setCompanyProfile, (state: CompanyProfileState, { companyProfile }) => ({
			...state,
			companyProfile: companyProfile
		})),
		on(CompanyProfileActions.setOnboardCompanyProgress, (state: CompanyProfileState, { progress }) => ({
			...state,
			onboardCompanyProgress: progress
		}))
	);
}

export abstract class CompanyProfileReducer {
	static reducer = (state: CompanyProfileState | undefined, action: Action) => {
		return CompanyReducerHandler.appReducer(state, action);
	};
}
