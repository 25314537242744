import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable, Subject, takeUntil } from "rxjs";
import { CompanyProfile } from "src/app/Models";
import { CompanyProfileActions, CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-lien-information",
	templateUrl: "./lien-information.component.html",
	styleUrls: ["./lien-information.component.scss"]
})
export class LienInformationComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public genericSelectors: GenericSelectors,
		public companyProfileSelectors: CompanyProfileSelectors
	) {
		super(store);
	}

	YESNO = "YESNO";
	public yesNo?: GenericLookup<string>[];

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		updatedCompanyProfile!.lienCollaborationYesNoId = this.form.get(this.YESNO)?.value;
		this.emitSave(updatedCompanyProfile);
		const path = this.companyProfile?.isCustomersInProgress ? "review" : "manual";
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([`${data.nextUrl + path}`], { relativeTo: this.activatedRoute });
	}
	init(): Observable<CompanyProfile | undefined> {
		return combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.YesNo))
		]).pipe(
			filter(([cp, yn]) => !!cp && !!yn && yn.length > 0),
			map(([cp, yn]) => {
				this.companyProfile = cp;
				this.yesNo = yn!.reverse();
				this.form = new FormGroup({
					YESNO: new FormControl(this.companyProfile?.lienCollaborationYesNoId, Validators.required)
				});
				return cp;
			})
		);
	}
}
