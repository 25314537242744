<div class="ls-upload-container d-flex flex-column justify-content-center align-items-center hoverable"
	 [class.disabled]="disabled" [disabled]="disabled" (click)="openInput($event)" id="input-container"
	 lsDrag (files)="filesDropped($event)">
	<div class="ls-icon-medium ls-icon-secondary-light d-flex justify-content-center align-items-center ls-mb-3">
		<span class="ls-upload-icon material-icons-outlined secondary-fill" [class.disabled]="disabled">upload_file</span>
	</div>
	<label for="upload" class="ls-upload-label ls-mb-0 d-flex flex-column align-items-center" (click)="disableLabelClick($event)"
			[class.hoverable]="!disabled" [class.disabled]="disabled">
		<p class="m text-center"><strong>Click to upload</strong> or drag your documents here</p>
		<p class="m ls-mb-0 text-center">Maximum {{maxFileCount}} {{maxFileCount! > 1 ? 'files' : 'file'}}: {{allowedFileExtensions?.join(', ')}}
			up to {{maxFileSize}} MB {{maxFileCount! > 1 ? 'each' : null}}
		</p>
	</label>
	<input type="file" id="upload" (change)="handleFiles($event.target)" (click)="clearInput()" [disabled]="disabled" multiple #upload/>
</div>
