import { Action, createReducer, on } from "@ngrx/store";
import { LsUserRole, UserInfo } from "../Models";
import { AppActions } from "./app-actions";

export interface AppState {
	userRole?: LsUserRole;
	userInfo?: UserInfo;
}

export const initialState: AppState = {};

abstract class AppReducerHandler {
	static appReducer = createReducer(
		initialState,
		on(AppActions.setUserRole, (state: AppState, { userRole }) => ({
			...state,
			userRole
		})),
		on(AppActions.setUserInfo, (state: AppState, { userInfo }) => ({
			...state,
			userInfo
		}))
	);
}

export abstract class AppReducer {
	static reducer = (state: AppState | undefined, action: Action) => {
		return AppReducerHandler.appReducer(state, action);
	};
}
