import { Component, OnInit } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, take } from "rxjs";
import { map } from "rxjs/operators";
import { LocalStorageService } from "../../../Utils";
import { CompanyProfile, CompanyUser } from "../../Models";

@Component({
	selector: "ls-invitation",
	template: ""
})
export class InvitationComponent implements OnInit {
	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private localStorageService: LocalStorageService
	) {}

	ngOnInit() {
		this.getParamsAndNavigate();
	}

	getParamsAndNavigate() {
		this.activatedRoute.queryParamMap
			.pipe(
				take(1),
				map((params) => {
					if (params.has("companyId") && params.has("userEmail")) {
						const companyIdStr = params.get("companyId");
						const userEmail = params.get("userEmail");
						if (!!companyIdStr && !!userEmail) {
							const companyId = parseInt(companyIdStr);
							this.localStorageService.setCompanyId(companyId);
							this.localStorageService.setEmailAddress(userEmail);
							this.localStorageService.setB2CSignupState();
						}
					}
					// Remove the query params
					this.router.navigate(["/onboarding/begin-application"], { relativeTo: this.activatedRoute });
				})
			)
			.subscribe();
	}
}
