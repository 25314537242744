import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map } from "rxjs/operators";
import { combineLatest, Subject, takeUntil } from "rxjs";
import { CompanyProfileSelectors } from "../app/AppStateManagement";
import { RouteStepData } from "../app/Modules/COT-Module/Constants";
import { IRouteStepData } from "src/app/Models/Interfaces";
import { RouteStepDataService } from "../app/Modules/COT-Module/Services";
import { CompanyProfile, OnboardCompanyProgress } from "../app/Models";
import { FeatureFlags, FeatureFlagSelectors } from "@limestone/ls-shared-modules";

@Injectable({
	providedIn: "root"
})
export class ContinueApplicationService {
	protected componentTeardown$ = new Subject();
	public customerSetupStep = 3;
	public routeStepData: Map<string, IRouteStepData> = RouteStepData;
	public companyProfile?: CompanyProfile;
	public ocp?: OnboardCompanyProgress;
	constructor(
		private store: Store,
		private companyProfileSelectors: CompanyProfileSelectors,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private featureFlagSelectors: FeatureFlagSelectors,
		private routeStepDataService: RouteStepDataService
	) {
		this.routeStepData = routeStepDataService.getData();
		this.init();
	}

	public init() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				filter(([cp, ocp, ff]) => !!cp && !!ocp && !!ff),
				map(([cp, ocp, ff]) => {
					this.companyProfile = cp;
					this.ocp = ocp;
					this.customerSetupStep = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!.enabled ? 2 : 3;
				})
			)
			.subscribe();
	}

	continueApplication(basePath: string) {
		if (this.ocp?.currentStep === this.customerSetupStep) {
			// Customer Setup
			const path = this.companyProfile?.isCustomersInProgress ? "review" : "manual";
			this.router.navigate([`../onboarding/customer-setup/${path}`], { relativeTo: this.activatedRoute.parent });
		} else {
			const routePath: string = this.getPathString(this.ocp!.currentStep!, this.ocp!.currentSubStep!);
			if (routePath) {
				basePath = `${basePath}${routePath}`;
				this.router.navigate([basePath], { relativeTo: this.activatedRoute.parent });
			}
		}
	}

	continueCustomerSetup(customerId: number, customerSubStep: number) {
		let basePath = "../onboarding/";

		// since we are continuing a specific customer,
		// start them at the first screen for a specific customer
		// if for some reason this customer has not progressed
		// to the first customer screen yet.
		if (customerSubStep < 3) customerSubStep = 3;

		const routePath: string = this.getPathString(this.customerSetupStep, customerSubStep);
		if (routePath) {
			basePath = `${basePath}${routePath}/${customerId}`;
			this.router.navigate([basePath], { relativeTo: this.activatedRoute.parent });
		}
	}

	private getPathString(step: number, substep: number) {
		let retString;

		this.routeStepData.forEach((stepData, key, map) => {
			if (stepData.step === step && stepData.substep === substep) {
				retString = key;
			}
		});
		if (!retString) {
			throw new Error(`No route path found for step: ${step} and substep: ${substep}`);
		}
		return retString;
	}
}
