<div class="d-flex flex-column">
	<h1 class="flex-column ls-mb-8 l">Review application</h1>
	<div class="ls-mb-11 d-flex flex-column">
		<div class="d-flex ls-mb-4 justify-content-between flex-column flex-md-row">
			<div class="d-flex">
				<div class="ls-icon-medium" [class]="getSectionIconClass(ApplicationSection.CONNECT_FINANCIALS)">
					<span class="material-icons">{{getSectionIcon(ApplicationSection.CONNECT_FINANCIALS)}}</span>
				</div>
				<label class="l hoverable" (click)="navToConnectFinancials()">Connect Financials</label>				
			</div>
			<div class="d-flex">
				<p class="m ls-pl-7 ls-pl-md-0">{{getConnectFinancialsMessage()}}</p>
			</div>			
		</div>
		<div class="d-flex ls-mb-4 justify-content-between">
			<div class="d-flex">
				<div class="ls-icon-medium ls-icon-primary-no-background">
					<span class="material-icons">check_circle</span>
				</div>
				<label class="l hoverable" (click)="navToCompanyDetails()">Company Details</label>
			</div>
		</div>
		<div class="d-flex ls-mb-4 justify-content-between">
			<div class="d-flex">
				<div class="ls-icon-medium ls-icon-primary-no-background">
					<span class="material-icons">check_circle</span>
				</div>
				<label class="l hoverable" (click)="navToFinancingIntentions()">Financing Intentions</label>
			</div>
		</div>
		<div class="ls-mb-0">
			<div class="d-flex justify-content-between ls-mb-2">
				<div class="d-flex">
					<div class="ls-icon-medium" [class]="getSectionIconClass(ApplicationSection.CUSTOMER_SETUP)">
						<span class="material-icons">{{getSectionIcon(ApplicationSection.CUSTOMER_SETUP)}}</span>
					</div>
					<label class="l hoverable" (click)="navToCustomerSetup()">Customer Setup</label>
				</div>
			</div>			
			<div class="d-flex flex-column">
				<ul class="no-bullets ls-mb-0 ls-pl-11">
					<li *ngFor="let cpr of companyProfileRelationships; last as last;" class="ls-pl-0_75" [class.ls-mb-2]="!last" [class.ls-mb-0]="last">						
						<div class="d-flex justify-content-between flex-column flex-md-row">
							<div class="d-flex">
								<div class="ls-icon-medium" 
										[class.ls-icon-error-no-background]="!cpr.isCustomerSetupComplete"
										[class.ls-icon-primary-no-background]="cpr.isCustomerSetupComplete">
									<span class="material-icons">{{cpr.isCustomerSetupComplete ? 'check_circle' : 'error'}}</span>
								</div>
								<label class="l hoverable" (click)="navToCustomer(cpr)">{{cpr.relationshipCompanyName}}</label>
							</div>
							<div class="d-flex">
								<p class="m ls-pl-7 ls-pl-md-0 " *ngIf="!cpr.isCustomerSetupComplete!">Please complete setting up this customer</p>
							</div>
						</div>
					</li>
				</ul>				
			</div>			
		</div>
	</div>
	<div class="d-flex flex-row">
		<button type="button" (click)="submitModal()" class="btn btn-lg btn-primary" [disabled]="!canSubmitApplication">Submit application</button>
	</div>
</div>