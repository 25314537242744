<div class="d-flex flex-column">
  <h1 class="l ls-mb-8">Here are the customers you’ve set up so far</h1>
  <p class="l ls-mb-8">You can edit your customer info, add a new one to your Accounts Receivable Finance application, or continue to finalize the application.</p>
  <div class="ls-mb-11">
    <div class="d-flex flex-column justify-content-between ls-mb-0 ls-p-1_75 rounded-7 bg-light flex-sm-row" *ngFor="let cpr of companyProfileRelationships; let last = last" [class.ls-mb-3]="!last">
      <div>
        <p class="title l ls-mb-2 d-flex">{{cpr.relationshipCompanyName}}<span *ngIf="!cpr.isCustomerSetupComplete" class="d-inline ls-icon-badge ls-ml-4 ls-incomplete-warning-icon material-icons">warning</span></p>
        <p class="ls-mb-2 m">
          <ng-container *ngIf="!isV1_0_2_FlagEnabled">
            <span *ngIf="!!cpr.primaryContact">{{cpr?.primaryContact!.firstName}} {{cpr?.primaryContact?.lastName}}</span>
            <span *ngIf="!!cpr.billingAddress && !!cpr.primaryContact"> &middot; </span>
          </ng-container>
          <span>{{cpr?.billingAddress?.toDisplayString()}}</span>
        </p>
        <p class="ls-mb-sm-0 m-bold"><span *ngIf="!isV1_0_2_FlagEnabled && (cpr.requestedCreditLimit | validNumber)">{{getRequestedCreditLimit(cpr)}}</span></p>
      </div>
      <button *ngIf="!cpr.isCustomerSetupComplete" class="btn btn-lg btn-primary" (click)="navToCustomerContact(cpr!.relationshipCompanyId!)">Continue</button>
      <button *ngIf="cpr?.isCustomerSetupComplete" class="btn btn-lg btn-floating btn-primary" (click)="navToCustomerContact(cpr!.relationshipCompanyId!)"><span class="material-icons md-18">mode_edit</span></button>
    </div>
  </div>
  <div class="d-flex flex-column flex-md-row justify-content-between">
    <button type="button" (click)="addAnotherCustomer()" class="btn btn-lg btn-primary ls-mb-4 ls-mb-md-0">Add another customer</button>
    <button type="button" (click)="continue()" class="btn btn-lg btn-outline-tertiary" [disabled]="!hasEnrolledCustomers">Continue application</button>
  </div>
</div>