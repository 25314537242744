import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Subject, filter, map, takeUntil } from "rxjs";
import {
	CompanyProfileActions,
	CompanyProfileSelectors,
	CompanyProfileRelationshipSelectors,
	AppActions,
	AppSelectors
} from "src/app/AppStateManagement";
import { CompanyProfile, UserInfo } from "src/app/Models";
import { EndSessionRequest } from "@azure/msal-browser";
import { MsalService } from "@azure/msal-angular";
import { ActivatedRoute } from "@angular/router";
import { FeatureFlagSelectors, FeatureFlags, OnboardApplicationStatus } from "@limestone/ls-shared-modules";
import { IntegrationMessageType } from "src/app/Models/Enums";
import { MdbNotificationService } from "mdb-angular-ui-kit/notification";
import { IAlert } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit, OnDestroy, AfterViewInit {
	private _componentTeardown$: Subject<any> = new Subject<any>();
	public COMPANY_TAB_INDEX = 0;
	public USER_TAB_INDEX = 1;
	public saveDisabled = true;
	public hideSave = false;
	public companyId?: number;
	public userInfo?: UserInfo;
	public companyProfile?: CompanyProfile;
	public companyProfileTabHasChanges = false;
	public userProfileTabHasChanges = false;
	public isReadOnly?: boolean;
	public IntegrationMessageTypes = IntegrationMessageType;
	public integrationMessage?: IAlert;
	public tabNames: string[] = ["Company Profile", "Your Profile", "Integrations"];
	public _tabIndex?: number;
	public isV1_0_2_FlagEnabled?: boolean;
	get tabIndex(): number {
		return this._tabIndex!;
	}
	set tabIndex(index: number) {
		this._tabIndex = index;
		this.hideSave = ![this.COMPANY_TAB_INDEX, this.USER_TAB_INDEX].includes(index!);
		this.saveDisabled = !(this.companyProfileTabHasChanges || this.userProfileTabHasChanges);
	}
	@ViewChild("successToast", { static: true }) successToast?: TemplateRef<any>;

	constructor(
		private _authService: MsalService,
		private _store: Store,
		public actions$: Actions,
		public companyProfileSelectors: CompanyProfileSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public appSelectors: AppSelectors,
		public featureFlagSelectors: FeatureFlagSelectors,
		public activatedRoute: ActivatedRoute,
		private notificationService: MdbNotificationService
	) {}

	public ngOnInit(): void {
		this.actions$
			.pipe(
				takeUntil(this._componentTeardown$),
				ofType(CompanyProfileActions.companyProfileSaveSuccessful, AppActions.userInfoSaveSuccessful),
				map(() => {
					this.saveDisabled = true;
					this.notificationService.open(this.successToast!, { autohide: true, position: "top-center" });
				})
			)
			.subscribe();

		this._store
			.select(this.companyProfileSelectors.selectCompanyProfile)
			.pipe(
				takeUntil(this._componentTeardown$),
				filter((c) => !!c),
				map((c) => {
					this.companyId = c?.companyId;
					this.companyProfile = c;
				})
			)
			.subscribe();

		this._store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships)
			.pipe(
				filter((cprs) => !!cprs),
				takeUntil(this._componentTeardown$),
				map((cprs) => {
					cprs?.forEach((cpr) => {
						const status = cpr.applicationStatusId;
						this.isReadOnly = this.isReadOnly || (!!status && status !== OnboardApplicationStatus.INPROGRESS);
					});
				})
			)
			.subscribe();

		this._store
			.select(this.appSelectors.selectUserInfo)
			.pipe(
				filter((ui) => !!ui),
				takeUntil(this._componentTeardown$),
				map((ui?: UserInfo) => {
					this.userInfo = ui;
				})
			)
			.subscribe();

		this._store
			.select(this.featureFlagSelectors.selectFeatureFlags)
			.pipe(
				filter((ff) => !!ff),
				takeUntil(this._componentTeardown$),
				map((ff) => {
					this.isV1_0_2_FlagEnabled = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!.enabled;
				})
			)
			.subscribe();
	}

	public ngAfterViewInit(): void {
		this.tabIndex = 0;
		this.activatedRoute.queryParams
			.pipe(
				takeUntil(this._componentTeardown$),
				map((params) => {
					if (params["tabIndex"]) this.tabIndex = parseInt(params["tabIndex"]);
				})
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this._componentTeardown$.next(null);
		this._componentTeardown$.complete();
	}

	public onClick() {
		if (this.companyProfileTabHasChanges) this.updateCompanyProfile();
		if (this.userProfileTabHasChanges) this.updateUserProfile();

		this.companyProfileTabHasChanges = false;
		this.userProfileTabHasChanges = false;
		this.saveDisabled = true;
	}

	public updateCompanyProfile(): void {
		this._store.dispatch(CompanyProfileActions.updateCompanyProfile({ companyProfile: this.companyProfile! }));
	}

	public updateUserProfile(): void {
		this._store.dispatch(AppActions.updateUserInfo({ userInfo: this.userInfo! }));
	}

	public companyProfileFormUpdated(value: CompanyProfile): void {
		this.saveDisabled = false;
		this.companyProfileTabHasChanges = true;
		this.companyProfile = value;
	}

	public userProfileFormUpdated(value: UserInfo): void {
		this.saveDisabled = false;
		this.userProfileTabHasChanges = true;
		this.userInfo = value;
	}

	public logOut() {
		const request: EndSessionRequest = {
			account: this._authService.instance.getActiveAccount(),
			postLogoutRedirectUri: window.origin + "/logout"
		};
		this._authService.logout(request);
	}

	public showIntegrationMessage($event: IAlert): void {
		this.integrationMessage = $event;
	}
}
