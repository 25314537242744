import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map, mergeMap, Observable, of, take, withLatestFrom } from "rxjs";
import {
	AppSelectors,
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors
} from "src/app/AppStateManagement";
import { CompanyProfileRelationship } from "src/app/Models";
import { StepperActions } from "../Modules/COT-Module/OnboardingStateManagement";
import { ChildStepType } from "../Modules/COT-Module/OnboardingStateManagement/Stepper/stepper-reducer";
import { IRouteStepData } from "../Models/Interfaces";

@Injectable()
export class CompanyProfileRelationshipDataResolver {
	constructor(
		private store: Store<any>,
		private appSelectors: AppSelectors,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors
	) {}

	companyProfileRelationshipMethod(
		relationshipCompanyId: string,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<any> {
		const customerId = relationshipCompanyId === "new" ? undefined : parseInt(relationshipCompanyId);
		const routeData = route.data as IRouteStepData;
		return this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship).pipe(
			filter((cpr) => !!cpr && cpr.relationshipCompanyId === customerId),
			take(1),
			map(() => {
				if (state.url.toString().includes("onboarding")) {
					this.store.dispatch(
						StepperActions.initializeChildSteps({
							childStepType: ChildStepType.CUSTOMER_SETUP,
							routeStepData: routeData
						})
					);
					this.store.dispatch(
						StepperActions.setNextStep({
							routeStepData: routeData,
							childStepType: ChildStepType.CUSTOMER_SETUP
						})
					);
				}
			})
		);
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const id = route.paramMap.get("relationshipCompanyId")!;
		this.initPrefetchData(id, route, state);
		return this.companyProfileRelationshipMethod(id, route, state);
	}

	initPrefetchData(id: string, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
		this.store
			.select(this.appSelectors.selectUserRoles)
			.pipe(
				take(1),
				filter((userRole) => !!userRole && userRole.roles.length > 0),
				map((userRole) => userRole!.roles[0].companyId),
				mergeMap((companyProfileId) => {
					let cprObsv;
					if (id !== "new") {
						const relationshipCompanyId = parseInt(id, 10);
						cprObsv = this.existingCompanyProfileRelationship(companyProfileId, relationshipCompanyId);
					} else {
						cprObsv = this.newCompanyProfileRelationship(companyProfileId);
					}
					return cprObsv;
				})
			)
			.subscribe();
	}

	existingCompanyProfileRelationship(companyProfileId: number, relationshipCompanyId: number): Observable<any> {
		return this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship).pipe(
			take(1),
			map(() =>
				this.store.dispatch(
					CompanyProfileRelationshipActions.getCompanyProfileRelationship({
						companyProfileId: companyProfileId,
						relationshipCompanyId: relationshipCompanyId
					})
				)
			)
		);
	}

	newCompanyProfileRelationship(companyProfileId: number): Observable<any> {
		return of(
			this.store.dispatch(
				CompanyProfileRelationshipActions.setCompanyProfileRelationship({
					companyProfileRelationship: new CompanyProfileRelationship(companyProfileId)
				})
			)
		);
	}
}
