import { Action, createReducer, on } from "@ngrx/store";
import { IntegrationDataConnection } from "src/app/Models";
import { CodatActions } from "./codat-actions";

export interface CodatState {
	integrationDataConnection?: IntegrationDataConnection[];
}

export const initialState: CodatState = {};

abstract class CodatReducerHandler {
	static appReducer = createReducer(
		initialState,
		on(CodatActions.setIntegrations, (state: CodatState, { integrationDataConnection }) => ({
			...state,
			integrationDataConnection
		}))
	);
}

export abstract class CodatReducer {
	static reducer = (state: CodatState | undefined, action: Action) => {
		return CodatReducerHandler.appReducer(state, action);
	};
}
