<ng-container *ngIf="(success$ | async); then connectionSuccess; else connectionFailed"></ng-container>
<ng-template #connectionSuccess>
  <h1 class="l ls-mb-4 ls-mb-xxl-8">Excellent!</h1>
  <p class="l ls-mb-6 ls-mb-xxl-11">You’ve successfully connected your accounting system.</p>
  <button type="button" (click)="continue()" class="btn btn-icon btn-primary btn-lg">
    Next <span class="material-icons md-18"> arrow_forward</span>
  </button>
</ng-template>
<ng-template #connectionFailed>
  <h1 class="l ls-mb-4 ls-mb-xxl-8">Oh, no... Looks like you didn’t connect your accounting system</h1>
  <p class="l ls-mb-6 ls-mb-xxl-11">Let’s try that again...</p>
  <div class="d-flex flex-sm-row flex-column justify-content-between">
    <button type="button" (click)="tryAgain()" class="btn btn-primary btn-lg ls-mb-2 ls-mb-sm-0">Try again</button>
    <button type="button" (click)="continue()" class="btn btn-primary-borderless btn-lg btn-do-it-later ls-p-0">I'll connect my accounting later</button>
  </div>
</ng-template>