<div class="support-case">
    <div class="d-flex flex-column flex-row justify-content-between ls-mb-8">
        <h3 class="ls-mb-2 ls-mb-lg-0">Request {{detail.caseNumber}} {{detail.subject}}</h3>
    </div>
    <div class="d-flex flex-md-row flex-column-reverse">
        <div class="d-flex flex-column ls-mr-md-1_25">
            <button type="button" class="btn btn-outline-primary btn-icon ls-mb-xxl-8 ls-mb-4" [hidden]="showReply" (click)="toggleShowReply()">
                <span class="material-icons ls-mr-0_75">reply</span>
                Reply
            </button>
            <ls-case-message-reply [caseId]="detail.id!" [userId]="userId!" [fromAddress]="userEmail!" [parentId]="detail.recordId!" [replyToId]="replyToId!" [threadId]="threadId!" (closeReply)="toggleShowReply()" class="ls-mb-8" [hidden]="!showReply"></ls-case-message-reply>
            <ls-case-message *ngFor="let message of detail.messages; let i = index" [message]="message" [userEmail]="userEmail!" [expanded]="i < 2" (downloadAttachment)="download($event)"></ls-case-message>
        </div>
        <div class="support-case-metadata-container d-flex flex-column ls-mb-8 ls-mb-md-0">
            <div class="meta-data ls-mb-2">
                <label class="s-caps">Assigned To</label>
                <span>{{ detail.ownerName }}</span>
                <label class="s-caps">Created</label>
                <span>{{ detail.createdDate | date }}</span>
                <label class="s-caps">Case ID</label>
                <span>#{{ detail.caseNumber }}</span>
                <label class="s-caps">Last Activity</label>
                <span>{{ detail.lastModifiedDate! | timeSinceDateDisplay }}</span>
                <label class="s-caps">Status</label>
                <div class="ls-badge ls-badge-support"
                     [class.ls-badge-tertiary]="detail.status?.toLowerCase() === CaseStatus.OPEN"
                     [class.ls-badge-primary]="detail.status?.toLowerCase() === CaseStatus.NEW"
                     [class.ls-badge-primary-light]="detail.status?.toLowerCase() === CaseStatus.SOLVED"
                     [class.ls-badge-secondary]="detail.status?.toLowerCase() === CaseStatus.PENDING"
                     [class.ls-badge-neutral]="detail.status?.toLowerCase() === CaseStatus.CLOSED">
                    <label class="s-caps s-column">{{detail.status}}</label>
                </div>
            </div>
            <hr class="solid">
            <div class="attachments ls-mb-2">
                <label class="s-caps">Attachments</label>
                <div class="d-flex flex-row ls-mb-2" *ngFor="let attachment of attachments">
                    <div class="d-flex ls-w-75">
                        <span class="material-icons-outlined ls-mr-1 file-icon">
                            attach_file
                        </span>
                        <span #fileName class="file-name ls-w-100 ls-pl-1 ls-pr-1">
                            {{truncateMultiLineFileName(attachment.name!, attachment.bodyLength! | convertFileSize)}}
                            <span class="file-size">({{ attachment.bodyLength! | convertFileSize }})</span>
                        </span>
                    </div>
                    <div class="d-flex ls-w-25 justify-content-end">
                        <span class="material-icons-outlined ls-mr-4" (click)="download(attachment)">
                            file_download
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>