import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "../../../../../Models";
import copy from "fast-copy";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import {
	CurrencyCode,
	FeatureFlagSelectors,
	FeatureFlags,
	GenericLookup,
	GenericSelectors,
	LookupTables
} from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-currency-payment",
	templateUrl: "./currency-payment.component.html",
	styleUrls: ["./currency-payment.component.scss"]
})
export class CurrencyPaymentComponent extends CustomerRelationshipBaseComponent {
	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public featureFlagSelectors: FeatureFlagSelectors
	) {
		super(router, activatedRoute, store, actions$, companyProfileSelectors);
		this.initData();
	}

	CURRENCY = "currency";
	ONBOARD_PAYMENT_METHOD = "onboardPaymentMethod";

	currencies: GenericLookup<string>[] = [];
	onboardPaymentMethods: GenericLookup<string>[] = [];
	filteredOnboardPaymentMethods: GenericLookup<string>[] = [];
	companyProfileRelationship?: CompanyProfileRelationship;
	customerRelationship2Form: FormGroup = new FormGroup({});
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	isV102Enabled = false;

	public initData(): void {
		combineLatest([
			this.store.select(this.featureFlagSelectors.selectFeatureFlags),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.Currency)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardPaymentMethod)),
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
		])
			.pipe(
				filter(([ff, cur, pm, cpr]) => !!ff && !!cur && !!pm && !!cpr),
				takeUntil(this.componentTeardown$),
				map(([ff, cur, pm, cpr]) => {
					const f = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!;
					this.isV102Enabled = f.enabled;
					this.currencies = cur!;
					this.onboardPaymentMethods = pm!;
					this.filteredOnboardPaymentMethods = pm!;
					this.companyProfileRelationship = cpr!;

					if (!this.isV102Enabled) {
						const otherCurrency = new GenericLookup<string>("Other", "Other");
						if (!this.currencies.find((currency) => currency.id === otherCurrency.id)) {
							this.currencies.push(otherCurrency);
						}
					} else {
						const intWire = new GenericLookup<string>("IW", "International Wire");
						this.filteredOnboardPaymentMethods = this.onboardPaymentMethods.filter((fopm) => fopm.id !== intWire.id);
					}

					if (cpr?.relationshipCompanyName && this.breadcrumbs && this.breadcrumbs.length < 2)
						this.breadcrumbs.push({ routerPath: "", displayText: cpr?.relationshipCompanyName ?? "" });
				})
			)
			.subscribe();

		this.customerRelationship2Form = new FormGroup({
			currency: new FormControl(
				this.companyProfileRelationship?.isOtherCurrency
					? CurrencyCode.OTHER
					: this.companyProfileRelationship?.currencyCode,
				Validators.required
			),
			onboardPaymentMethod: new FormControl(
				this.companyProfileRelationship?.onboardPaymentMethodId,
				Validators.required
			)
		});
	}

	isFormInvalid() {
		return this.customerRelationship2Form.invalid;
	}

	continue() {
		if (this.customerRelationship2Form.dirty) {
			const selectedCurrency = this.customerRelationship2Form.get(this.CURRENCY)?.value;
			const selectedOnboardPaymentMethod = this.customerRelationship2Form.get(this.ONBOARD_PAYMENT_METHOD)?.value;
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);

			if (!this.isV102Enabled) {
				updatedCompanyProfileRelationship.currencyCode =
					selectedCurrency !== CurrencyCode.OTHER ? selectedCurrency : null;
				updatedCompanyProfileRelationship.isOtherCurrency = selectedCurrency === CurrencyCode.OTHER;
			} else {
				updatedCompanyProfileRelationship.currencyCode = selectedCurrency;
			}
			updatedCompanyProfileRelationship.onboardPaymentMethodId = selectedOnboardPaymentMethod;

			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}

	navigateToNextScreen() {
		const customerId = this.companyProfileRelationship?.relationshipCompanyId;
		if (this.companyProfileRelationship?.isManualSetup) {
			this.router.navigate(["../../customer-revenue/" + customerId], { relativeTo: this.activatedRoute });
		} else {
			this.router.navigate(["../../invoice-platform/" + customerId], { relativeTo: this.activatedRoute });
		}
	}
}
