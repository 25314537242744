<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()" [isNextButtonDisabled]="isFormInvalid()"> 
  <div>
    <h2 class="l ls-mb-3">How would you like to disclose your relationship with Raistone to your customer?</h2>
    <p class="l ls-mb-3">Disclosure of Accounts Receivable Financing relationships to your customers is a common practice, but it’s not mandatory.</p>
    <p class="l ls-mb-3">At Raistone, we prioritize your business’s unique needs and goals, offering flexible options tailored to suit your financial strategy. The way you choose to <a class="ls-hyperlink" href="https://raistone.com/help/disclosure-of-relationships/" target="_blank">communicate the financing relationship and manage collections</a> can influence the pricing and structure of the financing we provide.</p>
    <p class="l ls-mb-3">Our customer support team is available to answer any questions you may have.</p>
    <form class="d-flex flex-column ls-mt-8" [formGroup]="noticeOfAssignmentForm">
      <div class="d-flex align-items-center options" *ngFor="let disclosure of financeDisclosures; let last = last"
          [class.ls-mb-4]="!last">
        <input mdbRadio [formControlName]="NOA" 
               class="radio" 
               type="radio" 
               name="financeDisclosure" [id]="disclosure.id" [value]="disclosure.id" />
        <label class="xl" [for]="disclosure.id">{{ disclosure.name }}
          <ls-tooltip *ngIf=tooltips.has(disclosure.id!) [position]="'left'">
            <span class="material-icons ls-icon-primary-tooltip" reference>info</span>
            <div content>
              <p class="m ls-mb-0 text-wrap">{{tooltips.get(disclosure.id!)!}}</p>
            </div>
          </ls-tooltip>
        </label>
      </div>
    </form>
  </div>
</ls-questionnaire> 
