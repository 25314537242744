import { IColumnHeader } from "./Interfaces/IColumnHeader";

export class Application {
	public companyId?: number;
	public companyName?: string;
	public createDate?: Date;
	public lastActivity?: Date;
	public applicationStatusId?: string;
	public applicationStatusName?: string;

	constructor(
		companyId?: number,
		companyName?: string,
		createDate?: Date,
		lastActivity?: Date,
		applicationStatusId?: string,
		applicationStatusName?: string
	) {
		this.companyId = companyId;
		this.companyName = companyName;
		this.createDate = createDate;
		this.lastActivity = lastActivity ? new Date(lastActivity!) : undefined;
		this.applicationStatusId = applicationStatusId;
		this.applicationStatusName = applicationStatusName;
	}

	static ColumnNames: IColumnHeader[] = [
		{ column: "Customer", relatedField: "companyName", canSort: true },
		{ column: "ID", relatedField: "companyId", canSort: false },
		{ column: "Created", relatedField: "createDate", canSort: true },
		{ column: "Last Activity", relatedField: "lastActivity", canSort: true },
		{ column: "Status", relatedField: "applicationStatusName", canSort: true }
	];

	static DefaultSort: string = Application.ColumnNames[3].relatedField;
}
