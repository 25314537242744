import { GenericLookup } from "@limestone/ls-shared-modules";
import { Contact } from "./Contact";

export class UserInfo extends Contact {
	b2cId: string;
	contactId?: number;
	emailAddress?: string;

	constructor(
		b2cId: string,
		contactId?: number,
		firstName?: string,
		lastName?: string,
		phoneCode?: string,
		phoneNumber?: string,
		phoneExt?: string,
		emailAddress?: string,
		contactType?: GenericLookup<string>
	) {
		super(contactId, firstName, lastName, phoneCode, phoneNumber, phoneExt, emailAddress, contactType);
		this.b2cId = b2cId;
		this.contactId = contactId;
		this.emailAddress = emailAddress;
	}
}
