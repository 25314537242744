import { IValidation } from "./Interfaces/IValidation";

export class PhoneValidation implements IValidation {
	type = "Phone";
	countryCode?: string;
	number?: string;
	extension?: string;

	constructor(countryCode?: string, number?: string, extension?: string) {
		this.countryCode = countryCode;
		this.number = number;
		this.extension = extension;
	}
}
