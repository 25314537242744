import { Injectable, OnDestroy } from "@angular/core";
import { FeatureFlagSelectors, FeatureFlags } from "@limestone/ls-shared-modules";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/AppStateManagement";
import { IRouteStepData } from "src/app/Models/Interfaces";
import { Subject, filter, map, takeUntil } from "rxjs";
import { RouteStepData, RouteStepData_V102 } from "../Constants";

@Injectable()
export class RouteStepDataService implements OnDestroy {
	public data: Map<string, IRouteStepData> = RouteStepData;
	private _serviceDestroyed$ = new Subject();
	constructor(store: Store<AppState>, featureFlagSelectors: FeatureFlagSelectors) {
		store
			.select(featureFlagSelectors.selectFeatureFlags)
			.pipe(
				filter((ff) => !!ff),
				takeUntil(this._serviceDestroyed$),
				map((ff) => {
					const f = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!;
					this.data = f.enabled ? RouteStepData_V102 : this.data;
				})
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this._serviceDestroyed$.next(null);
		this._serviceDestroyed$.complete();
	}

	public getData(): Map<string, IRouteStepData> {
		return this.data;
	}
}
