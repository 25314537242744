import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { filter, map, Subject, take, takeUntil } from "rxjs";
import { CompanyProfile, IntegrationDataConnection } from "src/app/Models";
import { CodatActions, CodatSelectors, CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { UnknownAccountPlatformComponent } from "../../../Modals";
import { ActivatedRoute, Router } from "@angular/router";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-accounting-platform",
	templateUrl: "./account-platform.component.html",
	styleUrls: ["./account-platform.component.scss"]
})
export class AccountPlatformComponent implements OnInit {
	protected componentTeardown$ = new Subject();
	constructor(
		private store: Store,
		private codatSelectors: CodatSelectors,
		private companyProfileSelectors: CompanyProfileSelectors,
		public dialog: MdbModalService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {}

	public integrations?: IntegrationDataConnection[] = [];
	public companyProfile?: CompanyProfile;

	@Output() percentComplete: EventEmitter<number> = new EventEmitter<number>();

	ngOnInit() {
		this.store
			.select(this.companyProfileSelectors.selectCompanyProfile)
			.pipe(
				filter((cp) => !!cp),
				takeUntil(this.componentTeardown$),
				map((cp) => {
					this.companyProfile = cp;
				})
			)
			.subscribe();
		this.store
			.select(this.codatSelectors.selectIntegrationDataConnections)
			.pipe(
				filter((integrations) => !!integrations),
				takeUntil(this.componentTeardown$),
				map((integrations) => {
					this.integrations = integrations;
				})
			)
			.subscribe();
	}

	onClick(idc: IntegrationDataConnection) {
		const updatedCompanyProfile = copy(this.companyProfile!);
		updatedCompanyProfile.integrationDataConnectionId = idc.key;
		const path: string = this.getRedirectPath();

		this.store.dispatch(
			CodatActions.createCompanyPlatform({ companyProfile: updatedCompanyProfile, redirectPath: path })
		);
	}

	openModal(event: Event) {
		event.preventDefault();
		this.dialog
			.open(UnknownAccountPlatformComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true
			})
			.onClose.pipe(
				take(1),
				map((accountingPlatform) => {
					if (accountingPlatform !== null || accountingPlatform !== "") {
						this.store.dispatch(CodatActions.notifyNewCompanyPlatform({ accountingPlatform }));
					}
					const data = this.activatedRoute.snapshot.data as IRouteStepData;
					this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute });
				})
			)
			.subscribe();
	}

	public getRedirectPath(): string {
		if (window.location.pathname.includes("settings")) {
			return "home/settings";
		} else {
			return "onboarding/connect-financials/accounting-success";
		}
	}
}
