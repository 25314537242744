import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { CodatActions, CompanyProfileSelectors } from "../../../../../../AppStateManagement";
import { ConnectFinancialsResultService } from "src/services/connect-financials-result.service";
import { CodatConnectionType } from "src/app/Models/Enums/CodatConnectionType";
import { BehaviorSubject, Subject, filter, map, takeUntil } from "rxjs";
import { CompanyProfile } from "src/app/Models";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-bank-account-result",
	templateUrl: "./bank-account-result.component.html",
	styleUrls: ["./bank-account-result.component.scss"],
	providers: [ConnectFinancialsResultService]
})
export class BankAccountResultComponent implements OnInit, OnDestroy {
	public componentTeardown$ = new Subject();
	public success$: BehaviorSubject<boolean> = this._connectFinancialsResultService.success$;
	public companyProfile?: CompanyProfile;
	public path = "onboarding/connect-financials/banking-success";

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public store: Store,
		public companyProfileSelectors: CompanyProfileSelectors,
		private _connectFinancialsResultService: ConnectFinancialsResultService
	) {}

	public ngOnInit() {
		this.store
			.select(this.companyProfileSelectors.selectCompanyProfile)
			.pipe(
				filter((cp) => !!cp),
				takeUntil(this.componentTeardown$),
				map((cp) => {
					this.companyProfile = cp;
					this._connectFinancialsResultService.init(CodatConnectionType.BANKING);
				})
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public connectBank() {
		this.store.dispatch(
			CodatActions.createConnection({ companyProfile: this.companyProfile!, redirectPath: this.path })
		);
	}

	public continue() {
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute });
	}
}
