import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, filter, map, Subject, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";
import { Store } from "@ngrx/store";
import {
	FeatureFlagSelectors,
	FeatureFlags,
	GenericLookup,
	GenericSelectors,
	LookupTables
} from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-customer-settings-readonly",
	templateUrl: "./customer-settings-readonly.component.html",
	styleUrls: ["./customer-settings-readonly.component.scss"]
})
export class CustomerSettingsReadonlyComponent implements OnInit, OnDestroy {
	private componentTeardown$: Subject<any> = new Subject<any>();
	private _companyProfileRelationship?: CompanyProfileRelationship;
	public preferredPayments?: GenericLookup<string>[];
	public financeDisclosures?: GenericLookup<string>[];
	public isV1_0_2_FlagEnabled?: boolean;
	@Input() set companyProfileRelationship(companyProfileRelationship: CompanyProfileRelationship | undefined) {
		if (companyProfileRelationship) {
			this._companyProfileRelationship = companyProfileRelationship;
		}
	}
	get companyProfileRelationship(): CompanyProfileRelationship | undefined {
		return this._companyProfileRelationship;
	}

	constructor(
		private store: Store,
		private genericSelectors: GenericSelectors,
		public featureFlagSelectors: FeatureFlagSelectors
	) {}

	ngOnInit() {
		combineLatest([
			this.store.select(this.genericSelectors.selectLookup(LookupTables.PreferredPaymentMethod)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.FinanceDisclosure)),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				filter(([payments, disclosures, ff]) => !!payments && !!disclosures && !!ff),
				map(([payments, disclosures, ff]) => {
					this.preferredPayments = payments!;
					this.financeDisclosures = disclosures!;
					this.isV1_0_2_FlagEnabled = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!.enabled;
				})
			)
			.subscribe();
	}
	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}
}
