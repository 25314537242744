import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	Validators
} from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { filter, map, Subject, takeUntil } from "rxjs";
import { ValidationActions } from "src/app/Modules/COT-Module/OnboardingStateManagement/Validation/validation-actions";
import { Contact, EmailValidation } from "src/app/Models";
import { Guid } from "src/Utils";

export type Orientation = "column" | "row";
@Component({
	selector: "ls-contact-form-control",
	templateUrl: "./contact-form-control.component.html",
	styleUrls: ["./contact-form-control.component.scss"],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: ContactFormControlComponent, multi: true },
		{ provide: NG_VALIDATORS, useExisting: ContactFormControlComponent, multi: true }
	]
})
export class ContactFormControlComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
	protected componentTeardown$ = new Subject();
	@Input() hidePhoneControl = false;
	@Input() orientation: Orientation = "row";

	private id: string;
	FIRST_NAME = "firstName";
	LAST_NAME = "lastName";
	PHONE = "phone";
	emailValidationResult?: string;
	formError = "";
	EMAIL = "email";

	formGroup: FormGroup = new FormGroup({});

	constructor(
		private store: Store,
		private actions$: Actions,
		private guid: Guid
	) {
		this.id = this.guid.New();
		this.FIRST_NAME = "firstName";
		this.LAST_NAME = "lastName";
		this.PHONE = "phone";
		this.EMAIL = "email";
		this.formGroup.addControl(this.FIRST_NAME, new FormControl(null, Validators.required));
		this.formGroup.addControl(this.LAST_NAME, new FormControl(null, Validators.required));
		this.formGroup.addControl(this.PHONE, new FormControl(null, Validators.required));
		this.formGroup.addControl(this.EMAIL, new FormControl(null, Validators.required));
	}

	ngOnInit() {
		if (this.hidePhoneControl) {
			this.formGroup.removeControl(this.PHONE);
			this.formGroup.updateValueAndValidity();
		}
		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(ValidationActions.validationFailure),
				filter((action) => action.validationType === "Email" && action.id === this.id),
				map((action) => {
					const errorMap = action.result.errors;
					if (errorMap.has("Email")) this.formGroup.get(this.EMAIL)?.setErrors({ error: errorMap.get("Email") });
					let emailErr = "";
					errorMap.forEach((errArray) => errArray.map((e) => (emailErr += e)));
					this.emailValidationResult = emailErr;
					this.validatorChange();
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	writeValue(contactFormData?: Contact): void {
		this.formGroup.get(this.FIRST_NAME)?.setValue(contactFormData?.firstName, { emitEvent: false });
		this.formGroup.get(this.LAST_NAME)?.setValue(contactFormData?.lastName, { emitEvent: false });
		if (!this.hidePhoneControl) this.formGroup.get(this.PHONE)?.setValue(contactFormData?.phone, { emitEvent: false });
		this.formGroup.get(this.EMAIL)?.setValue(contactFormData?.email, { emitEvent: false });
	}

	registerOnChange(onChange: (value: Contact | null) => void): void {
		this.formGroup.valueChanges.pipe(takeUntil(this.componentTeardown$), map(onChange)).subscribe();
	}

	onTouched() {}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		isDisabled ? this.formGroup.disable() : this.formGroup.enable();
	}

	validate(control: AbstractControl<any, any>): ValidationErrors | null {
		if (this.formGroup.valid) {
			return null;
		}

		let errors: any = {};

		errors = this.addControlErrors(errors, this.FIRST_NAME);
		errors = this.addControlErrors(errors, this.LAST_NAME);
		if (!this.hidePhoneControl) errors = this.addControlErrors(errors, this.PHONE);
		errors = this.addControlErrors(errors, this.EMAIL);

		return errors;
	}

	addControlErrors(allErrors: any, controlName: string) {
		const errors = { ...allErrors };

		if (this.formGroup.controls[controlName]) {
			const controlErrors = this.formGroup.controls[controlName].errors;

			if (controlErrors) {
				errors[controlName] = controlErrors;
			}

			return errors;
		}
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.validatorChange = fn;
	}

	validatorChange() {}

	controlHasError(controlName: string): boolean {
		if (this.formGroup.get(controlName)) {
			return (
				this.formGroup.get(controlName)!.invalid &&
				(this.formGroup.get(controlName)!.dirty || this.formGroup.get(controlName)!.touched)
			);
		}
		return false;
	}

	emailControlHasErrors(): boolean {
		const errors = this.formGroup.get(this.EMAIL)!.errors;
		if (errors) {
			return errors["error"];
		}
		return false;
	}
	getControlError(controlName: string): string {
		return this.formGroup.get(controlName)?.errors!["error"];
	}

	validateEmail() {
		const emailValidation = new EmailValidation(this.formGroup.get(this.EMAIL)?.value);
		this.store.dispatch(ValidationActions.validateEmail({ id: this.id, email: emailValidation }));
	}
}
