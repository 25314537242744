import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject, map, takeUntil } from "rxjs";
import { Contact, UserInfo } from "src/app/Models";

@Component({
	selector: "ls-user-profile",
	templateUrl: "./user-profile.component.html",
	styleUrls: ["./user-profile.component.scss"]
})
export class UserProfileComponent implements OnInit, OnDestroy {
	@Output() userInfoChange: EventEmitter<UserInfo> = new EventEmitter();
	@Output() formHasErrors: EventEmitter<any> = new EventEmitter();
	private componentTeardown$: Subject<any> = new Subject<any>();
	public USER = "user";
	public form: FormGroup = new FormGroup({});
	private _userInfo?: UserInfo;

	@Input() get userInfo() {
		return this._userInfo;
	}

	set userInfo(ui) {
		this._userInfo = ui;
		this.setFormControls();
	}

	constructor() {}

	public ngOnInit(): void {
		this.handleFormChange();
	}

	public handleFormChange(): void {
		this.form!.get(this.USER)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((formValue) => {
					if (!this.form!.valid) {
						this.formHasErrors.emit();
						return;
					}
					if (this.form!.dirty) {
						const regex = new RegExp(/.+@.+\..{2,}/g);
						const validEmail = formValue.email?.length >= 6 && regex.test(formValue.email);
						if (validEmail) {
							this.updateUserInfo(formValue);
							this.userInfoChange.emit(this.userInfo);
						} else {
							this.formHasErrors.emit();
						}
					}
				})
			)
			.subscribe();
	}

	public updateUserInfo(formValue: Contact) {
		this.userInfo!.firstName = formValue.firstName;
		this.userInfo!.lastName = formValue.lastName;
		this.userInfo!.phone = formValue.phone;
		this.userInfo!.emailAddress = formValue.email;
	}

	setFormControls() {
		this.form.addControl(this.USER, new FormControl(this.userInfo, Validators.required), { emitEvent: false });
	}

	public ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}
}
