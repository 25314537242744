import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { filter, map, Subject, takeUntil } from "rxjs";
import { CompanyAddress, CompanyProfile, Phone } from "src/app/Models";
import { AddressType, FeatureFlagSelectors, FeatureFlags, GenericLookup } from "@limestone/ls-shared-modules";
import { environment } from "../../../../../../environments/environment";
import { Store } from "@ngrx/store";

@Component({
	selector: "ls-company-profile-readonly",
	templateUrl: "./company-profile-readonly.component.html",
	styleUrls: ["./company-profile-readonly.component.scss"]
})
export class CompanyProfileReadonlyComponent implements OnInit, OnDestroy {
	@Output() companyProfileChange: EventEmitter<CompanyProfile> = new EventEmitter();
	@Output() formHasErrors: EventEmitter<boolean> = new EventEmitter();
	protected readonly environment = environment;

	private _companyProfile?: CompanyProfile;
	public primaryAddress?: CompanyAddress;
	public billingAddress?: CompanyAddress;
	@Input() get companyProfile() {
		return this._companyProfile;
	}

	set companyProfile(cp) {
		this._companyProfile = cp;
		this.primaryAddress = this.companyProfile?.addresses?.find((a) => a.addressType?.id === AddressType.PRIMARY);
		this.billingAddress = this.companyProfile?.addresses?.find((a) => a.addressType?.id === AddressType.BILLING);
		this.setFormControls();
	}

	private componentTeardown$ = new Subject();
	public PHONE = "phone";
	public BILLING = "billing";
	public companyProfileReadonlyForm: FormGroup = new FormGroup({});
	public toggled = false;
	public _isV1_0_2_FlagEnabled?: boolean;

	@Input() get isV1_0_2_FlagEnabled() {
		return this._isV1_0_2_FlagEnabled;
	}

	set isV1_0_2_FlagEnabled(ff) {
		this._isV1_0_2_FlagEnabled = ff;
	}

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute
	) {}

	ngOnInit() {
		this.handleFormChange();
	}

	setFormControls() {
		if (this.billingAddress && !this._isV1_0_2_FlagEnabled) {
			this.toggled = true;
		}

		const phone = new Phone(
			this.companyProfile?.phoneCountryCode,
			this.companyProfile?.phoneNumber,
			this.companyProfile?.phoneExt
		);
		this.companyProfileReadonlyForm = new FormGroup({
			phone: new FormControl(phone, Validators.required)
		});
	}

	public handleFormChange(): void {
		this.companyProfileReadonlyForm!.valueChanges.pipe(
			takeUntil(this.componentTeardown$),
			map(() => {
				if (!this.companyProfileReadonlyForm!.valid) {
					this.formHasErrors.emit();
					return;
				}
				if (this.companyProfileReadonlyForm!.dirty) {
					this.updateCompanyProfile();
					this.companyProfileChange.emit(this.companyProfile);
				}
			})
		).subscribe();
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	updateCompanyProfile() {
		const phone: Phone = this.companyProfileReadonlyForm.get(this.PHONE)?.value;
		this.companyProfile!.phoneCountryCode = phone.countryCode;
		this.companyProfile!.phoneNumber = phone.number;
		this.companyProfile!.phoneExt = phone.extension;

		const ba = this.companyProfileReadonlyForm.get(this.BILLING)?.value;
		if (ba && this.toggled && !this._isV1_0_2_FlagEnabled) {
			ba.id = this.billingAddress?.address?.id;
			const billingAddress = new CompanyAddress(
				new GenericLookup<string>(AddressType.BILLING, "Billing", true),
				true,
				ba
			);
			this.companyProfile!.addresses?.push(billingAddress);
		}
	}
}
