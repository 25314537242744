<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()" [isNextButtonDisabled]="isFormInvalid()">
	<h2 class="l ls-mb-6">Tell us more about your relationship with your customer</h2>
	<form [formGroup]="customerRelationship2Form" class="d-flex flex-column">
		<div class="ls-mb-6">
			<p class="title m">What currency do you use to do business with this customer?</p>
			<div *ngFor="let currency of currencies" class="ls-pb-3 d-flex align-items-center">
				<input mdbRadio [formControlName]="CURRENCY"
							 class="radio"
							 type="radio"
							 name="currency" [id]="currency.id" [value]="currency.id" />
				<label class="xl" [for]="currency.id">
					{{ currency.id }}
					<ls-tooltip *ngIf="currency.id === 'Other'" [position]="'right'">
						<span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
						<div content>
							<p class="m ls-mb-0 text-wrap">We currently only support the listed currencies and are working diligently to expand to other currencies.</p>
						</div>
					</ls-tooltip>
				</label>
			</div>
		</div>
		<div>
			<p class="title m">How do you receive payments from your customer today?</p>
			<div *ngFor="let onboardPaymentMethod of filteredOnboardPaymentMethods" class="pb-3 d-flex flex-row align-items-center">
				<input mdbRadio [formControlName]="ONBOARD_PAYMENT_METHOD"
							 class="radio"
							 type="radio"
							 name="paymentMethod" [id]="onboardPaymentMethod.id" [value]="onboardPaymentMethod.id" />
				<label class="xl" [for]="onboardPaymentMethod.id">{{ onboardPaymentMethod.name }}</label>
			</div>
		</div>
	</form>
</ls-questionnaire>