export class B2CQueryParams {
	companyId?: number;
	userEmail!: string;
	isLogin!: string;
	createAccUrl!: string;

	constructor(companyId?: number, userEmail?: string, isLogin?: boolean) {
		this.companyId = companyId;
		this.userEmail = userEmail ?? "";
		this.isLogin = isLogin ? "true" : "false";
		this.createAccUrl = window.location.origin + "/onboarding/info";
	}

	toParamsObject(): any {
		if (this.companyId) {
			return {
				companyId: this.companyId,
				userEmail: encodeURIComponent(this.userEmail),
				isLogin: this.isLogin,
				createAccUrl: this.createAccUrl
			};
		} else {
			return {
				userEmail: encodeURIComponent(this.userEmail),
				isLogin: this.isLogin,
				createAccUrl: this.createAccUrl
			};
		}
	}
}
