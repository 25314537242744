import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { filter, map, Subject, takeUntil } from "rxjs";
import { CompanyAddress, CompanyProfile, Phone } from "src/app/Models";
import { GenericLookup, AddressType, FeatureFlagSelectors, FeatureFlags } from "@limestone/ls-shared-modules";
import { Store } from "@ngrx/store";
@Component({
	selector: "ls-company-profile",
	templateUrl: "./company-profile.component.html",
	styleUrls: ["./company-profile.component.scss"]
})
export class CompanyProfileComponent implements OnInit, OnDestroy {
	@Output() companyProfileChange: EventEmitter<CompanyProfile> = new EventEmitter();
	@Output() formHasErrors: EventEmitter<boolean> = new EventEmitter();

	private _companyProfile?: CompanyProfile;
	public primaryAddress?: CompanyAddress;
	public billingAddress?: CompanyAddress;
	public _isV1_0_2_FlagEnabled?: boolean;

	@Input() get isV1_0_2_FlagEnabled() {
		return this._isV1_0_2_FlagEnabled;
	}

	set isV1_0_2_FlagEnabled(ff) {
		this._isV1_0_2_FlagEnabled = ff;
		if (this._isV1_0_2_FlagEnabled) this.setFormControls();
	}

	@Input() get companyProfile() {
		return this._companyProfile;
	}

	set companyProfile(cp) {
		this._companyProfile = cp;
		this.primaryAddress = this.companyProfile?.addresses?.find((a) => a.addressType?.id === AddressType.PRIMARY);
		this.billingAddress = this.companyProfile?.addresses?.find((a) => a.addressType?.id === AddressType.BILLING);
		this.setFormControls();
	}

	private componentTeardown$ = new Subject();
	public LEGAL_NAME = "legalName";
	public DBA_NAMES = "dbaNames";
	public PHONE = "phone";
	public PRIMARY = "primary";
	public BILLING = "billing";

	public companyProfileForm: FormGroup = new FormGroup({});
	public toggled = false;

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute
	) {}

	ngOnInit() {
		this.handleFormChange();
	}

	setFormControls() {
		if (this.billingAddress && !this._isV1_0_2_FlagEnabled) {
			this.toggled = true;
		}

		const phone = new Phone(
			this.companyProfile?.phoneCountryCode,
			this.companyProfile?.phoneNumber,
			this.companyProfile?.phoneExt
		);

		if (this._isV1_0_2_FlagEnabled) {
			this.companyProfileForm = new FormGroup({
				legalName: new FormControl(this.companyProfile?.legalBusinessName, Validators.required),
				phone: new FormControl(phone, Validators.required),
				primary: new FormControl(this.primaryAddress?.address, Validators.required)
			});
		} else {
			this.companyProfileForm = new FormGroup({
				legalName: new FormControl(this.companyProfile?.legalBusinessName, Validators.required),
				dbaNames: new FormControl(this.companyProfile?.dbaNames),
				phone: new FormControl(phone, Validators.required),
				primary: new FormControl(this.primaryAddress?.address, Validators.required),
				billing: new FormControl({ value: this.billingAddress?.address, disabled: !this.toggled })
			});
		}
	}

	public handleFormChange(): void {
		this.companyProfileForm!.valueChanges.pipe(
			takeUntil(this.componentTeardown$),
			map((formValue) => {
				if (!this.companyProfileForm!.valid) {
					this.formHasErrors.emit();
					return;
				}
				if (this.companyProfileForm!.dirty) {
					this.updateCompanyProfile(formValue);
					this.companyProfileChange.emit(this.companyProfile);
				}
			})
		).subscribe();
	}

	toggleBillingForm() {
		this.toggled = !this.toggled;
		this.toggled
			? this.companyProfileForm.get(this.BILLING)?.enable()
			: this.companyProfileForm.get(this.BILLING)?.disable();
		this.companyProfileForm.get(this.BILLING)?.updateValueAndValidity();
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	updateCompanyProfile(formValue: any) {
		this.companyProfile!.legalBusinessName = formValue.legalName;
		if (!this.isV1_0_2_FlagEnabled) {
			const dbaControl = this.companyProfileForm.get(this.DBA_NAMES);
			if (dbaControl?.dirty) this.companyProfile!.dbaNames = formValue.dbaNames?.dbaNames;
		}

		const phone: Phone = formValue.phone;
		this.companyProfile!.phoneCountryCode = phone.countryCode;
		this.companyProfile!.phoneNumber = phone.number;
		this.companyProfile!.phoneExt = phone.extension;

		this.companyProfile!.addresses = [];
		const pa = formValue.primary;
		if (pa) {
			pa.id = this.primaryAddress?.address?.id;
			const primaryAddress = new CompanyAddress(
				new GenericLookup<string>(AddressType.PRIMARY, "Primary Office / Headquarters", true),
				true,
				pa
			);
			this.companyProfile!.addresses?.push(primaryAddress);
		}

		const ba = formValue.billing;
		if (ba && this.toggled && !this._isV1_0_2_FlagEnabled) {
			ba.id = this.billingAddress?.address?.id;
			const billingAddress = new CompanyAddress(
				new GenericLookup<string>(AddressType.BILLING, "Billing", true),
				true,
				ba
			);
			this.companyProfile!.addresses?.push(billingAddress);
		}
	}
}
