import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { map, takeUntil } from "rxjs/operators";
import { filter, Subject, take } from "rxjs";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { Actions, ofType } from "@ngrx/effects";
import { Location } from "@angular/common";
import { CompanyDocument, LsHttpErrorResponse } from "../Models";
import { CompanyDocumentType } from "../Models/Enums";
import { FileUploadError, SortEvent } from "../Models/Interfaces";
import { DeleteDocumentComponent } from "../Modules/COT-Module/Modals";
import { UploadStatus } from "../Models/Enums/UploadStatus";
import { FileHandle } from "../../Elements/upload-widget/FileHandle";
import { CompanyDocumentActions } from "../Modules/COT-Module/OnboardingStateManagement/CompanyDocument/company-document-actions";
import { ModalActions } from "./ModalActions";

@Component({ selector: "ls-rmh", template: "" })
export abstract class FileUploadBaseComponent implements OnInit, OnDestroy {
	private componentTeardown$ = new Subject();
	public files?: CompanyDocument[];
	public allowedFileExtensions: string[];
	public errors: string[] = [];
	public maxFileSize = 50; // In MB
	public maxFileCount = 10;
	public disabled = false;
	public submitted = false;
	private companyId?: number;
	private readonly docType: CompanyDocumentType;

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public store: Store,
		public dialog: MdbModalService,
		public actions$: Actions,
		public location: Location
	) {
		this.allowedFileExtensions = ["PDF", "xls", "xlsx", "CSV", "unencrypted ZIP"];
		this.docType = this.activatedRoute.snapshot.data["docType"] as CompanyDocumentType;
	}

	ngOnInit() {
		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyDocumentActions.saveUnsuccessful),
				map((act) => {
					const errMap = act.result.errors;
					const f = this.files?.find(
						(f) => f.fileName === act.file?.file.name && f.status === UploadStatus.IN_PROGRESS
					);
					if (f) {
						f.status = UploadStatus.FAILED;
					}
					errMap.forEach((val, key) => {
						if (key === "FileSizeExceeded") {
							this.errors.push(
								`<strong>${val.toString()}</strong> exceeds the maximum file size. Please upload documents smaller than 50 MB.`
							);
						}
						if (key === "UnsupportedFileType") {
							this.errors.push(`The file type for <strong>${val.toString()}</strong> isn’t permitted.
                        Try again with any of the allowed file types: ${this.allowedFileExtensions.join(", ")}`);
						}
					});

					this.checkIfShouldDisable();
				})
			)
			.subscribe();
	}

	public getDocType(): CompanyDocumentType {
		return this.docType;
	}

	public getComponentTearDown(): Subject<any> {
		return this.componentTeardown$;
	}

	public setCompanyId(id: number): void {
		this.companyId = id;
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public navTo() {}

	public handleSortChange(sort: SortEvent) {}

	public handleError(error: FileUploadError) {
		this.errors.push(error.errorMessage);
		this.files?.push(
			new CompanyDocument(
				this.companyId,
				error.file.name,
				this.convertFileSize(error.file.size),
				new Date(),
				UploadStatus.FAILED,
				this.docType
			)
		);
	}

	public openFile(file: CompanyDocument) {
		this.store.dispatch(
			CompanyDocumentActions.downloadFile({
				companyId: file.companyId!,
				documentType: this.docType,
				fileName: file.fileName!
			})
		);
	}

	public deleteDocument(document: CompanyDocument) {
		this.dialog
			.open(DeleteDocumentComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true,
				data: { fileName: document.fileName }
			})
			.onClose.pipe(
				filter((result: ModalActions) => result === ModalActions.PRIMARY),
				take(1),
				map((result) => {
					this.store.dispatch(
						CompanyDocumentActions.deleteCompanyDocument({
							id: document.companyId!,
							documentType: document.documentType!,
							document
						})
					);
				})
			)
			.subscribe();
	}

	public uploadFiles(files: FileHandle[]) {
		if (this.fileCountOverLimit(files)) {
			return;
		}

		files.forEach((f) => {
			this.files?.push(
				new CompanyDocument(
					this.companyId,
					f.file.name,
					this.convertFileSize(f.file.size),
					new Date(),
					UploadStatus.IN_PROGRESS,
					this.docType
				)
			);
			if (this.isFileSizeOverLimit(f)) {
				return;
			}

			this.store.dispatch(
				CompanyDocumentActions.uploadFile({
					companyId: this.companyId!,
					documentType: this.docType,
					file: f
				})
			);
		});

		this.checkIfShouldDisable();
	}

	public isDisabled(): boolean {
		return !(this.files && this.files.some((f) => f.status === UploadStatus.COMPLETE));
	}

	private convertFileSize(size: number): string {
		const sizes: string[] = ["B", "KB", "MB", "GB", "TB"];
		let order = 0;
		let len = size;
		while (len >= 1024 && order < sizes.length - 1) {
			order++;
			len = len / 1024;
		}
		return `${Math.round(len)} ${sizes[order]}`;
	}

	private isFileSizeOverLimit(file: FileHandle): boolean {
		const maxBytes = this.convertMegaBytesToBytes(this.maxFileSize);
		if (file.file.size > maxBytes) {
			const errors = {
				FileSizeExceeded: file.file.name
			};
			const errResp = new LsHttpErrorResponse(undefined, undefined, undefined, undefined, errors);
			this.store.dispatch(CompanyDocumentActions.saveUnsuccessful({ file: file, result: errResp }));
			return true;
		}

		return false;
	}

	private convertMegaBytesToBytes(megabytes: number): number {
		return megabytes * 1048576;
	}

	private fileCountOverLimit(files: FileHandle[]): boolean {
		const completeOrInprogressFiles = this.getCompletedOrInProgressFiles();
		if (completeOrInprogressFiles) {
			return completeOrInprogressFiles.length + files.length > this.maxFileCount;
		} else {
			return files.length > this.maxFileCount;
		}
	}

	private getCompletedOrInProgressFiles(): CompanyDocument[] {
		return this.files!.filter((file) => [UploadStatus.IN_PROGRESS, UploadStatus.COMPLETE].includes(file.status!));
	}

	private checkIfShouldDisable(): void {
		this.disabled = this.getCompletedOrInProgressFiles().length === this.maxFileCount;
	}

	public abstract initData(): void;
}
