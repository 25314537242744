export class OnboardCompanyProgress {
	companyId: number;
	currentStep?: number;
	currentSubStep?: number;
	isOnboardingComplete: boolean;

	constructor(companyId: number, isOnboardingComplete: boolean, currentStep?: number, currentSubStep?: number) {
		this.companyId = companyId;
		this.currentStep = currentStep;
		this.currentSubStep = currentSubStep;
		this.isOnboardingComplete = isOnboardingComplete;
	}
}
