import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { filter, mergeMap, withLatestFrom } from "rxjs";
import { StepperActions } from "./stepper-actions";
import { Store } from "@ngrx/store";
import { StepperSelectors } from "./stepper-selectors";
import { LsStep } from "../../../../../Elements/stepper/limestone-element-stepper.component";
import {
	CompanyProfileActions,
	CompanyProfileSelectors,
	CompanyProfileRelationshipSelectors,
	CompanyProfileRelationshipActions
} from "../../../../AppStateManagement";
import { OnboardCompanyProgress } from "../../../../Models";
import { IRouteStepData } from "../../../../Models/Interfaces";
import { RouteStepData, RouteStepData_V102 } from "../../Constants";
import { ActiveState, ChildStepType, StepperState } from "./stepper-reducer";
import { FeatureFlagSelectors, FeatureFlags } from "@limestone/ls-shared-modules";

@Injectable()
export class StepperEffects {
	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private stepperSelectors: StepperSelectors,
		private companyProfileSelectors: CompanyProfileSelectors,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		private featureFlagSelectors: FeatureFlagSelectors
	) {}

	setNextStep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StepperActions.setNextStep),
			withLatestFrom(
				this.store.select(this.stepperSelectors.selectState),
				this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
				this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
				this.store.select(this.companyProfileSelectors.selectCompanyProfile),
				this.store.select(this.featureFlagSelectors.selectFeatureFlags)
			),
			filter(([act, state, cpProgress, cpr, cp, ff]) => !!ff),
			mergeMap(([act, state, cpProgress, cpr, cp, ff]) => {
				const actions: Array<any> = new Array<any>();
				let customerSetupStep = 3;
				// update parent steps //
				let steps = state.steps;
				if (ff?.some((f) => f.id === FeatureFlags.Onboarding_V1_0_2 && f.enabled)) {
					steps = state.steps.filter((s) => s.label !== "Financing Intentions");
					customerSetupStep = 2;
				}

				const activeStep = state.activeState!.activeStep ?? 0;
				if (act.routeStepData.step! >= activeStep)
					this.setPercentComplete(steps[activeStep], act.routeStepData.substep ?? cpProgress!.currentSubStep!);

				if (act.routeStepData.step! > activeStep) this.updateStep(steps[activeStep]);

				if (act.routeStepData.step! >= 2) {
					actions.push(StepperActions.setSettingsDisplay({ display: true }));
					state.displaySettings = true;
				}

				// set route for customer setup landing page
				steps[customerSetupStep].route = cp?.isCustomersInProgress
					? "customer-setup/review"
					: steps[customerSetupStep].route;
				this.store.dispatch(StepperActions.updateCompletedSteps());

				// update child steps (if needed)//
				if (act.routeStepData.section !== undefined && state.activeState?.childStepsActive) {
					let childSteps: LsStep[] = [];

					switch (act.childStepType) {
						case ChildStepType.CUSTOMER_SETUP:
							childSteps = steps[customerSetupStep].children!;
							if (act.routeStepData.substep! > cpr!.currentSubStep!) {
								this.updateStep(childSteps[act.routeStepData.section - 1]);
								this.setPercentComplete(childSteps[act.routeStepData.section], act.routeStepData.subSection!);
								cpr!.currentSubStep = act.routeStepData.substep!;
								actions.push(
									CompanyProfileRelationshipActions.updateCustomerProgress({ companyProfileRelationship: cpr! })
								);
							}
					}
					state.steps = steps;
				}

				if (act.routeStepData.step === undefined) {
					act.routeStepData = {
						step: state.activeState?.activeStep,
						substep: state.activeState?.activeSubstep
					};
				}
				actions.push(
					StepperActions.updateSteps({ steps }),
					StepperActions.setActiveState({ activeState: this.configureActiveState(act.routeStepData, state) })
				);

				// update company progress //
				if (
					(cpProgress!.currentStep || cpProgress!.currentStep === 0) &&
					(cpProgress!.currentSubStep || cpProgress!.currentSubStep === 0)
				) {
					if (
						act.routeStepData.step! > cpProgress!.currentStep ||
						(act.routeStepData.step! === cpProgress!.currentStep &&
							act.routeStepData.substep! > cpProgress!.currentSubStep)
					) {
						// Moving forward to new step/substep first time
						const progressObject: OnboardCompanyProgress = new OnboardCompanyProgress(
							cpProgress!.companyId,
							cpProgress!.isOnboardingComplete,
							act.routeStepData.step,
							act.routeStepData.substep
						);
						actions.push(CompanyProfileActions.updateOnboardCompanyProgress({ progress: progressObject }));
					}
				} else {
					// First time updating the DB
					const progressObject: OnboardCompanyProgress = new OnboardCompanyProgress(
						cpProgress!.companyId,
						cpProgress!.isOnboardingComplete,
						act.routeStepData.step,
						act.routeStepData.substep
					);
					actions.push(CompanyProfileActions.updateOnboardCompanyProgress({ progress: progressObject }));
				}

				return actions;
			})
		)
	);

	initializeStepper$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StepperActions.initializeStepper),
			withLatestFrom(
				this.store.select(this.stepperSelectors.selectState),
				this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
				this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
				this.store.select(this.featureFlagSelectors.selectFeatureFlags)
			),
			filter(([act, state, cpProgress, cpr, ff]) => !!ff),
			mergeMap(([act, state, cpProgress, cpr, ff]) => {
				const actions: Array<any> = new Array<any>();
				let customerSetupStep = 3;
				if (!state.activeState) {
					let steps = state.steps;
					const currentStep = cpProgress?.currentStep;
					let currentSubStep = cpProgress?.currentSubStep;

					if (ff?.some((f) => f.id === FeatureFlags.Onboarding_V1_0_2 && f.enabled)) {
						steps = state.steps.filter((s) => s.label !== "Financing Intentions");
						customerSetupStep = 2;
						steps[customerSetupStep].substeps = 14;
					}

					if (act.routeStepData.step! >= 2 || (!!currentStep && currentStep >= 2)) {
						actions.push(StepperActions.setSettingsDisplay({ display: true }));
					}

					if (currentStep === customerSetupStep && cpr?.currentSubStep) {
						currentSubStep = cpr!.currentSubStep;
					}

					for (let i = 0; i <= currentStep!; i++) {
						if (currentStep! > i) {
							// this step is fully complete
							this.updateStep(steps[i]);
						} else {
							// this is the current step, set percentage
							this.setPercentComplete(steps[i], currentSubStep!);
						}
					}
					if (act.routeStepData.step === undefined) {
						act.routeStepData = {
							step: currentStep,
							substep: currentSubStep
						};
					}
					const completedSteps = this.updateCompletedSteps(steps, currentStep!);
					state.completedSteps = completedSteps;
					actions.push(
						StepperActions.updateSteps({ steps }),
						StepperActions.setCompletedSteps({ steps: completedSteps }),
						StepperActions.setActiveState({ activeState: this.configureActiveState(act.routeStepData, state) })
					);
				}

				return actions;
			})
		)
	);

	initializeChildSteps$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StepperActions.initializeChildSteps),
			withLatestFrom(
				this.store.select(this.stepperSelectors.selectState),
				this.store.select(this.companyProfileSelectors.selectCompanyProfile),
				this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
				this.store.select(this.featureFlagSelectors.selectFeatureFlags)
			),
			filter(([act, state, cpProgress, cpr, ff]) => !!ff),
			mergeMap(([act, state, cp, cpr, ff]) => {
				const actions: Array<any> = new Array<any>();

				const steps = state.steps;
				let childSteps: LsStep[] = [];
				let progressData: IRouteStepData;
				let childProgress = 0;
				const isV102FlagEnabled = ff?.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)?.enabled;
				const customerSetupStep = isV102FlagEnabled ? 2 : 3;
				if (isV102FlagEnabled && steps[customerSetupStep].children !== null) {
					steps[customerSetupStep].children![4].substeps = 5;
					steps[customerSetupStep].children![4].route = "customer-setup/noa/{customerId}";
				}
				switch (act.childStepType) {
					case ChildStepType.CUSTOMER_SETUP:
						progressData = this.getCustomerSetupProgressData(
							isV102FlagEnabled!,
							customerSetupStep,
							cpr!.currentSubStep!
						);
						childSteps = steps[customerSetupStep].children!;
						this.resetChildProgress(childSteps);
						childProgress = cpr!.currentSubStep!;
						if (childProgress === steps[customerSetupStep].substeps) {
							const lastChild = childSteps[childSteps.length - 1];
							const secondLastChild = childSteps[childSteps.length - 2];

							this.updateStep(secondLastChild);
							this.updateStep(lastChild);
						}
						childSteps.forEach((step, indx) => {
							if (indx === 0) {
								step.route = cp?.isCustomersInProgress ? "customer-setup/review" : step.route;
							}

							if (indx === 2) {
								step.route =
									"customer-setup/" +
									(cpr?.isManualSetup ? "relationship/" : "currency-payment/") +
									cpr?.relationshipCompanyId;
							}
							if (indx !== 0 && indx !== childSteps.length - 1) {
								const routeArr = step.route.split("/");
								routeArr[routeArr.length - 1] = cpr!.relationshipCompanyId?.toString() ?? "";
								step.route = routeArr.join("/");
							}
						});
				}

				const progressStep = progressData!.section;
				const progressSubStep = progressData!.subSection;

				for (let i = 0; i <= progressStep!; i++) {
					if (progressStep! > i) {
						this.updateStep(childSteps[i]);
					} else {
						this.setPercentComplete(childSteps[i], Math.max(progressSubStep!, childProgress));
					}
					childProgress = Math.max(childProgress - childSteps[i].substeps, 0);
				}

				actions.push(
					StepperActions.updateSteps({ steps }),
					StepperActions.setActiveState({ activeState: this.configureActiveState(act.routeStepData, state) })
				);

				return actions;
			})
		)
	);

	updateCompletedSteps$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StepperActions.updateCompletedSteps),
			withLatestFrom(
				this.store.select(this.stepperSelectors.selectSteps),
				this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress)
			),
			mergeMap(([act, steps, progress]) => {
				return [StepperActions.setCompletedSteps({ steps: this.updateCompletedSteps(steps, progress!.currentStep!) })];
			})
		)
	);

	private configureActiveState(routeData: IRouteStepData, state: StepperState) {
		let activeState: ActiveState;

		if (
			routeData.section !== undefined &&
			state.steps[routeData.step!].childDisplayStep &&
			state.steps[routeData.step!].childDisplayStep! <= routeData.substep!
		) {
			const completedSteps = new Set<number>();
			const steps = state.steps[routeData.step!].children;
			steps
				?.filter((s, indx) => indx === 0 || steps[indx - 1].percentComplete === 100)
				.forEach((s, indx) => completedSteps.add(indx));
			activeState = {
				activeSteps: steps!,
				activeStep: routeData.section,
				activeSubstep: routeData.subSection!,
				activeCompletedSteps: completedSteps,
				childStepsActive: true,
				displaySettingsButton: state.displaySettings,
				displayNavBackButton: true,
				navBackName: state.steps[routeData.step!].label,
				parent: {
					activeSteps: state.steps,
					activeStep: routeData.step!,
					activeSubstep: routeData.substep!,
					activeCompletedSteps: state.completedSteps,
					childStepsActive: false,
					displaySettingsButton: state.displaySettings,
					displayNavBackButton: false
				}
			};
		} else {
			activeState = {
				activeSteps: state.steps,
				activeStep: routeData.step!,
				activeSubstep: routeData.substep!,
				activeCompletedSteps: state.completedSteps,
				childStepsActive: false,
				displaySettingsButton: state.displaySettings,
				displayNavBackButton: false
			};
		}

		return activeState;
	}

	private updateCompletedSteps(steps: LsStep[], stepCompleted: number): Set<number> {
		const stepsComplete = new Set<number>();
		steps
			.filter((step, indx) => indx === 0 || (step.percentComplete >= 0 && indx <= stepCompleted))
			.map((step, indx) => {
				stepsComplete.add(indx);
			});
		return stepsComplete;
	}

	private setPercentComplete(step: LsStep, activeSubStep: number): void {
		if (step.percentComplete !== 100) {
			step.completedSubsteps = Math.max(step.completedSubsteps ?? 0, activeSubStep);
			step.percentComplete = (step.completedSubsteps / (step.substeps ?? 1)) * 100;
		}
	}

	private updateStep(previousStep: LsStep) {
		previousStep.completedSubsteps = previousStep.substeps;
		previousStep.percentComplete = 100;
	}

	private getRouteStepData(isV102FlagEnabled: boolean, stepVal: number, substepVal: number): IRouteStepData {
		const routeStepData: Map<string, IRouteStepData> = isV102FlagEnabled ? RouteStepData_V102 : RouteStepData;
		return [...routeStepData.values()].filter(
			(routeStepData) => routeStepData.step === stepVal && routeStepData.substep === substepVal
		)[0];
	}

	private getCustomerSetupProgressData(isV102FlagEnabled: boolean, customerSetupStep: number, currentSubstep: number) {
		return this.getRouteStepData(isV102FlagEnabled, customerSetupStep, currentSubstep);
	}

	private resetChildProgress(childSteps: LsStep[]) {
		childSteps.forEach((step) => {
			if (!step.childStepAlwaysComplete) {
				step.completedSubsteps = 0;
				step.percentComplete = 0;
			}
		});
	}
}
