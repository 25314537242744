import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormControl, FormControlStatus, FormGroup, Validators } from "@angular/forms";
import { debounce, filter, map, takeUntil } from "rxjs/operators";
import { interval, Subject, take } from "rxjs";
import { Store } from "@ngrx/store";
import { AccountCreationActions } from "../../../OnboardingStateManagement";
import { AccountSubmissionForm, B2CQueryParams, CompanyProfile, EmailValidation, Phone } from "../../../../../Models";
import { Actions, ofType } from "@ngrx/effects";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { LocalStorageService } from "../../../../../../Utils";
import { MsalService } from "@azure/msal-angular";
import { RedirectRequest } from "@azure/msal-browser";
import { environment } from "../../../../../../environments/environment";
import { CompanyProfileActions } from "../../../../../AppStateManagement";
import { ValidationActions } from "src/app/Modules/COT-Module/OnboardingStateManagement/Validation/validation-actions";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-ca-info",
	templateUrl: "./create-account-info.component.html",
	styleUrls: ["./create-account-info.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class CreateAccountInfoComponent implements OnInit, OnDestroy {
	BUSINESS_NAME_CONTROL = "businessName";
	ANNUAL_REVENUE_CONTROL = "annualRevenue";
	FIRST_NAME_CONTROL = "firstName";
	LAST_NAME_CONTROL = "lastName";
	TITLE_CONTROL = "title";
	PHONE_CONTROL = "phone";
	BUSINESS_EMAIL_CONTROL = "businessEmail";
	TERMS_AGREEMENT_CONTROL = "termsAgreement";
	caForm: FormGroup = new FormGroup({});
	continueDisabled = true;
	isDuplicate = false;
	phoneValidationResult?: string;
	emailValidationResult?: string;
	formError = "";
	toggled = false;
	static id = "create-accounts";

	public annualRevenueRanges?: GenericLookup<string>[];
	private annualRevSelect?: HTMLElement;

	private componentTeardown$ = new Subject();

	constructor(
		private store: Store,
		private actions$: Actions,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private localStorageService: LocalStorageService,
		private authService: MsalService,
		public genericSelectors: GenericSelectors
	) {}

	ngOnInit() {
		const elements = document.getElementsByClassName("form-control select-input");
		if (elements.length > 0 && (elements[0] as HTMLElement).tagName === "INPUT") {
			this.annualRevSelect = elements[0] as HTMLElement;
		}

		this.store
			.select(this.genericSelectors.selectLookup(LookupTables.AnnualRevenueRange))
			.pipe(
				filter((arr) => !!arr && arr.length > 0),
				takeUntil(this.componentTeardown$),
				map((arr) => (this.annualRevenueRanges = arr!))
			)
			.subscribe();

		this.caForm = new FormGroup({
			businessName: new FormControl("", Validators.required),
			annualRevenue: new FormControl(null, Validators.required),
			firstName: new FormControl("", Validators.required),
			lastName: new FormControl("", Validators.required),
			title: new FormControl(null, Validators.required),
			phone: new FormControl(new Phone(), Validators.required),
			businessEmail: new FormControl("", Validators.required),
			termsAgreement: new FormControl(false, Validators.requiredTrue)
		});

		this.caForm.statusChanges
			.pipe(
				takeUntil(this.componentTeardown$),
				map((status: FormControlStatus) => {
					this.continueDisabled = status === "INVALID";
				})
			)
			.subscribe();

		this.caForm
			.get(this.BUSINESS_EMAIL_CONTROL)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				debounce(() => interval(250)),
				filter((val: string) => {
					const regex = new RegExp(/.+@.+\..{2,}/g);
					return val.length >= 6 && regex.test(val);
				}),
				map((email) => this.validateEmail())
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(ValidationActions.validationFailure),
				filter((act) => act.id === CreateAccountInfoComponent.id),
				map((action) => {
					const errorMap = action.result.errors;
					switch (action.validationType) {
						case "Email":
							if (errorMap.has("Email"))
								this.caForm.get(this.BUSINESS_EMAIL_CONTROL)?.setErrors({ error: errorMap.get("Email") });
							let emailErr = "";
							errorMap.forEach((errArray) => errArray.map((e) => (emailErr += e)));
							this.emailValidationResult = emailErr;
							break;
						case `${AccountCreationActions.ACCOUNT_SUBMISSION}/SAVE`:
							if (errorMap.has("FirstName")) {
								let fNameErr = "";
								errorMap.get("FirstName")!.forEach((err) => (fNameErr += ` ${err}`));
								this.caForm.get(this.FIRST_NAME_CONTROL)?.setErrors({ error: fNameErr });
							}
							if (errorMap.has("LastName")) {
								let lNameErr = "";
								errorMap.get("LastName")!.forEach((err) => (lNameErr += ` ${err}`));
								this.caForm.get(this.LAST_NAME_CONTROL)?.setErrors({ error: lNameErr });
							}
							if (errorMap.has("Title")) {
								let titleErr = "";
								errorMap.get("Title")!.forEach((err) => (titleErr += ` ${err}`));
								this.caForm.get(this.TITLE_CONTROL)?.setErrors({ error: titleErr });
							}
							if (errorMap.has("ApproximateAnnualRevenue")) {
								let revenueErr = "";
								errorMap.get("ApproximateAnnualRevenue")!.forEach((err) => (revenueErr += ` ${err}`));
								this.caForm.get(this.ANNUAL_REVENUE_CONTROL)?.setErrors({ error: revenueErr });
							}
							if (errorMap.has("Duplicate Account")) {
								this.formError = errorMap.get("Duplicate Account")![0];
								this.isDuplicate = true;
								this.observeUntilChanged();
							}
							break;
						default:
							throw new Error("Unknown validation type.");
					}
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileActions.setCompanyProfile),
				map((action) => {
					const cp: CompanyProfile = action.companyProfile;
					this.loginRedirect(false, cp.companyId, cp.users![0].emailAddress);
				})
			)
			.subscribe();

		this.checkForErrorState();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	checkForErrorState() {
		this.activatedRoute.queryParams
			.pipe(
				take(1),
				map((params: Params) => {
					if (params["error"]) {
						this.formError =
							"We already have an account associated with this email. Please try with a different email or <strong>log in</strong>.";
					}
				})
			)
			.subscribe();
	}

	controlHasError(controlName: string): boolean {
		if (this.caForm.get(controlName)) {
			return (
				this.caForm.get(controlName)!.invalid &&
				(this.caForm.get(controlName)!.dirty || this.caForm.get(controlName)!.touched)
			);
		}
		return false;
	}

	getControlError(controlName: string): string {
		return this.caForm.get(controlName)?.errors!["error"];
	}

	submit() {
		const phone: Phone = this.caForm.get(this.PHONE_CONTROL)?.value;
		const account = new AccountSubmissionForm(
			this.caForm.get(this.BUSINESS_NAME_CONTROL)?.value,
			this.caForm.get(this.ANNUAL_REVENUE_CONTROL)?.value,
			this.caForm.get(this.FIRST_NAME_CONTROL)?.value,
			this.caForm.get(this.LAST_NAME_CONTROL)?.value,
			this.caForm.get(this.TITLE_CONTROL)?.value,
			phone.countryCode,
			phone.number,
			phone.extension,
			this.caForm.get(this.BUSINESS_EMAIL_CONTROL)?.value,
			this.caForm.get(this.TERMS_AGREEMENT_CONTROL)?.value
		);
		this.store.dispatch(AccountCreationActions.createAccount({ account }));
	}

	loginRedirect(isLogin = true, companyId?: number, email?: string) {
		this.localStorageService.setB2CSignupState(isLogin);
		const rr: RedirectRequest = {
			scopes: environment.scopes,
			extraQueryParameters: new B2CQueryParams(
				companyId,
				email,
				this.localStorageService.getB2CSignupState()
			).toParamsObject(),
			redirectStartPage: window.origin + "/onboarding/begin-application"
		};
		this.authService.loginRedirect(rr);
	}

	validateEmail() {
		const emailValidation = new EmailValidation(this.caForm.get(this.BUSINESS_EMAIL_CONTROL)?.value);
		this.store.dispatch(ValidationActions.validateEmail({ id: CreateAccountInfoComponent.id, email: emailValidation }));
	}

	observeUntilChanged() {
		const valueChanged$ = new Subject();
		this.caForm
			.get(this.BUSINESS_NAME_CONTROL)!
			.valueChanges.pipe(
				takeUntil(valueChanged$),
				map(() => {
					this.isDuplicate = false;
					valueChanged$.next(null);
					valueChanged$.complete();
				})
			)
			.subscribe();

		this.caForm
			.get(this.BUSINESS_EMAIL_CONTROL)!
			.valueChanges.pipe(
				takeUntil(valueChanged$),
				map(() => {
					this.isDuplicate = false;
					valueChanged$.next(null);
					valueChanged$.complete();
				})
			)
			.subscribe();
	}

	handleClose() {
		setTimeout(() => this.annualRevSelect?.blur(), 1);
	}
}
