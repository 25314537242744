import { Component, Input, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { FeatureFlagSelectors, FeatureFlags } from "@limestone/ls-shared-modules";
import { Store } from "@ngrx/store";
import { Subject, filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";

@Component({
	selector: "ls-application-contact-info",
	templateUrl: "./application-contact-info.component.html",
	styleUrls: ["./application-contact-info.component.scss"]
})
export class ApplicationContactInfoComponent implements OnDestroy {
	private componentTeardown$: Subject<any> = new Subject<any>();
	private _companyProfileRelationship?: CompanyProfileRelationship;
	@Input() set companyProfileRelationship(companyProfileRelationship: CompanyProfileRelationship | undefined) {
		if (companyProfileRelationship) {
			this._companyProfileRelationship = companyProfileRelationship;
			this.hideAccountsPayableContactForm = !companyProfileRelationship?.accountsPayableContact;
			this.setFormGroup();
		}
	}
	get companyProfileRelationship(): CompanyProfileRelationship | undefined {
		return this._companyProfileRelationship;
	}
	@Input() readOnly = true;
	public customerContactForm: FormGroup = new FormGroup({});
	public static FORM_CONTROL_NAME = "contactInfo";
	public LEGAL_NAME = "legalName";
	public BILLING = "billing";
	public PRIMARY_CONTACT = "primaryContact";
	public ACCOUNTS_PAYABLE_CONTACT = "accountsPayableContact";
	public HAS_ACCOUNTS_PAYABLE_CONTACT = "hasAccountsPayableContact";
	public hideAccountsPayableContactForm = true;
	public isV1_0_2_FlagEnabled = false;

	constructor(
		private fgd: FormGroupDirective,
		private _store: Store,
		private _featureFlagSelectors: FeatureFlagSelectors
	) {
		this._store
			.select(this._featureFlagSelectors.selectFeatureFlags)
			.pipe(
				filter((ff) => !!ff),
				takeUntil(this.componentTeardown$),
				map((ff) => {
					this.isV1_0_2_FlagEnabled = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!.enabled;
					if (this.isV1_0_2_FlagEnabled) {
						this.customerContactForm = new FormGroup({
							legalName: new FormControl(this.companyProfileRelationship?.relationshipCompanyName, Validators.required),
							billing: new FormControl(this.companyProfileRelationship?.billingAddress, Validators.required)
						});
					} else {
						this.customerContactForm = new FormGroup({
							legalName: new FormControl(this.companyProfileRelationship?.relationshipCompanyName, Validators.required),
							billing: new FormControl(this.companyProfileRelationship?.billingAddress, Validators.required),
							hasAccountsPayableContact: new FormControl(!!this.companyProfileRelationship?.accountsPayableContact),
							primaryContact: new FormControl(this.companyProfileRelationship?.primaryContact, Validators.required),
							accountsPayableContact: new FormControl({
								value: this.companyProfileRelationship?.accountsPayableContact ?? null,
								disabled: this.hideAccountsPayableContactForm
							})
						});
					}
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	setFormGroup() {
		const address = this.companyProfileRelationship?.billingAddress ?? null;
		if (this.isV1_0_2_FlagEnabled) {
			this.customerContactForm.patchValue(
				{
					legalName: this.companyProfileRelationship?.relationshipCompanyName,
					billing: address
				},
				{ onlySelf: true, emitEvent: false }
			);
		} else {
			this.customerContactForm.patchValue(
				{
					legalName: this.companyProfileRelationship?.relationshipCompanyName,
					billing: address,
					hasAccountsPayableContact: !!this.companyProfileRelationship?.accountsPayableContact,
					primaryContact: this.companyProfileRelationship?.primaryContact,
					accountsPayableContact: this.companyProfileRelationship?.accountsPayableContact ?? null
				},
				{ onlySelf: true, emitEvent: false }
			);
			this.enabledACP(this.hideAccountsPayableContactForm);

			this.customerContactForm
				.get(this.HAS_ACCOUNTS_PAYABLE_CONTACT)
				?.valueChanges.pipe(
					takeUntil(this.componentTeardown$),
					map((value) => {
						this.hideAccountsPayableContactForm = !value;
						this.enabledACP(!value);
					})
				)
				.subscribe();
		}

		if (this.fgd.form.get(ApplicationContactInfoComponent.FORM_CONTROL_NAME) === null) {
			this.fgd.form?.addControl(ApplicationContactInfoComponent.FORM_CONTROL_NAME, this.customerContactForm, {
				emitEvent: false
			});
		}

		this.customerContactForm.markAsPristine();
	}

	private enabledACP(value: boolean) {
		if (value) {
			this.customerContactForm.get(this.ACCOUNTS_PAYABLE_CONTACT)?.disable({ onlySelf: true, emitEvent: false });
		} else {
			this.customerContactForm.get(this.ACCOUNTS_PAYABLE_CONTACT)?.enable({ onlySelf: true, emitEvent: false });
		}
	}
}
