<ls-questionnaire #questionnaireForm (nextButtonClicked)="continue()" [isNextButtonDisabled]="isFormInvalid()" [breadcrumbs]="breadcrumbs">
    <h3 class="ls-mb-8">Tell us more about your relationship with your customer</h3>
    <form [formGroup]="customerRelationship3Form" class="d-flex flex-column">
        <div *ngIf="!isV1_0_2_FlagEnabled" class="d-flex flex-column ls-mb-8">
            <p class="title m ls-mb-3">What is your average invoice amount with this customer?</p>
            <mdb-form-control class="half-max-width">
                <mdb-select [formControlName]="ONBOARD_INSTRUMENT_RANGE" [id]="ONBOARD_INSTRUMENT_RANGE" mdbValidate
                            [optionHeight]="56" class="form-control form-control-lg">
                    <mdb-option *ngFor="let onboardInstrumentRange of onboardInstrumentRanges" [value]="onboardInstrumentRange.id">{{ currencyCode + ' ' + onboardInstrumentRange.name }}</mdb-option>
                </mdb-select>
                <label mdbLabel class="form-label" [for]="ONBOARD_INSTRUMENT_RANGE">Invoice amount</label>
            </mdb-form-control>
        </div>
        <div  class="d-flex flex-column ls-mb-8">
            <p class="title m ls-mb-3">What are your approximate annual sales with this customer?</p>
            <mdb-form-control class="half-max-width">
                <mdb-select [formControlName]="ONBOARD_REVENUE_RANGE" [id]="ONBOARD_REVENUE_RANGE" mdbValidate
                            [optionHeight]="56" class="form-control form-control-lg">
                    <mdb-option *ngFor="let onboardRevenueRange of onboardRevenueRanges" [value]="onboardRevenueRange.id">{{ currencyCode + ' ' + onboardRevenueRange.name }}</mdb-option>
                </mdb-select>
                <label mdbLabel class="form-label" [for]="ONBOARD_REVENUE_RANGE">Amount</label>
            </mdb-form-control>
        </div>
        <div *ngIf="!isV1_0_2_FlagEnabled" class="d-flex flex-column">
            <p class="title m ls-mb-3">What is your total accounts receivable balance with this customer?</p>
            <mdb-form-control class="half-max-width">
                <mdb-select [formControlName]="ACCOUNTS_RECEIVABLE_REVENUE_RANGE" [id]="ACCOUNTS_RECEIVABLE_REVENUE_RANGE"
                            [optionHeight]="56" mdbValidate  class="form-control form-control-lg">
                    <mdb-option *ngFor="let accountsReceivableRevenueRange of accountsReceivableRevenueRanges" [value]="accountsReceivableRevenueRange.id">{{ currencyCode + ' ' + accountsReceivableRevenueRange.name }}</mdb-option>
                </mdb-select>
                <label mdbLabel class="form-label" [for]="ACCOUNTS_RECEIVABLE_REVENUE_RANGE">Amount</label>
            </mdb-form-control>
        </div>
    </form>
</ls-questionnaire>