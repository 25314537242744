<form [formGroup]="formGroup" class="d-flex flex-column">
  <div class="ls-mb-3">
    <ng-content></ng-content>
  </div>
  <div class="grid gap-3">
    <div class="platform g-col-12 g-col-md-6" *ngFor="let p of platforms">
      <div class="d-flex flex-row align-items-center">
        <input mdbRadio [formControlName]="PLATFORM" class="radio platform-input" type="radio" 
               [name]="PLATFORM" [id]="p.id" [value]="p.id"/>
        <label class="xl platform-label" [for]="p.id">{{ p.name }}</label>
      </div>
    </div>
  </div>
  <div [class.ls-mt-4]="!hideOtherPlatformInput" [hidden]="hideOtherPlatformInput">
    <mdb-form-control>
      <input mdbInput [formControlName]="OTHER_PLATFORM_INPUT" mdbValidate type="text" [maxlength]="maxLength" id="OTHER_PLATFORM_INPUT" class="form-control form-control-lg" />
      <label mdbLabel class="form-label" for="OTHER_PLATFORM_INPUT">Specify</label>
      <div class="form-helper">
        <div class="form-counter">{{ characterCount }} / {{ maxLength }}</div>
      </div>
    </mdb-form-control>
  </div>
</form>