import { LsAction } from "../Core";
import { createAction, props } from "@ngrx/store";
import { ActionType } from "../Core/LsAction";
import { LsUserRole, UserInfo } from "../Models";

export abstract class AppActions extends LsAction {
	/* eslint-disable */
  static USER_ROLES: Readonly<string> = 'USER_ROLES';
  static USER_INFO: Readonly<string> = 'USER_INFO';  
    /* eslint-enable */

	static setUserRole = createAction(
		AppActions.getActionName(AppActions.USER_ROLES, ActionType.SET),
		props<{ userRole: LsUserRole }>()
	);

	static getUserInfo = createAction(AppActions.getActionName(AppActions.USER_INFO, ActionType.GET));
	static setUserInfo = createAction(
		AppActions.getActionName(AppActions.USER_INFO, ActionType.SET),
		props<{ userInfo: UserInfo }>()
	);

	static updateUserInfo = createAction(
		AppActions.getActionName(AppActions.USER_INFO, ActionType.UPDATE),
		props<{ userInfo: UserInfo }>()
	);

	static userInfoSaveSuccessful = createAction(AppActions.getActionName(AppActions.USER_INFO, ActionType.COMPLETE));

	static userInfoSaveUnsuccessful = createAction(
		AppActions.getActionName(AppActions.USER_INFO, ActionType.ERROR),
		props<{ errors: string[] }>()
	);
}
