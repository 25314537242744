import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { filter, map, Observable, takeUntil } from "rxjs";
import { CompanyProfile } from "src/app/Models";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import {
	AddressType,
	CountryCode,
	FeatureFlags,
	FeatureFlagSelectors,
	GenericSelectors,
	StateProvince
} from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { IRouteStepData } from "src/app/Models/Interfaces";
import { combineLatest } from "rxjs";
@Component({
	selector: "ls-state-of-formation",
	templateUrl: "./state-of-formation.component.html",
	styleUrls: ["./state-of-formation.component.scss"]
})
export class StateOfFormationComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store,
		private companyProfileSelectors: CompanyProfileSelectors,
		private genericSelectors: GenericSelectors,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private featureFlagSelectors: FeatureFlagSelectors
	) {
		super(store);
		this.form.addControl(
			this.STATE_OF_FORMATION,
			new FormControl(this.companyProfile?.formationStateCode, Validators.required),
			{ emitEvent: false }
		);
	}

	public STATE_OF_FORMATION = "stateOfFormation";
	public stateProvinces: StateProvince[] = [];
	public isV1_0_2_FlagEnabled = false;

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		updatedCompanyProfile.formationStateCode = this.form.get(this.STATE_OF_FORMATION)?.value;
		this.emitSave(updatedCompanyProfile);
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute });
	}

	init(): Observable<CompanyProfile | undefined> {
		return combineLatest([
			this.store.select(this.featureFlagSelectors.selectFeatureFlags),
			this.store.select(this.genericSelectors.selectStateProvinces),
			this.store.select(this.companyProfileSelectors.selectCompanyProfile)
		]).pipe(
			filter(([ff, states, cp]) => !!ff && !!states && !!cp),
			takeUntil(this.componentTeardown$),
			map(([ff, states, cp]) => {
				this.isV1_0_2_FlagEnabled = ff!.find((ff) => ff.id === FeatureFlags.Onboarding_V1_0_2)!.enabled;
				const primaryAddress = cp!.addresses?.find(
					(companyAddress) => companyAddress.addressType?.id === AddressType.PRIMARY
				);

				if (this.isV1_0_2_FlagEnabled) {
					this.stateProvinces = states!.filter((s) => s.country === primaryAddress?.address?.country?.code);
				} else {
					this.stateProvinces = states!.filter((s) => s.country === CountryCode.US);
				}
				this.companyProfile = cp;
				this.form.get(this.STATE_OF_FORMATION)?.setValue(cp?.formationStateCode, Validators.required),
					{ emitEvent: false };
				return cp;
			})
		);
	}
}
