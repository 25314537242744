import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: "ls-begin-application",
	templateUrl: "./begin-application.component.html",
	styleUrls: ["./begin-application.component.scss"]
})
export class BeginApplicationComponent {
	public expectations: Expectation[] = [
		{
			icon: "edit_note",
			title: "We’ll need some basic company info",
			details:
				"We’ll ask questions to get to know your business and your customers. In total, the process should take no longer than 10 to 15 minutes, having you on your way to funding in no time at all!"
		},
		{
			icon: "verified",
			title: "Raistone’s quick application review",
			details:
				"Once you’ve provided all your information, the Raistone team will immediately get to work. A member of our team will get back to you within one business day to discuss next steps."
		},
		{
			icon: "support_agent",
			title: "We’re here to assist!",
			details:
				"Need help along the way? No problem! A member of the Raistone support team is standing by to help at any time by clicking “Chat with an Expert” at the bottom right of the screen."
		}
	];
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute
	) {}

	onClick() {
		this.router.navigate(["../connect-financials/introduction"], { relativeTo: this.activatedRoute });
	}
}

interface Expectation {
	icon: string;
	title: string;
	details: string;
}
