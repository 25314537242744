import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from "./b2c-configuration";
import { ISFChatbot, ISFChatbotConfig } from "../app/Models/Interfaces";

const scopes = ["https://raistoneb2c.onmicrosoft.com/api-ls-qa/user_impersonation"];

const salesforceChatbotConfig: ISFChatbotConfig = {
	baseLiveAgentContentURL: "https://c.la3-c1cs-ia4.salesforceliveagent.com/content",
	deploymentId: "5721I0000001L5g",
	buttonId: "5737d000000Cjah",
	baseLiveAgentURL: "https://d.la3-c1cs-ia4.salesforceliveagent.com/chat",
	eswLiveAgentDevName: "Customer_Support",
	isOfflineSupportEnabled: false
};

const saleforceChatbot: ISFChatbot = {
	url: "https://raistone--qa.sandbox.my.salesforce.com",
	siteUrl: "https://raistone--qa.sandbox.my.site.com/",
	serviceUrl: "https://service.force.com",
	id: "00D7d0000098eFC",
	eswLiveAgentDevName: "Customer_Support",
	config: salesforceChatbotConfig
};

export const environment = {
	production: false,
	scopes,
	limestoneHost: "https://qa.backend.raistoneapi.com",
	MsalInstanceConfig: MSALInstanceFactory("ae7dbda8-9d7e-4fd9-95f2-a2310d697973", "B2C_1A_ALD_QA"),
	MsalInterceptorConfig: MSALInterceptorConfigFactory("https://qa.backend.raistoneapi.com", scopes),
	MsalGuardConfig: MSALGuardConfigFactory(scopes),
	pendoEnabled: false,
	environmentName: "QA",
	supportEmailAddress: "QA-Support@raistone.com",
	salesforceChatbot: saleforceChatbot
};
