import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject, filter, map, takeUntil, combineLatest } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";
import { CompanyProfileRelationshipSelectors, CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { CurrencyPipe } from "@angular/common";
import {
	CurrencyCode,
	FeatureFlagSelectors,
	FeatureFlags,
	OnboardApplicationStatus
} from "@limestone/ls-shared-modules";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-review",
	templateUrl: "./review.component.html",
	styleUrls: ["./review.component.scss"]
})
export class ReviewComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	companyProfileRelationships?: CompanyProfileRelationship[];
	hasEnrolledCustomers = true;
	private addCustomerPath?: string;
	public isV1_0_2_FlagEnabled?: boolean;

	constructor(
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public featureFlagSelectors: FeatureFlagSelectors,
		private currencyPipe: CurrencyPipe
	) {}

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				filter(([cp, cprs, ff]) => !!cp && !!cprs && !!ff),
				takeUntil(this.componentTeardown$),
				map(([cp, cprs, ff]) => {
					this.isV1_0_2_FlagEnabled = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!.enabled;
					this.addCustomerPath = cp?.isAccountingPlatformConnected ? "../customer-list" : "../manual";
					this.companyProfileRelationships = cprs?.filter(
						(cpr) => cpr.applicationStatusId === OnboardApplicationStatus.INPROGRESS
					);
					this.hasEnrolledCustomers =
						this.companyProfileRelationships?.find((cpr) => cpr.isCustomerSetupComplete) !== undefined;
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	navToCustomerContact(id: number) {
		this.router.navigate([`../customer-contact/${id}`], { relativeTo: this.activatedRoute });
	}

	addAnotherCustomer() {
		this.router.navigate([this.addCustomerPath], { relativeTo: this.activatedRoute });
	}

	getRequestedCreditLimit(cpr: CompanyProfileRelationship): string {
		const currencyInfo = cpr.isOtherCurrency ? CurrencyCode.USD : cpr.currencyCode;
		return `${currencyInfo} ${this.currencyPipe.transform(
			cpr.requestedCreditLimit,
			currencyInfo,
			"symbol-narrow",
			"1.0-0"
		)} requested`;
	}

	continue() {
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute });
	}
}
