import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FileHandle } from "./FileHandle";
import { FileUploadService } from "./file-upload.service";
import { FileUploadError } from "../../app/Models/Interfaces";

@Component({
	selector: "ls-upload-widget",
	templateUrl: "./limestone-element-upload-widget.component.html",
	styleUrls: ["./limestone-element-upload-widget.component.scss"]
})
export class LimestoneElementUploadWidgetComponent {
	@Input() maxFileCount = 10;
	@Input() maxFileSize?: number;
	@Input() allowedFileExtensions?: string[];
	@Input() disabled = false;
	@Output() files: EventEmitter<FileHandle[]> = new EventEmitter();
	@Output() fileUploadError: EventEmitter<FileUploadError> = new EventEmitter<FileUploadError>();
	@ViewChild("upload") uploadInput!: ElementRef;

	constructor(private fileUploadService: FileUploadService) {}
	filesDropped(files: FileHandle[]): void {
		this.files.emit(files);
	}

	handleFiles(ev: EventTarget | null) {
		// @ts-ignore
		const files = ev["files"];
		const fileHandles = this.fileUploadService.convert(files);
		if (files.length > 0) {
			this.files.emit(fileHandles);
		}
	}

	disableLabelClick(event: Event) {
		event.stopPropagation();
	}

	openInput(event: Event) {
		event.stopPropagation();
		this.uploadInput.nativeElement.click();
	}

	clearInput() {
		this.uploadInput.nativeElement.value = null;
	}
}
