<div class="d-flex flex-column ls-mt-8 ls-mb-8">
  <form [formGroup]="companyProfileForm">
    <div class="ls-mb-3">
      <label class="s-caps">BUSINESS NAMES</label>
    </div>
    <mdb-form-control class="legal-name ls-mb-4 ls-w-md-50">
      <input mdbInput [formControlName]="LEGAL_NAME" mdbValidate type="text" id="legalName"
             class="form-control form-control-lg"/>
      <label mdbLabel class="form-label" for="legalName">Legal Company Name</label>
    </mdb-form-control>
    <div class="ls-mb-8" *ngIf="!_isV1_0_2_FlagEnabled">
      <ls-business-names  [formControlName]="DBA_NAMES"></ls-business-names>
    </div>
    <div class="ls-mb-8 ls-w-md-50">
      <div class="ls-mb-3">
        <label class="s-caps">OFFICE PHONE NUMBER</label>
      </div>
      <ls-phone-form-control [formControlName]="PHONE" ></ls-phone-form-control>
    </div>
    <div class="ls-mb-7 ls-w-md-50">
      <div class="ls-mb-3">
        <label class="s-caps">PRIMARY ADDRESS</label>
      </div>
      <ls-address-form-control [formControlName]="PRIMARY"></ls-address-form-control>
    </div>
    <ng-container *ngIf="!_isV1_0_2_FlagEnabled">
      <div class="ls-mb-3 ls-w-md-50">
        <input
            mdbCheckbox
            class="form-check-input"
            type="checkbox"
            value=""
            id="billingAddressCheck"
            [checked]="toggled"
            (click)="toggleBillingForm()"
            />
        <label class="form-check-label xl" for="billingAddressCheck">
            Different billing address
        </label>
      </div>
      <div [class.d-none]="!toggled" class="ls-w-md-50">
        <ls-address-form-control [formControlName]="BILLING"></ls-address-form-control>
      </div>
    </ng-container>
  </form>
</div>
