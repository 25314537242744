import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable } from "rxjs";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { CompanyProfile } from "../../../../../Models";
import { FeatureFlags, FeatureFlagSelectors } from "@limestone/ls-shared-modules";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-company-name",
	templateUrl: "./company-name.component.html",
	styleUrls: ["./company-name.component.scss"]
})
export class CompanyNameComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private companyProfileSelectors: CompanyProfileSelectors,
		private ffSelectors: FeatureFlagSelectors
	) {
		super(store);
	}

	LEGAL_NAME = "legalName";
	DBA_NAMES = "dbaNames";

	radioClicked = false;
	companyNameIsLegalName = false;
	confirmedLegalName = false;
	isV102Enabled = false;
	init(): Observable<CompanyProfile | undefined> {
		return combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
			this.store.select(this.ffSelectors.selectFeatureFlags)
		]).pipe(
			filter(([cp, prog, ff]) => !!cp && !!prog && !!ff),
			map(([cp, prog, ff]) => {
				const f = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_2)!;
				this.isV102Enabled = f.enabled;
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				if (prog!.currentStep! > 1 || (prog!.currentStep! === 1 && prog!.currentSubStep! > 2)) {
					this.legalName(true);
					this.confirmedLegalName = true;
				}
				this.companyProfile = cp;
				this.form = new FormGroup({
					legalName: new FormControl(this.companyProfile?.legalBusinessName, Validators.required),
					dbaNames: new FormControl(this.companyProfile?.dbaNames)
				});
				return cp;
			})
		);
	}

	legalName(isLegalName: boolean) {
		this.radioClicked = true;
		this.companyNameIsLegalName = isLegalName;
		if (isLegalName) {
			this.form.get(this.LEGAL_NAME)?.setValue(this.companyProfile?.legalBusinessName);
		} else {
			this.form.get(this.LEGAL_NAME)?.setValue(null);
		}
	}

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		if (!this.companyNameIsLegalName) {
			updatedCompanyProfile.legalBusinessName = this.form.get(this.LEGAL_NAME)?.value;
		}
		const dbaControl = this.form.get(this.DBA_NAMES);
		if (dbaControl?.dirty) updatedCompanyProfile.dbaNames = dbaControl.value.dbaNames;
		this.emitSave(updatedCompanyProfile);
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute, replaceUrl: true });
	}

	isInvalid() {
		return !this.radioClicked || this.form.invalid;
	}
}
