import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { map, takeUntil } from "rxjs/operators";
import { filter, Subject, take } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import { CompanyProfileRelationship } from "../../../../Models";
import { QuestionnaireComponent } from "../../../../Core";
import { CompanyProfileRelationshipActions, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { Breadcrumb } from "../../../../Models/Interfaces/Breadcrumb";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({ selector: "ls-cpr", template: "" })
export abstract class CustomerRelationshipBaseComponent implements OnDestroy {
	public componentTeardown$ = new Subject();
	companyProfileRelationship?: CompanyProfileRelationship;
	public breadcrumbs?: Array<Breadcrumb> = [];
	childForm?: QuestionnaireComponent;

	protected constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public store: Store,
		public actions$: Actions,
		public companyProfileSelectors: CompanyProfileSelectors
	) {
		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileRelationshipActions.companyProfileRelationshipSaveSuccessful),
				filter(() => this.companyProfileRelationship?.relationshipCompanyId !== undefined),
				map(() => {
					this.navigateToNextScreen();
					this.childForm!.isNextButtonDisabled = false;
				})
			)
			.subscribe();

		this.store
			.select(this.companyProfileSelectors.selectCompanyProfile)
			.pipe(
				take(1),
				map((cp) => {
					const path = cp?.isCustomersInProgress ? "review" : "manual";
					this.breadcrumbs = [{ routerPath: `/onboarding/customer-setup/${path}`, displayText: "Customer Setup" }];
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileRelationshipActions.companyProfileRelationshipSaveUnsuccessful),
				map(() => {
					this.childForm!.isNextButtonDisabled = false;
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public abstract continue(): void;

	public navigateToNextScreen() {
		const data = this.activatedRoute.snapshot.data as IRouteStepData;
		this.router.navigate([data.nextUrl! + this.companyProfileRelationship?.relationshipCompanyId], {
			relativeTo: this.activatedRoute
		});
	}

	public abstract initData(): void;
}
