import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { filter, map, Subject, take, takeUntil } from "rxjs";
import { CompanyProfile } from "src/app/Models";
import { BankAccountPersuasionComponent } from "../../../Modals";
import { CodatActions, CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { ModalActions } from "../../../../../Core";
import { IRouteStepData } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-link-bank-account",
	templateUrl: "./link-bank-account.component.html",
	styleUrls: ["./link-bank-account.component.scss"]
})
export class LinkBankAccountComponent implements OnInit {
	protected componentTeardown$ = new Subject();
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public store: Store,
		public companyProfileSelectors: CompanyProfileSelectors,
		private dialog: MdbModalService
	) {}

	companyProfile?: CompanyProfile;
	path = "onboarding/connect-financials/banking-success";

	ngOnInit() {
		this.store
			.select(this.companyProfileSelectors.selectCompanyProfile)
			.pipe(
				filter((cp) => !!cp),
				takeUntil(this.componentTeardown$),
				map((cp) => {
					this.companyProfile = cp;
				})
			)
			.subscribe();
	}

	connectAccounts() {
		this.store.dispatch(
			CodatActions.createConnection({ companyProfile: this.companyProfile!, redirectPath: this.path })
		);
	}

	openModal() {
		this.dialog
			.open(BankAccountPersuasionComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true
			})
			.onClose.pipe(
				take(1),
				map((action: ModalActions) => {
					if (action === ModalActions.PRIMARY) {
						this.store.dispatch(
							CodatActions.createConnection({ companyProfile: this.companyProfile!, redirectPath: this.path })
						);
					} else {
						const data = this.activatedRoute.snapshot.data as IRouteStepData;
						this.router.navigate([data.nextUrl], { relativeTo: this.activatedRoute });
					}
				})
			)
			.subscribe();
	}
}
