import { Component, OnDestroy, OnInit } from "@angular/core";
import { filter, map, Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { UserInfo } from "../../../../Models";
import { Store } from "@ngrx/store";
import { AppSelectors } from "../../../../AppStateManagement";
@Component({
	selector: "ls-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public store: Store,
		public appSelectors: AppSelectors
	) {}

	public userInfo?: UserInfo;

	ngOnInit() {
		this.store
			.select(this.appSelectors.selectUserInfo)
			.pipe(
				takeUntil(this.componentTeardown$),
				filter((userInfo) => !!userInfo),
				map((userInfo) => (this.userInfo = userInfo))
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	navToApplications() {
		this.router.navigate([`applications`], { relativeTo: this.activatedRoute });
	}
}
