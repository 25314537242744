import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { filter, takeUntil, map } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { CurrencyCode } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-requested-financing",
	templateUrl: "./requested-financing.component.html",
	styleUrls: ["./requested-financing.component.scss"]
})
export class RequestedFinancingComponent extends CustomerRelationshipBaseComponent {
	companyProfileRelationship?: CompanyProfileRelationship;
	requestedCreditLimit = 0;
	currencyCode = CurrencyCode.USD;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors
	) {
		super(router, activatedRoute, store, actions$, companyProfileSelectors);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.currencyCode = !cpr!.isOtherCurrency ? cpr!.currencyCode! : this.currencyCode;
					this.requestedCreditLimit = cpr!.requestedCreditLimit!;
					if (cpr?.relationshipCompanyName && this.breadcrumbs && this.breadcrumbs.length < 2)
						this.breadcrumbs.push({ routerPath: "", displayText: cpr?.relationshipCompanyName ?? "" });
				})
			)
			.subscribe();
	}

	onRangeValueChange(rangeValue: number) {
		this.requestedCreditLimit = rangeValue;
	}

	continue() {
		if (this.requestedCreditLimit != this.companyProfileRelationship?.requestedCreditLimit) {
			const selectedFinancingAmount = this.requestedCreditLimit;
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.requestedCreditLimit = selectedFinancingAmount;

			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
}
