import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { UserInfo } from "../app/Models";
import { HttpService } from "./HTTP";

@Injectable()
export class AppService extends HttpService {
	constructor(private http: HttpClient) {
		super(http);
		this.path = "/api/userroles/user-info";
	}

	getUserInfo(): Observable<UserInfo> {
		const url = `${this.path}`;
		return this.http
			.get<UserInfo>(url)
			.pipe(
				map(
					(resp: UserInfo) =>
						new UserInfo(
							resp.b2cId,
							resp.contactId,
							resp.firstName,
							resp.lastName,
							resp.phone?.countryCode,
							resp.phone?.number,
							resp.phone?.extension,
							resp.emailAddress,
							resp.contactType
						)
				)
			);
	}

	updateUserInfo(userInfo: UserInfo): Observable<UserInfo> {
		const url = `${this.path}`;
		return this.http
			.put<any>(url, userInfo)
			.pipe(
				map(
					(resp: any) =>
						new UserInfo(
							resp.b2cId,
							resp.contactId,
							resp.firstName,
							resp.lastName,
							resp.phone?.countryCode,
							resp.phone?.number,
							resp.phone?.extension,
							resp.emailAddress
						)
				)
			);
	}
}
