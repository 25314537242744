<form class="d-flex flex-column" [formGroup]="formGroup">
  <div class="number d-flex flex-row">
    <mdb-form-control class="country-code-control ls-mr-3">
      <span class="country-code-icon material-icons" [hidden]="!codeIconToggled" id="add-icon">add</span>
      <input [formControlName]="COUNTRY_CODE" min="0" (focus)="toggleCode()" (blur)="toggleCode()" mdbInput mdbValidate type="string" [id]="COUNTRY_CODE" class="form-control form-control-lg" />
      <label mdbLabel class="form-label" [for]="COUNTRY_CODE">Code</label>
    </mdb-form-control>
    <mdb-form-control class="ls-mr-3 w-100">
      <input [formControlName]="PHONE_NUMBER" min="8" mdbInput mdbValidate type="tel" [id]="PHONE_NUMBER" class="form-control form-control-lg" />
      <label mdbLabel class="form-label" [for]="PHONE_NUMBER">Phone Number</label>
    </mdb-form-control>
    <mdb-form-control>
      <input [formControlName]="PHONE_EXT" min ="0" mdbInput mdbValidate type="tel" [id]="PHONE_EXT" class="form-control form-control-lg" (keydown)="onKeyDown($event)" />
      <label mdbLabel class="form-label" [for]="PHONE_EXT">Ext</label>
    </mdb-form-control>
  </div>
  <div *ngIf="phoneValidationResult" id="phoneValidationError" class="form-text validation-error">{{phoneValidationResult}}</div>
</form>
