import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";
import { filter, forkJoin, map, Observable, take } from "rxjs";
import { GenericActions, GenericSelectors, LookupTables, SortDirection } from "@limestone/ls-shared-modules";

@Injectable()
export class GenericDataResolver {
	constructor(
		private store: Store<any>,
		private genericSelectors: GenericSelectors
	) {}

	existingFinances$ = this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardExistingFinance)).pipe(
		filter((ef) => !!ef && ef.length > 0),
		take(1)
	);

	financeRanges$ = this.store.select(this.genericSelectors.selectLookup(LookupTables.FinanceRange)).pipe(
		filter((fr) => !!fr && fr.length > 0),
		take(1)
	);

	financeFrequencies$ = this.store.select(this.genericSelectors.selectLookup(LookupTables.FinanceFrequency)).pipe(
		filter((ff) => !!ff && ff.length > 0),
		take(1)
	);
	financePurpose$ = this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardFinancePurpose)).pipe(
		filter((fp) => !!fp && fp.length > 0),
		take(1)
	);

	financeUrgencies$ = this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardFinanceUrgency)).pipe(
		filter((f) => !!f && f.length > 0),
		take(1)
	);

	industries$ = this.store.select(this.genericSelectors.selectLookup(LookupTables.Industry)).pipe(
		filter((ind) => !!ind && ind.length > 0),
		take(1)
	);

	countries$ = this.store.select(this.genericSelectors.selectCountries).pipe(
		filter((c) => !!c),
		take(1)
	);

	stateProvinces$ = this.store.select(this.genericSelectors.selectStateProvinces).pipe(
		filter((sp) => !!sp),
		take(1)
	);

	yesNo$ = this.store.select(this.genericSelectors.selectLookup(LookupTables.YesNo)).pipe(
		filter((yn) => !!yn && yn.length > 0),
		take(1)
	);

	platforms$ = this.store.select(this.genericSelectors.selectLookup(LookupTables.Platform)).pipe(
		filter((p) => !!p && p.length > 0),
		take(1)
	);

	relationshipDuration$ = this.store.select(this.genericSelectors.selectLookup(LookupTables.RelationshipDuration)).pipe(
		filter((rd) => !!rd && rd.length > 0),
		take(1)
	);

	preferredPaymentMethods$ = this.store
		.select(this.genericSelectors.selectLookup(LookupTables.PreferredPaymentMethod))
		.pipe(
			filter((ppm) => !!ppm && ppm.length > 0),
			take(1)
		);

	onboardRevenueRange$ = this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardRevenueRange)).pipe(
		filter((rr) => !!rr && rr.length > 0),
		take(1)
	);

	resolve(): Observable<any> {
		this.initPrefetchData();
		return forkJoin([
			this.existingFinances$,
			this.financeFrequencies$,
			this.financeRanges$,
			this.financePurpose$,
			this.financeUrgencies$,
			this.industries$,
			this.countries$,
			this.stateProvinces$,
			this.platforms$,
			this.yesNo$,
			this.relationshipDuration$,
			this.preferredPaymentMethods$,
			this.onboardRevenueRange$
		]);
	}

	initPrefetchData(): void {
		this.store
			.select(this.genericSelectors.selectMap)
			.pipe(
				take(1),
				map((state) => {
					if (!state.has(LookupTables.OnboardExistingFinance)) {
						this.store.dispatch(
							GenericActions.getLookup({
								lookup: LookupTables.OnboardExistingFinance,
								sortOrder: SortDirection.ASC,
								isActive: true
							})
						);
					}
					if (!state.has(LookupTables.FinanceRange)) {
						this.store.dispatch(
							GenericActions.getLookup({
								lookup: LookupTables.FinanceRange,
								sortOrder: SortDirection.ASC,
								isActive: true
							})
						);
					}
					if (!state.has(LookupTables.FinanceFrequency)) {
						this.store.dispatch(
							GenericActions.getLookup({
								lookup: LookupTables.FinanceFrequency,
								sortOrder: SortDirection.ASC,
								isActive: true
							})
						);
					}
					if (!state.has(LookupTables.OnboardFinancePurpose)) {
						this.store.dispatch(
							GenericActions.getLookup({
								lookup: LookupTables.OnboardFinancePurpose,
								sortOrder: SortDirection.ASC,
								isActive: true
							})
						);
					}
					if (!state.has(LookupTables.OnboardFinanceUrgency)) {
						this.store.dispatch(
							GenericActions.getLookup({
								lookup: LookupTables.OnboardFinanceUrgency,
								sortOrder: SortDirection.ASC,
								isActive: true
							})
						);
					}
					if (!state.has(LookupTables.Industry)) {
						this.store.dispatch(GenericActions.getLookup({ lookup: LookupTables.Industry, isActive: true }));
					}
					if (!state.has(LookupTables.YesNo)) {
						this.store.dispatch(
							GenericActions.getLookup({ lookup: LookupTables.YesNo, sortOrder: SortDirection.ASC, isActive: true })
						);
					}
					if (!state.has(LookupTables.Platform)) {
						this.store.dispatch(
							GenericActions.getLookup({ lookup: LookupTables.Platform, sortOrder: SortDirection.ASC, isActive: true })
						);
					}
					if (!state.has(LookupTables.RelationshipDuration)) {
						this.store.dispatch(
							GenericActions.getLookup({
								lookup: LookupTables.RelationshipDuration,
								sortOrder: SortDirection.ASC,
								isActive: true
							})
						);
					}
					if (!state.has(LookupTables.Currency)) {
						this.store.dispatch(
							GenericActions.getLookup({ lookup: LookupTables.Currency, sortOrder: SortDirection.ASC })
						);
					}
					if (!state.has(LookupTables.OnboardPaymentMethod)) {
						this.store.dispatch(
							GenericActions.getLookup({
								lookup: LookupTables.OnboardPaymentMethod,
								sortOrder: SortDirection.ASC,
								isActive: true
							})
						);
					}
					if (!state.has(LookupTables.PreferredPaymentMethod)) {
						this.store.dispatch(
							GenericActions.getLookup({
								lookup: LookupTables.PreferredPaymentMethod,
								sortOrder: SortDirection.ASC,
								isActive: true
							})
						);
					}
					if (!state.has(LookupTables.FinanceDisclosure)) {
						this.store.dispatch(GenericActions.getLookup({ lookup: LookupTables.FinanceDisclosure, isActive: true }));
					}
					if (!state.has(LookupTables.OnboardInstrumentRange)) {
						this.store.dispatch(GenericActions.getLookup({ lookup: LookupTables.OnboardInstrumentRange }));
					}
					if (!state.has(LookupTables.OnboardRevenueRange)) {
						this.store.dispatch(GenericActions.getLookup({ lookup: LookupTables.OnboardRevenueRange }));
					}
				})
			)
			.subscribe();

		this.store
			.select(this.genericSelectors.selectStateProvinces)
			.pipe(
				filter((sp) => !sp),
				take(1),
				map((state) => this.store.dispatch(GenericActions.getStateProvince()))
			)
			.subscribe();

		this.store
			.select(this.genericSelectors.selectCountries)
			.pipe(
				filter((c) => !c),
				take(1),
				map(() => this.store.dispatch(GenericActions.getCountries()))
			)
			.subscribe();
	}
}
