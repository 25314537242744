<ls-stepper [lsSteps]="displayState?.activeSteps!" 
			[activeStep]="displayState?.activeStep!" 
			[stepComplete]="displayState?.activeCompletedSteps!"
			[displaySettingsButton]="displayState?.displaySettingsButton!"
			[displayNavBackButton]="displayState?.displayNavBackButton!"
			[showActiveStep]="showActiveStep"
			[navBackName]="displayState?.navBackName!"
			(navBackClicked)="handleNavClick()" (stepClicked)="handleStepChange($event)"
			(expandedChanged)="expanded = $event" (settingsClicked)="handleSettingsClick()" (logoutClicked)="logout()"
			class="d-flex flex-column flex-grow-1">

	<cdk-step *ngFor="let step of displayState?.activeSteps; let i = index">
		<div class="d-flex flex-column flex-grow-1 justify-content-between onboarding-step d-sm-flex" [class.d-none]="expanded">
			<div class="d-flex flex-column ls-mb-16">
				<router-outlet *ngIf="i === displayState?.activeStep"></router-outlet>
			</div>
			<ls-footer class="d-flex flex-column-reverse flex-grow-1"></ls-footer>
		</div>
	</cdk-step>
</ls-stepper>