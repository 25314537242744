import { Action, createReducer, on } from "@ngrx/store";
import { LsStep } from "../../../../../Elements/stepper/limestone-element-stepper.component";
import { StepperActions } from "./stepper-actions";
import copy from "fast-copy";

export interface ActiveState {
	activeSteps: LsStep[];
	activeStep: number;
	activeSubstep: number;
	activeCompletedSteps: Set<number>;
	childStepsActive: boolean;
	displaySettingsButton: boolean;
	displayNavBackButton: boolean;
	navBackName?: string;
	parent?: ActiveState;
}

export interface StepperState {
	steps: LsStep[];
	completedSteps: Set<number>;
	displaySettings: boolean;
	activeState?: ActiveState;
}

export enum ChildStepType {
	CUSTOMER_SETUP
}

export const initialState: StepperState = {
	steps: [
		{
			label: `Connect Financials`,
			percentComplete: 0,
			iconAfterComplete: "edit",
			substeps: 5,
			route: "connect-financials"
		},
		{
			label: `Company Details`,
			percentComplete: 0,
			iconAfterComplete: "edit",
			substeps: 6,
			route: "company-details"
		},
		{
			label: `Financing Intentions`,
			percentComplete: 0,
			iconAfterComplete: "edit",
			substeps: 4,
			route: "financing-intentions"
		},
		{
			label: `Customer Setup`,
			percentComplete: 0,
			iconAfterComplete: "edit",
			substeps: 15,
			route: "customer-setup",
			childDisplayStep: 3,
			children: [
				{
					label: `Customer List`,
					percentComplete: 100,
					icon: "checklist",
					iconAfterComplete: "checklist",
					substeps: 2,
					route: "customer-setup/manual",
					childStepType: ChildStepType.CUSTOMER_SETUP,
					childStepAlwaysComplete: true
				},
				{
					label: `Contact Info`,
					percentComplete: 0,
					icon: "contact_page",
					iconAfterComplete: "contact_page",
					substeps: 1,
					route: "customer-setup/customer-contact/{customerId}",
					childStepType: ChildStepType.CUSTOMER_SETUP
				},
				{
					label: `Customer Relationship`,
					percentComplete: 0,
					icon: "support_agent",
					iconAfterComplete: "support_agent",
					substeps: 3,
					route: "customer-setup/relationship/{customerId}",
					childStepType: ChildStepType.CUSTOMER_SETUP
				},
				{
					label: `Platforms Used`,
					percentComplete: 0,
					icon: "api",
					iconAfterComplete: "api",
					substeps: 3,
					route: "customer-setup/invoice-platform/{customerId}",
					childStepType: ChildStepType.CUSTOMER_SETUP
				},
				{
					label: `Customer Settings`,
					percentComplete: 0,
					icon: "settings_suggest",
					iconAfterComplete: "settings_suggest",
					substeps: 6,
					route: "customer-setup/requested-financing/{customerId}",
					childStepType: ChildStepType.CUSTOMER_SETUP
				},
				{
					label: ``,
					percentComplete: 0,
					icon: "done",
					iconAfterComplete: "done",
					substeps: 0,
					route: "",
					childStepType: ChildStepType.CUSTOMER_SETUP
				}
			]
		},
		// {
		// 	label: `Share Documents`,
		// 	percentComplete: 0,
		// 	iconAfterComplete: 'edit',
		// 	substeps: 10,
		// 	route: 'documents'
		// },
		{
			label: `Review Application`,
			percentComplete: 0,
			icon: "edit",
			iconAfterComplete: "edit",
			substeps: 1,
			route: "review-application"
		}
	],
	displaySettings: false,
	completedSteps: new Set<number>()
};

abstract class StepperReducerHandler {
	static appReducer = createReducer(
		initialState,
		on(StepperActions.updateSteps, (state: StepperState, { steps }) => ({
			...state,
			steps: copy(steps)
		})),
		on(StepperActions.setSettingsDisplay, (state: StepperState, { display }) => ({
			...state,
			displaySettings: display
		})),
		on(StepperActions.setCompletedSteps, (state: StepperState, { steps }) => ({
			...state,
			completedSteps: steps
		})),
		on(StepperActions.setActiveState, (state: StepperState, { activeState }) => ({
			...state,
			activeState
		}))
	);
}

export abstract class StepperReducer {
	static reducer = (state: StepperState | undefined, action: Action) => {
		return StepperReducerHandler.appReducer(state, action);
	};
}
