import { Directive, HostListener, Output, Input, EventEmitter, ElementRef } from "@angular/core";
import { FileHandle } from "./FileHandle";
import { FileUploadService } from "./file-upload.service";

@Directive({
	selector: "[lsDrag]"
})
export class DragDirective {
	@Output() files: EventEmitter<FileHandle[]> = new EventEmitter();
	@Input() disabled = false;

	constructor(
		private fileUploadService: FileUploadService,
		private elementRef: ElementRef
	) {}

	@HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
		if (this.disabled) {
			evt.dataTransfer!.dropEffect = "none";
		} else {
			evt.dataTransfer!.dropEffect = "copy";
			this.elementRef.nativeElement.classList.add("dragging");
		}
	}

	@HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
		if (!this.disabled) {
			this.elementRef.nativeElement.classList.remove("dragging");
		}
	}

	@HostListener("drop", ["$event"]) public onDrop(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();

		if (!this.disabled) {
			const fileHandles = this.fileUploadService.convert(evt.dataTransfer!.files);
			if (fileHandles.length > 0) {
				this.files.emit(fileHandles);
			}
			this.elementRef.nativeElement.classList.remove("dragging");
		}
	}
}
