import { CompanyUser } from "./CompanyUser";
import { CompanyAddress } from "./CompanyAddress";
import { CountryCode, GenericLookup } from "@limestone/ls-shared-modules";

export class CompanyProfile {
	companyId?: number;
	industryId?: string;
	legalBusinessName?: string;
	annualRevenueRangeId?: string;
	phoneId?: number;
	phoneCountryCode?: string;
	phoneNumber?: string;
	phoneExt?: string;
	agreedToTerms?: boolean;
	dbaNames?: string[];
	existingFinances?: GenericLookup<string>[];
	financeFrequency?: string;
	financePurposes?: string[];
	financeRange?: string;
	financeUrgency?: string;
	addresses?: CompanyAddress[];
	lienCollaborationYesNoId?: string;
	integrationDataConnectionId?: string;
	users?: CompanyUser[];
	isAccountingPlatformConnected?: boolean;
	isBankingPlatformConnected?: boolean;
	isCustomersInProgress?: boolean;
	formationStateCode?: string;
	isCodatCustomerFetchComplete?: boolean;
	isInvoiceHistoryFetchComplete?: boolean;
	hasSubmittedApplications?: boolean;
	taxId?: string;

	constructor(
		companyId?: number,
		industryId?: string,
		legalBusinessName?: string,
		annualRevenueRangeId?: string,
		phoneId?: number,
		phoneCountryCode?: string,
		phoneNumber?: string,
		phoneExt?: string,
		agreedToTerms?: boolean,
		dbaNames?: string[],
		existingFinances?: GenericLookup<string>[],
		financeFrequency?: string,
		financePurposes?: string[],
		financeRange?: string,
		financeUrgency?: string,
		addresses?: CompanyAddress[],
		lienCollaborationYesNoId?: string,
		integrationDataConnectionId?: string,
		users?: CompanyUser[],
		isAccountingPlatformConnected?: boolean,
		isBankingPlatformConnected?: boolean,
		isCustomersInProgress?: boolean,
		formationStateCode?: string,
		isCodatCustomerFetchComplete?: boolean,
		isInvoiceHistoryFetchComplete?: boolean,
		hasSubmittedApplications?: boolean,
		taxId?: string
	) {
		this.companyId = companyId;
		this.industryId = industryId;
		this.legalBusinessName = legalBusinessName;
		this.annualRevenueRangeId = annualRevenueRangeId;
		this.phoneId = phoneId;
		this.phoneCountryCode = phoneCountryCode;
		this.phoneNumber = phoneNumber;
		this.phoneExt = phoneExt;
		this.agreedToTerms = agreedToTerms;
		this.dbaNames = dbaNames;
		this.existingFinances = existingFinances;
		this.financeFrequency = financeFrequency;
		this.financePurposes = financePurposes;
		this.financeRange = financeRange;
		this.financeUrgency = financeUrgency;
		this.addresses = addresses;
		this.lienCollaborationYesNoId = lienCollaborationYesNoId;
		this.integrationDataConnectionId = integrationDataConnectionId;
		this.users = users
			? users.map(
					(u) =>
						new CompanyUser(
							this.companyId,
							u.userId,
							u.contactId,
							u.isInternal,
							u.firstName,
							u.lastName,
							u.emailAddress,
							u.title,
							u.b2cId,
							u.status,
							u.lastLogin,
							u.userRole,
							u.lastInviteSent
						)
			  )
			: new Array<CompanyUser>();
		this.isAccountingPlatformConnected = isAccountingPlatformConnected;
		this.isBankingPlatformConnected = isBankingPlatformConnected;
		this.isCustomersInProgress = isCustomersInProgress;
		this.formationStateCode = formationStateCode;
		this.isCodatCustomerFetchComplete = isCodatCustomerFetchComplete;
		this.isInvoiceHistoryFetchComplete = isInvoiceHistoryFetchComplete;
		this.hasSubmittedApplications = hasSubmittedApplications;
		this.taxId = taxId;
	}

	public isAddressOfCountry(countryCode: CountryCode): boolean {
		return !!this.addresses?.some((a) => a.address?.country?.code === countryCode);
	}
}
