import { Component, OnInit } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { RedirectRequest } from "@azure/msal-browser";
import { environment } from "../../../environments/environment";
import { B2CQueryParams } from "../../Models";
import { LocalStorageService } from "../../../Utils";

@Component({
	selector: "ls-logout",
	template: "",
	styleUrls: []
})
export class LogoutComponent implements OnInit {
	constructor(
		private authService: MsalService,
		private localStorageService: LocalStorageService
	) {}
	ngOnInit() {
		this.localStorageService.setB2CSignupState(true);
		const rr: RedirectRequest = {
			scopes: environment.scopes,
			extraQueryParameters: new B2CQueryParams(
				undefined,
				undefined,
				this.localStorageService.getB2CSignupState()
			).toParamsObject(),
			redirectStartPage: window.origin + "/onboarding/begin-application"
		};
		this.authService.loginRedirect(rr);
	}
}
