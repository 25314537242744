import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, switchMap } from "rxjs";
import { HttpErrorService } from "src/services/HTTP";
import { CompanyProfileRelationshipActions } from "./company-profile-relationship-actions";
import { CompanyProfileRelationshipService } from "src/services";

@Injectable()
export class CompanyProfileRelationshipEffects {
	constructor(
		private actions$: Actions,
		private companyProfileRelationshipService: CompanyProfileRelationshipService,
		private httpErrorService: HttpErrorService
	) {}

	getCompanyProfileRelationship$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.getCompanyProfileRelationship),
			mergeMap((act) =>
				this.companyProfileRelationshipService
					.getCompanyProfileRelationship(act.companyProfileId, act.relationshipCompanyId)
					.pipe(
						map((cpr) =>
							CompanyProfileRelationshipActions.setCompanyProfileRelationship({ companyProfileRelationship: cpr })
						)
					)
			)
		)
	);

	createCompanyProfileRelationship$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.createCompanyProfileRelationship),
			mergeMap((act) =>
				this.companyProfileRelationshipService.createCompanyProfileRelationship(act.companyProfileRelationship).pipe(
					switchMap((cpr) => [
						CompanyProfileRelationshipActions.setCompanyProfileRelationship({ companyProfileRelationship: cpr }),
						CompanyProfileRelationshipActions.companyProfileRelationshipSaveSuccessful()
					]),
					catchError((err) => {
						console.error(err);
						return this.httpErrorService
							.parseError(err)
							.pipe(
								map((resp) =>
									CompanyProfileRelationshipActions.companyProfileRelationshipSaveUnsuccessful({ errors: resp })
								)
							);
					})
				)
			)
		)
	);

	updateCompanyProfileRelationship$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.updateCompanyProfileRelationship),
			mergeMap((act) =>
				this.companyProfileRelationshipService.updateCompanyProfileRelationship(act.companyProfileRelationship).pipe(
					switchMap((cpr) => [
						CompanyProfileRelationshipActions.setCompanyProfileRelationship({ companyProfileRelationship: cpr }),
						CompanyProfileRelationshipActions.companyProfileRelationshipSaveSuccessful()
					]),
					catchError((err) => {
						console.error(err);
						return this.httpErrorService
							.parseError(err)
							.pipe(
								map((resp) =>
									CompanyProfileRelationshipActions.companyProfileRelationshipSaveUnsuccessful({ errors: resp })
								)
							);
					})
				)
			)
		)
	);

	getCompanyProfileRelationships$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.getCompanyProfileRelationships),
			mergeMap((act) =>
				this.companyProfileRelationshipService
					.getCompanyProfileRelationships(act.companyProfileId)
					.pipe(
						map((cprs) =>
							CompanyProfileRelationshipActions.setCompanyProfileRelationships({ companyProfileRelationships: cprs })
						)
					)
			)
		)
	);

	getUnenrolledCompanyProfileRelationships$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.getUnenrolledCompanyProfileRelationships),
			mergeMap((act) =>
				this.companyProfileRelationshipService.getCompanyProfileRelationships(act.companyProfileId, true).pipe(
					map((cprs) =>
						CompanyProfileRelationshipActions.setUnenrolledCompanyProfileRelationships({
							companyProfileRelationships: cprs
						})
					)
				)
			)
		)
	);

	getApplications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.getApplications),
			mergeMap((act) =>
				this.companyProfileRelationshipService
					.getApplications(act.companyProfileId, act.pr)
					.pipe(map((apps) => CompanyProfileRelationshipActions.setApplications({ page: apps })))
			)
		)
	);

	updateCustomerProgress = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.updateCustomerProgress),
			mergeMap((act) =>
				this.companyProfileRelationshipService.updateCustomerProgress(act.companyProfileRelationship).pipe(
					switchMap(() => [
						CompanyProfileRelationshipActions.setCompanyProfileRelationship({
							companyProfileRelationship: act.companyProfileRelationship
						})
					])
				)
			)
		)
	);

	submitApplication = createEffect(
		() =>
			this.actions$.pipe(
				ofType(CompanyProfileRelationshipActions.submitApplication),
				mergeMap((app) => this.companyProfileRelationshipService.submitApplication(app.companyProfileId))
			),
		{ dispatch: false }
	);
}
